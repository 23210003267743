import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { MultiSelectFilter, useMultiselectFilter } from '../MultiSelectFilter';
export function DocumentCategoryFilter() {
    var _a, _b;
    const items = (_b = (_a = metadataConfig.get('DocumentCategory')) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(type => ({
        id: type.value,
        title: type.text,
    }));
    const filter = useMultiselectFilter({
        items: items || [],
        paramKey: 'documentCategory',
        name: searchFilterCopy().documentCategory,
    });
    return <MultiSelectFilter filter={filter}/>;
}
