import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AlertContainer } from './styled';
export function ESignLoadingProviderError() {
    return (<AlertContainer>
      <Alert appearance="danger" icon={<Falcon icon="exclamation-triangle"/>} message={t({
            message: 'Something went wrong, please try again later',
            comment: 'Error message for not being able to load the current e-signing provider',
        })}/>
    </AlertContainer>);
}
