import { useRef } from 'react';
import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChipDropdown } from 'shared/domains/common-ui';
import { DateRangeForm } from './DateRangeForm/DateRangeForm';
export function DateRangeFilter(props) {
    const { filter } = props;
    const dropdownRef = useRef();
    const handleSelect = (newDateRange) => {
        var _a;
        filter.setDateRange(newDateRange.startDate, newDateRange.endDate);
        (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown();
    };
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<ChipDropdown ref={dropdownRef} label={filter.name} secondary={filter.value} initiallyOpen={filter.isInDraft} action={filter.dirty && (<ChipAction onClick={filter.clear}>
            <Falcon icon="remove"/>
          </ChipAction>)} dropdownContent={<DateRangeForm initialValue={{ startDate: filter.startDate, endDate: filter.endDate }} onSubmit={handleSelect}/>}/>);
}
