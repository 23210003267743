import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChipDropdown } from 'shared/domains/common-ui';
import { MultiSelectList } from './MultiSelectList';
export function MultiSelectFilter(props) {
    const { filter } = props;
    const handleSelect = (selectedItem, isSelected) => {
        if (isSelected)
            filter.remove(selectedItem);
        else
            filter.add(selectedItem);
    };
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<ChipDropdown label={filter.name} secondary={filter.value} loading={filter.loading} initiallyOpen={filter.isInDraft} action={<ChipAction onClick={filter.clear}>
          <Falcon icon="remove"/>
        </ChipAction>} dropdownContent={<MultiSelectList items={filter.items} selectedItems={filter.currentItems} onSelect={handleSelect}/>}/>);
}
