const getSuggestionType = (suggestion) => {
    if (!suggestion)
        return '';
    const { type, subType } = suggestion;
    if (subType) {
        return `${type}:${subType}`;
    }
    return type;
};
const getParsedAttributes = (attributes) => {
    try {
        if (attributes) {
            return JSON.parse(attributes);
        }
        return {};
    }
    catch (e) {
        return {};
    }
};
const getExtensibleData = (data) => {
    try {
        /**
         * For some reason `data` is non extensible object.
         * which makes pleditor to crash when we accept the formatting suggestion.
         * This is a temporary hack that makes data object extensible.
         */
        return JSON.parse(JSON.stringify(data));
    }
    catch (e) {
        return {};
    }
};
export function transformSuggestionData(suggestion) {
    const { id = '', userId, createdAt, data, attributes } = suggestion || {};
    return {
        id,
        type: getSuggestionType(suggestion),
        authorId: userId || '',
        createdAt: createdAt ? new Date(createdAt) : null,
        hasComments: false,
        data: getExtensibleData(data),
        attributes: getParsedAttributes(attributes),
        originalSuggestionId: null,
    };
}
