var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { IconButton, useToast, Loader, Menu, MenuList, MenuItem, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTasks } from 'app/domains/tasks/components/TasksProvider';
import { useDeleteTask } from '../../hooks/useDeleteTask';
function TaskDropdown(props) {
    const { taskId, completed } = props;
    const { mutate, loading } = useDeleteTask();
    const { handleEditTask } = useTasks();
    const [open, setOpen] = useState(false);
    const { addToast } = useToast();
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield mutate(taskId);
            addToast({
                title: t `Task deleted`,
                appearance: 'success',
            });
        }
        catch (deleteMutationError) {
            addToast({
                title: t `An error occurred`,
                appearance: 'destructive',
            });
        }
    });
    return (<Menu open={open} onClose={() => setOpen(false)} target={<IconButton disabled={loading} size="small" appearance="ghost" onClick={() => setOpen(!open)} $round>
          {loading ? <Loader /> : <Falcon icon="ellipsis-v"/>}
        </IconButton>}>
      <MenuBody>
        <MenuList>
          {!completed && (<MenuItem onClick={() => {
                setOpen(false);
                handleEditTask(taskId);
            }}>
              <Trans>Edit</Trans>
            </MenuItem>)}
          <MenuItem data-testid="task-action-delete" onClick={handleDelete}>
            <Trans>Delete task</Trans>
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
export { TaskDropdown };
