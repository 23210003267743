var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t, Trans } from '@lingui/macro';
import { TableBodyCell, Chip, MenuList, MenuItem, TableCellEmpty } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FileSystemDocumentStatus, FileSystemDocumentStatusExplicitEvents, SearchEntityTypeEnum, } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { getStatusData, isDocument } from './utils';
import { useSetDocumentStatus } from '../../useSetDocumentStatus';
export function DocumentStatusCell(props) {
    const { status, documentId, entityId, typename, type } = props, rest = __rest(props, ["status", "documentId", "entityId", "typename", "type"]);
    const flag = useFeatureFlag('feature_document_status');
    const { setStatus, loading } = useSetDocumentStatus();
    const data = getStatusData(status);
    const handleSetNewState = (event) => () => setStatus([
        {
            event,
            documentId,
            cacheId: entityId,
            typename,
        },
    ]);
    const isUnknown = status === FileSystemDocumentStatus.Unknown;
    const isUploaded = type === SearchEntityTypeEnum.DocumentUploaded;
    if (!isDocument(type)) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    if (flag && isUnknown && isUploaded) {
        return (<TableBodyCell {...rest}>
        <ChipDropdown size="medium" variant="outline" icon={<Falcon icon="plus"/>} loading={loading} label={t({ message: 'Add', comment: 'Global search: Document status label text' })} dropdownContent={<MenuList>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetAgreed)}>
                <Trans comment="Global search: Set document status">Set as agreed</Trans>
              </MenuItem>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetSigned)}>
                <Trans comment="Global search: Set document status">Set as signed</Trans>
              </MenuItem>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetDraft)}>
                <Trans comment="Global search: Set document status">Set as draft</Trans>
              </MenuItem>
            </MenuList>}/>
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <Chip icon={<Falcon icon="lock"/>} label={data.label} appearance={data.appearance} variant="solid"/>
    </TableBodyCell>);
}
