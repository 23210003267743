import { gql } from '@apollo/client';
import { ProfileFragment } from 'shared/domains/users/utils/fragments';
export const QUERY = gql `
  query authenticate {
    authenticated @client
    profile @client {
      ...ProfileFragment
    }
  }
  ${ProfileFragment}
`;
