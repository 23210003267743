import { searchFilterCopy } from './searchFilterCopy';
import { DateRangeFilter, useDateRangeFilter } from '../DateRangeFilter';
export function NoticeDateFilter(props) {
    const { startDate, endDate } = props;
    const filter = useDateRangeFilter({
        paramKey: 'noticeDate',
        name: searchFilterCopy().noticeDate,
        initialValues: {
            startDate,
            endDate,
        },
    });
    return <DateRangeFilter filter={filter}/>;
}
