var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getMarketByCode } from 'app/domains/markets/config';
import { StyledIcon } from './styled';
export function MarketFlag(props) {
    const { code, flag } = props, restProps = __rest(props, ["code", "flag"]);
    const marketFlagByCode = code && getMarketByCode(code);
    const glyphFlag = flag || (marketFlagByCode === null || marketFlagByCode === void 0 ? void 0 : marketFlagByCode.flag);
    if (glyphFlag) {
        return <StyledIcon {...restProps} glyph={glyphFlag} alignment="baseline"/>;
    }
    return null;
}
