import { SplitOperation, MoveOperation, Range } from 'pleditor';
/**
 * Adds the operation to replace the original operation with.
 * This replacement will happen in the autosave plugin.
 * @param operation - The original operation to be replaced.
 * @param toReplace - The new operation to replace the original one.
 */
export function replaceOperation(operation, toReplace) {
    const data = Array.isArray(toReplace) ? toReplace : [toReplace];
    Object.assign(operation, {
        toReplace: data,
    });
}
/**
 * @param operation The SplitOperation to clone and add the graveyard child node to
 * @param graveyardChild Node to add to the cloned operation
 * @returns A Plop with extra information about the graveyard child.
 */
export function addGraveyardChild(operation, graveyardChild) {
    const clonedOperation = operation.clone();
    const child = graveyardChild.toJSON();
    const newOperation = Object.assign(clonedOperation, {
        extra: Object.assign(Object.assign({}, (clonedOperation.extra || {})), { gy: child }),
    });
    return newOperation;
}
export function isSplitOperation(operation) {
    return (operation instanceof SplitOperation &&
        operation.type === 'split' &&
        Boolean(operation.graveyardPosition));
}
// Checks if the operation (Plop) is a complementary operation or not
export function isComplementary(operation) {
    return operation.isComplementary === true;
}
export function isMoveOperation(operation) {
    var _a, _b;
    return (operation instanceof MoveOperation &&
        operation.type === 'reinsert' &&
        ((_b = (_a = operation.sourcePosition) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.rootName) === '$graveyard');
}
// Helper function to run the private _clone function to avoid TSC errors
// TODO: Find alternative solution to this. `Operation` class do have a clone
// method but that one is not working as intended.
export function cloneEditorNode(node) {
    // eslint-disable-next-line no-underscore-dangle
    return node._clone();
}
/**
 * This function will return all nodes based on a MoveOperation's source position.
 */
export function getNodesFromSourcePosition(operation) {
    // eslint-disable-next-line no-underscore-dangle
    const range = Range._createFromPositionAndShift(operation.sourcePosition, operation.howMany);
    const result = range.getItems();
    const nodes = Array.from(result);
    return nodes;
}
