import { gql } from '@apollo/client';
export const mutation = gql `
  mutation SynchronizeCollaboratorSession($documentId: ID!, $versionNumber: Int!) {
    synchronizeFileSystemDocumentCollaborationSession(
      input: { documentId: $documentId, version: $versionNumber }
    ) {
      complementaryOperations

      collaborators {
        id
        userId
        documentId
        createdAt
        updatedAt
        lastModifiedAt
        lastAcknowledgedDocumentVersion
      }
    }
  }
`;
