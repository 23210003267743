import { t, Trans } from '@lingui/macro';
import { useToast, ConfirmDialog, Alert, Avatar } from '@pocketlaw/tetris';
import { Paragraph } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useRemoveExternalSharingInvite } from '../../hooks/useRemoveExternalSharingInvite';
export function RemoveExternalSharingInviteDialog(props) {
    const { id, email, onClose } = props;
    const { addToast } = useToast();
    const { removeExternalSharingInvite, loading, error } = useRemoveExternalSharingInvite();
    const handleConfirm = () => {
        if (id !== undefined) {
            removeExternalSharingInvite(id).then(() => {
                addToast({ title: t `Successfully removed access`, appearance: 'success' });
                onClose();
            });
        }
    };
    return (<ConfirmDialog appearance="danger" cancel={t `Cancel`} confirm={t `Remove`} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="user"/>
        </Avatar>} heading={t `Remove access`} loading={loading} onCancel={onClose} onClose={onClose} onConfirm={handleConfirm} size="sm">
      <Paragraph>
        <Trans>Are you sure you want to remove {email}&apos;s access to the document?</Trans>
      </Paragraph>
      {error && (<Alert appearance="danger" icon={<Falcon icon="circle-xmark"/>} message={t({
                message: 'Something went wrong, please try again later.',
                comment: 'error alert when failed to remove external sharing invite - message',
            })}/>)}
    </ConfirmDialog>);
}
