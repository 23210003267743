var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Alert, Avatar, Box, DialogContent, DialogFooter, DialogHeader, Typography, useToast, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useSaveViewMutation } from 'app/domains/global-search';
import { TextField, FormDialog, CancelButton, SubmitButton } from 'shared/domains/forms';
import { schema } from './schema';
export function SaveViewDialog(props) {
    const { onClose } = props;
    const { addToast } = useToast();
    const history = useHistory();
    const { saveView, error } = useSaveViewMutation();
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield saveView(values.name);
            history.push(`/saved-view/${data === null || data === void 0 ? void 0 : data.createSearchCustomView.id}`);
            addToast({
                title: t({ message: 'Successfully created view', comment: 'Save view success toast' }),
                appearance: 'success',
            });
        }
        catch (e) {
            addToast({
                title: t({ message: 'Failed to save view', comment: 'Save view error toast' }),
                appearance: 'danger',
            });
        }
    });
    return (<FormDialog onClose={onClose} initialValues={{ name: '' }} validationSchema={schema} onSubmit={handleSubmit} validateOnMount>
      <DialogHeader title={t({ message: 'Save view', comment: 'Save view dialog header title' })} media={<Avatar appearance="light">
            <Falcon icon="bookmark"/>
          </Avatar>}/>
      <DialogContent>
        <Box pb="2xl">
          <Typography $fontSize="large">
            <Trans comment="Save custom search view note">
              Save your filtered view to quickly access it from the main menu later.
            </Trans>
          </Typography>
        </Box>

        <TextField label="Name view" name="name" autoFocus/>

        {error && (<Alert appearance="danger" icon={<Falcon icon="xmark-circle"/>} message={t({
                message: 'Something went wrong, please try again later.',
                comment: 'Failed to do something error message',
            })}/>)}
      </DialogContent>

      <DialogFooter>
        <CancelButton onClick={onClose}>
          <Trans comment="Cancel button text">Cancel</Trans>
        </CancelButton>
        <SubmitButton>
          <Trans comment="Save view button label">Save view</Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
