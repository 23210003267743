import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Checkbox, MenuHeader, SearchInput, MenuBody, MenuList, MenuSubheader, MenuItem, } from '@pocketlaw/tetris';
import { useFuse } from 'app/domains/search';
import { RightAlign } from './styled';
export function MultiSelectList(props) {
    const { onSelect, items, selectedItems = [] } = props;
    const { search, result } = useFuse(items, { keys: ['title'] });
    const hasSearchedItems = (result === null || result === void 0 ? void 0 : result.length) > 0;
    const itemsToDisplay = hasSearchedItems ? result : items;
    const selectableItems = itemsToDisplay.filter(({ id }) => !selectedItems.some(item => item.id === id));
    const handleSearch = (event) => {
        search(event.target.value);
    };
    return (<Fragment>
      <MenuHeader>
        <SearchInput variant="line" onChange={handleSearch} placeholder={t({
            message: 'Search',
            comment: 'Multi select search input placeholder',
        })} autoFocus stretch/>
      </MenuHeader>

      <MenuBody>
        <MenuList $density="small">
          {Boolean(selectedItems.length) && (<Fragment>
              <MenuSubheader>
                <Trans comment="Selected items in multi select filter">Selected</Trans>
              </MenuSubheader>
              {selectedItems.map(item => {
                const { title, id } = item;
                return (<MenuItem key={id} as="label" $selected>
                    {title}
                    <RightAlign>
                      <Checkbox checked onChange={() => onSelect(item, true)}/>
                    </RightAlign>
                  </MenuItem>);
            })}
            </Fragment>)}

          {Boolean(selectableItems.length) && (<Fragment>
              <MenuSubheader>
                {hasSearchedItems ? (<Trans comment="Filters items subheader in multi select filter">
                    Filtered items
                  </Trans>) : (<Trans comment="All items subheader in multi select filter">All items</Trans>)}
              </MenuSubheader>

              {selectableItems.map(item => {
                const { title, id } = item;
                const isChecked = selectedItems.some(currentItem => currentItem.id === id);
                return (<MenuItem key={id} as="label" $selected={isChecked}>
                    {title}
                    <RightAlign>
                      <Checkbox checked={isChecked} onChange={() => onSelect(item, isChecked)}/>
                    </RightAlign>
                  </MenuItem>);
            })}
            </Fragment>)}
        </MenuList>
      </MenuBody>
    </Fragment>);
}
