var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { createPath } from 'history';
import { useUpdatePreferredLocale } from 'shared/domains/languages/hooks/useUpdatePreferredLocale';
import * as Sentry from 'shared/domains/sentry';
export function useLanguageSwitcher() {
    const { mutate, state } = useUpdatePreferredLocale();
    const { addToast } = useToast();
    const handleUpdateLocale = (options) => __awaiter(this, void 0, void 0, function* () {
        const { locale, userId } = options;
        if (state.loading) {
            return;
        }
        try {
            if (!locale) {
                throw Error('No locale specified');
            }
            if (userId) {
                yield mutate({ locale, userId });
            }
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('lang', locale);
            const { pathname } = window.location;
            const newPath = createPath({ pathname, search: searchParams.toString() });
            window.location.assign(newPath);
        }
        catch (error) {
            Sentry.captureException(error);
            addToast({
                title: t({
                    message: 'There was a problem changing language',
                    comment: 'Toast message if there is an error when changing language.',
                }),
                appearance: 'warning',
            });
        }
    });
    return {
        update: handleUpdateLocale,
        state,
    };
}
