var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo, useState, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useDebouncedCallback } from 'use-debounce';
export function useFuse(data, options) {
    const _a = options || {}, { delay = 250 } = _a, optionsWithoutDelay = __rest(_a, ["delay"]);
    const [active, setActive] = useState(false);
    const [result, setResult] = useState([]);
    const [searchString, setSearchString] = useState('');
    const fuse = useMemo(() => new Fuse(data, Object.assign({ threshold: 0.4, ignoreLocation: true, minMatchCharLength: 2 }, optionsWithoutDelay)), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data), JSON.stringify(options || {})]);
    const handleSearch = useCallback((newValue) => {
        setSearchString(newValue);
    }, []);
    const reset = useCallback(() => {
        setActive(false);
        setResult([]);
        setSearchString('');
    }, []);
    const debouncedSearch = useDebouncedCallback(() => {
        if (!searchString.length) {
            reset();
        }
        else {
            const searchResult = fuse.search(searchString);
            setResult(searchResult);
            setActive(true);
        }
    }, delay);
    useEffect(() => {
        debouncedSearch();
    }, [searchString, debouncedSearch]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mappedResult = useMemo(() => result.map(item => item.item), [JSON.stringify(result)]);
    return {
        search: handleSearch,
        result: mappedResult,
        reset,
        searchString,
        active,
    };
}
