import { useSearchContext } from '../../SearchProvider';
export function useNumberRangeFilter(options) {
    var _a, _b;
    const { paramKey, name } = options;
    const { isFilterInDraft, removeFilterFromDraft, setParamValue, isFilterActive, getNumberRangeFilterValue, } = useSearchContext();
    const numberRange = getNumberRangeFilterValue(paramKey);
    const setNumberRange = (from, to) => {
        setParamValue(paramKey, [`${(from === null || from === void 0 ? void 0 : from.toString()) || ''},${(to === null || to === void 0 ? void 0 : to.toString()) || ''}`]);
        removeFilterFromDraft(paramKey);
    };
    const clear = () => {
        setParamValue(paramKey, []);
        removeFilterFromDraft(paramKey);
    };
    const isActive = isFilterActive(paramKey);
    const isInDraft = isFilterInDraft(paramKey);
    const fromDisplayValue = (_a = numberRange.from) === null || _a === void 0 ? void 0 : _a.toString();
    const toDisplayValue = (_b = numberRange.to) === null || _b === void 0 ? void 0 : _b.toString();
    const label = [
        fromDisplayValue || '',
        fromDisplayValue && toDisplayValue && ' - ',
        toDisplayValue || '',
    ]
        .filter(Boolean)
        .join('');
    return {
        from: numberRange.from,
        to: numberRange.to,
        setNumberRange,
        value: label,
        clear,
        isActive,
        isInDraft,
        name,
    };
}
