import { gql } from '@apollo/client';
import { CreateCommentReplyFragment } from './fragments';
export const createCommentMutation = gql `
  mutation createComment($commentId: UUID!, $commentThreadId: UUID!, $body: String!) {
    createComment: createFileSystemDocumentCommentReply(input: { 
      headCommentId: $commentThreadId, body: $body, id: $commentId
    }) {
      id
      ...CreateCommentReplyFragment
    }
    ${CreateCommentReplyFragment}
  }
`;
