import { gql } from '@apollo/client';
import { GetSuggestionFragment } from '../utils/suggestionFragment';
export const query = gql `
  query GetDocumentSuggestions($id: ID!) {
    suggestions: fileSystemDocumentSuggestions(where: { documentId: { eq: $id } }) {
      ...GetSuggestionFragment
    }
  }
  ${GetSuggestionFragment}
`;
