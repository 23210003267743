import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function CurrencyFilter() {
    var _a;
    const currency = metadataConfig.get('Currency');
    const filter = useSingleSelectFilter({
        paramKey: 'currency',
        items: ((_a = currency === null || currency === void 0 ? void 0 : currency.allowedValues) === null || _a === void 0 ? void 0 : _a.map(item => ({
            id: item.value,
            title: item.text,
        }))) || [],
        name: searchFilterCopy().currency,
    });
    return <SingleSelectFilter filter={filter}/>;
}
