import { Button, unit, inputStyles } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledButton = styled(Button) `
  ${inputStyles};
  padding: ${unit('md')};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(:disabled):hover {
    text-decoration: none;
  }
`;
export const Container = styled.div `
  padding: ${unit('xl')};
  display: flex;
  flex-direction: column;
  gap: ${unit('md')};
`;
