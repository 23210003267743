import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { AvatarLetter, AvatarList, LabelHeading } from '@pocketlaw/tetris';
import { useTemplateApprovers } from './useTemplateApprovers';
export function TemplateApprovers(props) {
    const { composerId } = props;
    const { approvers } = useTemplateApprovers(composerId);
    const avatars = approvers.map(approver => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            id: ((_b = (_a = approver === null || approver === void 0 ? void 0 : approver.companyUser) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) || '',
            tooltip: { title: (_d = (_c = approver === null || approver === void 0 ? void 0 : approver.companyUser) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.name },
            component: <AvatarLetter>{(((_f = (_e = approver === null || approver === void 0 ? void 0 : approver.companyUser) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.name) || '')[0]}</AvatarLetter>,
        });
    });
    return (<Fragment>
      <LabelHeading>
        <Trans comment="Heading displaying the approvers for a template draft">Approvers</Trans>
      </LabelHeading>
      <AvatarList avatars={avatars}/>
    </Fragment>);
}
