import { select, t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EmptyContainer } from './styled';
export function Empty(props) {
    const { accessDropdownPermission } = props;
    return (<EmptyContainer>
      <AvatarWithLabel title={t({
            comment: 'Contract access tab empty content: Title',
            message: 'Empty in here.',
        })} subtitle={t({
            comment: 'Contract access tab empty content: Subtitle',
            message: select(accessDropdownPermission, {
                access: 'No users with access.',
                partialAccess: 'No users with partial access.',
                noAccess: 'No users with no access.',
                other: 'No users with no access.',
            }),
        })} size="md" alignment="vertical">
        <Falcon icon="inbox"/>
      </AvatarWithLabel>
    </EmptyContainer>);
}
