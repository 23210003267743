import { gql } from '@apollo/client';
export const getCompanyUsers = gql `
  query GetCompanyUsers {
    companyUsers {
      id
      userId: user_id
      user {
        id
        name
      }
    }
  }
`;
