export const getTextFromSelection = (selection) => {
    const range = selection.getFirstRange();
    if (!range) {
        return '';
    }
    let text = '';
    Array.from(range.getItems()).forEach(item => {
        if (item.is('$textProxy')) {
            text += item.data;
        }
    });
    return text;
};
