import { t } from '@lingui/macro';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function SharedFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'shared',
        items: [
            {
                id: 'true',
                title: t({ message: 'Shared', comment: 'Search external-sharing filter: Is shared' }),
            },
            {
                id: 'false',
                title: t({
                    message: 'Not shared',
                    comment: 'Search external-sharing filter: Is NOT shared',
                }),
            },
        ],
        name: searchFilterCopy().shared,
    });
    return <SingleSelectFilter filter={filter}/>;
}
