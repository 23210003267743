import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ErrorAlert(props) {
    const { hasError } = props;
    if (!hasError) {
        return null;
    }
    return (<Alert appearance="danger" icon={<Falcon icon="circle-xmark"/>} message={t({
            message: 'Something went wrong, please try again later',
            comment: 'Error alert message',
        })}/>);
}
