var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TableBodyCell, TableCellEmpty, TableCellText } from '@pocketlaw/tetris';
import { metadataConfig } from 'shared/domains/metadata';
export function CurrencyCell(props) {
    var _a, _b;
    const { value } = props, rest = __rest(props, ["value"]);
    const allValues = (_b = (_a = metadataConfig
        .get('Currency')) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(({ text, value: textValue, groupValues }) => [
        { text, value: textValue },
        ...(groupValues || []),
    ]).flat();
    const currency = allValues === null || allValues === void 0 ? void 0 : allValues.find(item => item.value === value);
    if (!currency) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <TableCellText primary={currency === null || currency === void 0 ? void 0 : currency.text}/>
    </TableBodyCell>);
}
