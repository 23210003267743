import { gql } from '@apollo/client';
import { FieldFragment } from './fragments';
export const editorDraftQuery = gql `
  query editorDraft($id: UUID!) {
    draft(id: $id) {
      id
      updatedAt: updated_at
      templateId: template_version_template_id
      result
      tree {
        id
        version
        outdated
        fields {
          id
          refId: ref
          ... on GroupField {
            ...FieldFragment
            fields {
              ...FieldFragment
            }
          }
        }
      }
      composer: unstable__composer {
        id
        metadata {
          name
          marketLocaleCode
        }
        categoryId
        marketCode
        category {
          id
          metadata {
            id
            title
          }
        }
      }
      templateVersion: unstable__templateVersion {
        reference
        templateId
        source: body
        astBody
        template {
          id
          marketLocale {
            locale
          }
        }
      }
    }
    documents: fileSystemDocuments(
      where: { draftId: { eq: $id }, templateId: { isNull: false } }
      orderBy: { draftId: desc, templateId: desc, updatedAt: desc }
    ) {
      id
      parentId
      name
      acl: acls {
        id
        companyId
        userId
      }
      draftId
      templateId
      signed
      files {
        id
        companyId
        path
        type: mimeType
        size
      }
      lastSignRequest {
        id
        status
      }
    }
  }
  ${FieldFragment}
`;
