import { Indent, IndentBlock, Bold, Essentials, Heading, Italic, List, Paragraph, Table, Strikethrough, Underline, Alignment, PageBreak, Link, Notification, Permissions, } from 'pleditor';
export const basePlugins = [
    Bold,
    Essentials,
    Heading,
    Italic,
    List,
    Paragraph,
    Table,
    Strikethrough,
    Underline,
    Alignment,
    PageBreak,
    Link,
    Indent,
    IndentBlock,
    Notification,
    Permissions,
];
