import { gql } from '@apollo/client';
export const GetCommentFragment = gql `
  fragment GetCommentFragment on FileSystemDocumentComment {
    id
    body
    resolved: isResolved
    resolvedAt
    resolvedBy
    createdAt
    anchorId
    lastEditAt
    user {
      id
      name
    }
    replies {
      id
      body
      createdAt
      lastEditAt
      user {
        id
        name
      }
    }
  }
`;
