import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChipDropdown } from 'shared/domains/common-ui';
import { SingleSelectList } from './SingleSelectList';
export function SingleSelectFilter(props) {
    const { filter } = props;
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<ChipDropdown label={filter.name} secondary={filter.value} loading={filter.loading} initiallyOpen={filter.isInDraft} action={<ChipAction onClick={filter.clear}>
          <Falcon icon="remove"/>
        </ChipAction>} dropdownContent={<SingleSelectList items={filter.items} selectedItem={filter.currentItems} onSelect={item => filter.select(item)}/>}/>);
}
