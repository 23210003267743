import { createContext, useRef, useState } from 'react';
import { usePreview } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
export const PleditorContext = createContext(null);
export function PleditorProvider(props) {
    const { children } = props;
    const { isFreeText } = usePreview();
    const isSandboxEnabled = useFeatureFlag('feature_pleditor_sandbox');
    const [state, setState] = useState('initializing');
    const [sandbox, setSandbox] = useState(false);
    const pleditorRef = useRef(null);
    const setPleditor = (editor) => {
        pleditorRef.current = editor;
        setState('initialized');
    };
    const unsetPleditor = () => {
        pleditorRef.current = null;
        setState('initializing');
    };
    const sandboxMode = isSandboxEnabled && sandbox && isFreeText;
    const sandboxModeAvailable = isSandboxEnabled && isFreeText;
    const baseValue = {
        setPleditor,
        unsetPleditor,
        sandboxMode,
        sandboxModeAvailable,
        setSandboxMode: setSandbox,
    };
    if (state === 'initialized' && pleditorRef.current !== null) {
        const value = Object.assign(Object.assign({}, baseValue), { pleditorState: state, pleditor: pleditorRef.current });
        return <PleditorContext.Provider value={value}>{children}</PleditorContext.Provider>;
    }
    const value = Object.assign(Object.assign({}, baseValue), { pleditorState: 'initializing', pleditor: null });
    return <PleditorContext.Provider value={value}>{children}</PleditorContext.Provider>;
}
export { usePleditor } from './hook';
