var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CreateCommentReplyFragment } from './fragments';
import { createCommentMutation } from './mutation';
export function addComment(apiClient, newComment) {
    return __awaiter(this, void 0, void 0, function* () {
        const { content, threadId, id } = newComment;
        const response = yield apiClient.mutate({
            mutation: createCommentMutation,
            variables: {
                commentId: id,
                commentThreadId: threadId,
                body: content,
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({
                        id: threadId,
                        __typename: 'FileSystemDocumentComment',
                    }),
                    fields: {
                        replies(existingCommentReplies = []) {
                            const newCommentReplyRef = cache.writeFragment({
                                data: data === null || data === void 0 ? void 0 : data.createComment,
                                fragment: CreateCommentReplyFragment,
                            });
                            return [...existingCommentReplies, newCommentReplyRef];
                        },
                    },
                });
            },
        });
        const { data } = response || {};
        const { createComment } = data || {};
        return {
            commentId: (createComment === null || createComment === void 0 ? void 0 : createComment.id) || '',
            createdAt: new Date((createComment === null || createComment === void 0 ? void 0 : createComment.createdAt) || ''),
        };
    });
}
