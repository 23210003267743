import { t } from '@lingui/macro';
import { Locale, createDropdown, View } from 'pleditor';
import { Comments } from './icons';
import { buildEmptyView, buildTitleView } from './utils';
import { PleditorPlugin } from '../PleditorPlugin';
export class CustomCommentsArchive extends PleditorPlugin {
    init() {
        const { editor } = this;
        editor.ui.componentFactory.add('customCommentsArchive', () => {
            const locale = new Locale();
            const dropdown = createDropdown(locale);
            const commentsRepository = this.editor.plugins.get('CommentsRepository');
            dropdown.panelView.on('change:isVisible', (e, name, value) => {
                if (!value) {
                    dropdown.panelView.children.clear();
                    return;
                }
                const commentThreads = commentsRepository.getCommentThreads();
                const hasResolvedThread = commentThreads.some(item => item.isResolved);
                const emptyView = buildEmptyView(hasResolvedThread);
                const titleView = buildTitleView();
                const commentArchiveUi = this.getCommentsArchiveUiView();
                const commentsArchiveContent = new View();
                commentsArchiveContent.setTemplate({
                    tag: 'div',
                    children: [commentArchiveUi, emptyView],
                    attributes: {
                        class: ['ck-comments-archive__content'],
                    },
                });
                const dropdownView = new View();
                dropdownView.setTemplate({
                    tag: 'div',
                    children: [titleView, commentsArchiveContent],
                    attributes: {
                        class: ['ck', 'ck-comments-archive'],
                        dir: 'auto',
                    },
                });
                dropdown.panelPosition = 'sw';
                dropdown.panelView.children.add(dropdownView);
            });
            dropdown.buttonView.set({
                tooltip: t({ message: 'Archived', comment: 'Comments archive toolbar button text' }),
                icon: Comments,
                class: 'pleditor-toolbar-fa-icon-button',
            });
            return dropdown;
        });
    }
    getCommentsArchiveUiView() {
        const commentsArchiveView = new View();
        const commentsArchiveUI = this.editor.plugins.get('CommentsArchiveUI');
        const commentAnnotationsViews = (commentsArchiveUI === null || commentsArchiveUI === void 0 ? void 0 : commentsArchiveUI.annotationViews) || [];
        commentsArchiveView.setTemplate({
            tag: 'div',
            children: commentAnnotationsViews,
        });
        return commentsArchiveView;
    }
}
