import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function buildDocumentEntityTypeFilter(currentItems) {
    const validItems = currentItems.filter(item => Boolean(item));
    const types = [];
    if (validItems.length === 0) {
        types.push(SearchEntityTypeEnum.DocumentComposed, SearchEntityTypeEnum.DocumentFreetext, SearchEntityTypeEnum.DocumentUploaded);
    }
    else {
        validItems.forEach(type => {
            const typedId = type;
            types.push(typedId);
        });
    }
    return { in: types };
}
