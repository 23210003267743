import { t } from '@lingui/macro';
import { TableHead, TableRow, TableHeadCell, VisuallyHidden, TableHeaderText, } from '@pocketlaw/tetris';
export function TemplateHead() {
    const titleLabel = t({
        message: 'Title',
        comment: 'Create document dialog in dashboard: (screen reader) Title of table column',
    });
    const languageLabel = t({
        message: 'Language',
        comment: 'Create document dialog in dashboard: (screen reader) Title of table column',
    });
    const selectLabel = t({
        message: 'Select template',
        comment: 'Create document dialog in dashboard: (screen reader) Title of table column',
    });
    return (<TableHead>
      <TableRow>
        <TableHeadCell id="title">
          <TableHeaderText>{titleLabel}</TableHeaderText>
        </TableHeadCell>
        <TableHeadCell id="language">
          <TableHeaderText>{languageLabel}</TableHeaderText>
        </TableHeadCell>
        <TableHeadCell id="select">
          <VisuallyHidden>{selectLabel}</VisuallyHidden>
        </TableHeadCell>
      </TableRow>
    </TableHead>);
}
