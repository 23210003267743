import { Trans, t } from '@lingui/macro';
import { number, object, ref } from 'yup';
import { TextField, Form, SubmitButton } from 'shared/domains/forms';
import { Container } from './styles';
export function NumberRangeForm(props) {
    const validationSchema = object().shape({
        from: number().required(t({
            message: 'Please provide a value',
            comment: 'Generic validation message when no data is provided for document metadata field',
        })),
        to: number().min(ref('from'), t({
            message: 'Must be greater than `From`',
            comment: 'Number range filter: `To` must be greater than `From` error message',
        })),
    });
    const { onSubmit, initialValue } = props;
    return (<Form initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema} disableOnSubmit={false}>
      <Container>
        <TextField name="from" autoFocus label={t({ message: 'From', comment: 'Range filter: "From" input label' })} type="number"/>
        <TextField name="to" label={t({ message: 'To', comment: 'Range filter: "To" input label' })} type="number"/>
        <SubmitButton size="small">
          <Trans comment="Apply filter button label">Apply</Trans>
        </SubmitButton>
      </Container>
    </Form>);
}
