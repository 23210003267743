import { t } from '@lingui/macro';
import { RestrictedEditingMode } from 'pleditor';
import { useDocumentLocked } from 'app/domains/documents';
import { useGetCurrentUser, usePleditor } from 'shared/domains/documents';
import { PleditorError } from '../../components/PleditorError';
import { PleditorLoader } from '../../components/PleditorLoader';
import { useGetUserIdAccess } from '../../hooks/useGetUsersAccess';
import { RTCPleditor } from '../RTCPleditor';
import { SandboxPleditor } from '../SandboxPleditor';
import { useImportDocumentToCcs, useGetDocumentFeatures } from '../hooks';
import { APP_FREE_TEXT_PLUGINS } from '../plugins';
export function AppFreeTextRTCPleditor() {
    const { locked } = useDocumentLocked();
    const { sandboxMode } = usePleditor();
    const currentUser = useGetCurrentUser();
    const { usersExternal, usersWithoutAccess, usersWithAccess } = useGetUserIdAccess();
    const { loading: importing, error: importError } = useImportDocumentToCcs();
    const { documentFeatures, loading: loadingFeatures, error: featuresError, } = useGetDocumentFeatures();
    const loading = importing || loadingFeatures;
    const error = importError || featuresError;
    const plugins = locked
        ? [...APP_FREE_TEXT_PLUGINS, RestrictedEditingMode]
        : APP_FREE_TEXT_PLUGINS;
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const fontItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Fonts toolbar button label', message: 'Fonts' }),
        items: ['fontSize', 'fontColor', 'fontBackgroundColor', '|', 'alignment'],
    };
    const insertItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Insert toolbar button label', message: 'Insert' }),
        items: ['link', '|', 'insertImage', '|', 'insertTable'],
    };
    const commentItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Comments toolbar button label', message: 'Comments' }),
        items: ['inlineComment', '|', 'customCommentsArchive', '|', 'toggleComments'],
    };
    const toolbarItems = [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        fontItems,
        '<->',
        insertItems,
        '|',
        commentItems,
        '|',
        'revisionHistory',
        '|',
        'editMode',
    ];
    const balloonToolbarItems = [
        'inlineCommentCompact',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'inlineAssistantShortcut',
    ];
    if (sandboxMode) {
        return (<SandboxPleditor currentUser={currentUser} toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} featureAvailability={documentFeatures}/>);
    }
    return (<RTCPleditor documentType="freetext" toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} featureAvailability={documentFeatures} usersExternal={usersExternal} usersWithAccess={usersWithAccess} usersWithoutAccess={usersWithoutAccess} plugins={plugins} locked={locked}/>);
}
