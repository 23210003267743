var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCurrentCompanyPlan } from '../../useCurrentCompanyPlan';
export const useNormalizeComposers = (composers) => {
    const { currentPlan } = useCurrentCompanyPlan();
    return composers.map(composer => {
        var _a;
        const { composerPlans, companyComposers = [] } = composer, rest = __rest(composer, ["composerPlans", "companyComposers"]);
        const isCompanyComposer = companyComposers !== null && companyComposers.length > 0;
        const locked = !((_a = composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) === null || _a === void 0 ? void 0 : _a.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id)));
        return Object.assign(Object.assign({}, rest), { composerPlans,
            isCompanyComposer,
            locked });
    });
};
