import { useLayoutEffect, useMemo, useRef } from 'react';
import { usePreview } from '../../../../../hooks/usePreview';
import { CanvasLayer } from '../../CanvasLayer';
import { TextLayer } from '../../TextLayer';
import { usePDFData } from '../../usePDFData';
import { OuterPage, Page } from './styled';
export function Item(props) {
    const { page, pageNumber } = props;
    const { zoom, zoomRef } = usePreview();
    const { scale, spacing, setPageRef } = usePDFData();
    const pageRef = useRef(null);
    const viewport = useMemo(() => page.getViewport({ scale }), [page, scale]);
    const pageHeight = viewport.height * zoom;
    const pageWidth = viewport.width * zoom;
    useLayoutEffect(() => {
        if (pageRef.current) {
            setPageRef(pageNumber, pageRef.current);
            if (pageNumber === 1) {
                zoomRef.current = pageRef.current;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<OuterPage $height={pageHeight} $width={pageWidth} $spacing={spacing}>
      <Page $zoom={zoom} ref={pageRef}>
        <CanvasLayer page={page} scale={scale}/>
        <TextLayer page={page} scale={scale}/>
      </Page>
    </OuterPage>);
}
