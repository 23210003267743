import { useTableContext } from './TableContext';
export function useCreateActionEventHandlers(actionName) {
    const { activeAction, setActiveAction } = useTableContext();
    const eventHandlers = {
        onMouseEnter: () => {
            if ((activeAction === null || activeAction === void 0 ? void 0 : activeAction.event) === 'open' && (activeAction === null || activeAction === void 0 ? void 0 : activeAction.name) === actionName) {
                return;
            }
            setActiveAction({ name: actionName, event: 'hover' });
        },
        onMouseLeave: () => {
            if ((activeAction === null || activeAction === void 0 ? void 0 : activeAction.event) === 'open' && (activeAction === null || activeAction === void 0 ? void 0 : activeAction.name) === actionName) {
                return;
            }
            setActiveAction(undefined);
        },
        onClick: () => {
            if ((activeAction === null || activeAction === void 0 ? void 0 : activeAction.event) === 'open' && (activeAction === null || activeAction === void 0 ? void 0 : activeAction.name) === actionName) {
                setActiveAction(undefined);
            }
            else {
                setActiveAction({ name: actionName, event: 'open' });
            }
        },
    };
    return eventHandlers;
}
