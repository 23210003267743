import { useQuery } from '@apollo/client';
import { getAllMarketCodes } from 'app/domains/markets/config';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { composersQuery } from './query';
function useComposers() {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const marketCodes = getAllMarketCodes();
    return useQuery(composersQuery, {
        partialRefetch: true,
        returnPartialData: true,
        variables: { companyId, marketCodes },
    });
}
export { useComposers };
