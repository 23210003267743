import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function LimitationOfLiabilityFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'limitationOfLiability',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().limitationOfLiability,
    });
    return <SingleSelectFilter filter={filter}/>;
}
