import { t } from '@lingui/macro';
import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { useMultiselectFilter, MultiSelectList, } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function DocumentEntityTypeFilter() {
    const filter = useMultiselectFilter({
        items: [
            {
                id: SearchEntityTypeEnum.DocumentComposed,
                title: t({
                    message: 'Document (generated)',
                    comment: 'Search entity type filter: Documents (generated)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentFreetext,
                title: t({
                    message: 'Document (freetext)',
                    comment: 'Search entity type filter: Document (freetext)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentUploaded,
                title: t({
                    message: 'Document (uploaded)',
                    comment: 'Search entity type filter: Document (uploaded)',
                }),
            },
        ],
        paramKey: 'type',
        name: searchFilterCopy().type,
    });
    const handleSelect = (selectedItem, isSelected) => {
        if (isSelected && filter.currentItems.length === 1)
            filter.clear();
        else if (isSelected)
            filter.remove(selectedItem);
        else
            filter.add(selectedItem);
    };
    return (<ChipDropdown label={filter.name} secondary={filter.value ||
            t({
                message: 'All',
                comment: 'Empty content type filter value',
            })} loading={filter.loading} action={filter.value && (<ChipAction onClick={filter.clear}>
            <Falcon icon="remove"/>
          </ChipAction>)} dropdownContent={<MultiSelectList items={filter.items} selectedItems={filter.currentItems} onSelect={handleSelect}/>}/>);
}
