export const addToSearchString = (input) => {
    const { searchString = '', paramKey, value = [] } = input;
    const searchParams = new URLSearchParams(searchString);
    if (!searchParams.has(paramKey)) {
        value.forEach(paramValue => {
            if (paramValue)
                searchParams.append(paramKey, paramValue);
        });
        return searchParams.toString();
    }
    const currentEntries = Array.from(searchParams.entries());
    const existingParamIndex = currentEntries.findIndex(([key]) => paramKey === key);
    // This will filter out the existing params so we can replace them with news ones
    const newEntries = [...currentEntries.filter(([key]) => key !== paramKey)];
    const entriesToAdd = value.filter(Boolean).map(item => [paramKey, item]);
    newEntries.splice(existingParamIndex, 0, ...entriesToAdd);
    return new URLSearchParams(newEntries).toString();
};
