var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FsPathFilterQueryDocument } from './FSPathFilterQuery.gql';
export function useGetPath(mpath) {
    const pathIds = useMemo(() => {
        const fullPath = mpath.split('.');
        return fullPath.slice(1, fullPath.length - 1);
    }, [mpath]);
    const _a = useQuery(FsPathFilterQueryDocument, {
        variables: {
            id: pathIds,
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { fileSystemEntries } = data || {};
    const path = (fileSystemEntries === null || fileSystemEntries === void 0 ? void 0 : fileSystemEntries.map(({ name }) => name).join(' / ')) || '';
    return Object.assign({ data: {
            path,
        } }, rest);
}
