import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { Container } from './styled';
export function CancelSignRequestErrorAlert() {
    const { cancelError } = useEsigning();
    if (!cancelError) {
        return null;
    }
    return (<Container>
      <Alert appearance="danger" icon={<Falcon icon="exclamation-triangle"/>} message={t({
            message: 'Something went wrong while trying to cancel the sign request, please try again',
            comment: 'Error message for when the user tries to cancel a sign request but something went wrong',
        })}/>
    </Container>);
}
