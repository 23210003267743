import { t } from '@lingui/macro';
import { Select, useGeneratedId } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
import { ROOT } from 'app/domains/filesystem';
export function SelectFolderField(props) {
    const { folder } = props;
    const [field] = useField('parentId');
    const { isSubmitting } = useFormikContext();
    const inputId = useGeneratedId('select-folder-field');
    const title = t({ message: 'Root', comment: 'fileSystem picker root option for select folder' });
    return (<Select {...field} id={inputId} label={t({ message: 'Choose folder', comment: 'label for select folder dropdown' })} disabled={isSubmitting} stretch>
      <option value={ROOT}>{title}</option>
      {(folder === null || folder === void 0 ? void 0 : folder.parent) && (<optgroup label={folder.parent.name}>
          <option value={folder.id}>{folder.name}</option>
        </optgroup>)}
      <option value="otherOptions">{t `Other options...`}</option>
    </Select>);
}
