import { margin, marginBottom, fontWeight, paddingLeft, themeGet } from '@pocketlaw/tetris';
import T from 'prop-types';
import styled from 'styled-components';

import useMarkdown from './useMarkdown';

const Container = styled.div`
  color: ${themeGet('colors.text.300')};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
  }
  ol {
    list-style: decimal outside;
  }

  ul {
    list-style: disc outside;
  }

  ol,
  ul {
    ${paddingLeft(6)};
  }

  p {
    ${margin(0)}
  }

  ol,
  ul,
  p {
    &:not(:last-child) {
      ${marginBottom(2)}
    }
  }

  a {
    color: ${themeGet('colors.secondary.500')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    ${fontWeight('semibold')};
  }
`;

function Markdown({ contents, ...restProps }) {
  const children = useMarkdown(typeof contents === 'string' ? contents : '');

  return <Container {...restProps}>{children || contents}</Container>;
}

Markdown.propTypes = {
  contents: T.node,
};

Markdown.defaultProps = {
  contents: '',
};

export default Markdown;
