import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const balloonToolbarStyles = css `
  .ck-balloon-panel {
    .ck-button.pleditor-toolbar-fa-icon-button--assistant {
      border-radius: 50% !important;
      color: ${themeGet('colors.assistant.500')};
      border: solid ${pxToRem(1)} ${themeGet('colors.assistant.500')};
      background-color: ${themeGet('colors.assistant.50')};
      transition: background-color 250ms ease;

      &:not(.ck-disabled) {
        cursor: pointer;

        &:hover {
          background-color: ${themeGet('colors.assistant.100')};
        }

        &:active {
          background-color: ${themeGet('colors.assistant.200')};
        }

        &:focus-visible {
          background-color: ${themeGet('colors.assistant.50')};
          box-shadow: 0 0 0 ${pxToRem(4)} ${themeGet('colors.focus.assistant')};
        }
      }

      &.ck-disabled {
        opacity: 0.5;
      }
    }
  }
`;
