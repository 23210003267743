import { createContext, useEffect, useState } from 'react';
import { localeConfig } from 'shared/domains/languages';
import { useComposerData } from '../../ComposerDataProvider';
import { useDraft } from '../useDraft';
export const DraftPreviewContext = createContext(null);
export { useDraftPreview } from './hook';
export function DraftPreviewProvider(props) {
    const { composerId, children } = props;
    const { createDocument } = useComposerData();
    const { data, loading, error } = useDraft(composerId);
    const { composer, templates } = data || {};
    const activeLanguageCode = localeConfig.getActiveLocale().languageCode;
    const templateByLocale = templates.find(template => template.languageCode === activeLanguageCode);
    const [firstTemplate] = templates || [];
    const initialSelected = templateByLocale || firstTemplate;
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    useEffect(() => {
        if (!selectedTemplateId) {
            setSelectedTemplateId(initialSelected === null || initialSelected === void 0 ? void 0 : initialSelected.id);
        }
    }, [initialSelected === null || initialSelected === void 0 ? void 0 : initialSelected.id, selectedTemplateId]);
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);
    const selectTemplate = (templateId) => setSelectedTemplateId(templateId);
    const createDraft = () => {
        if (!selectedTemplate) {
            return;
        }
        const { id: templateId, latestVersion } = selectedTemplate;
        const { treeVersion } = composer;
        if (!latestVersion || !treeVersion) {
            return;
        }
        createDocument({
            composer: {
                id: composerId,
                tree: {
                    version: treeVersion,
                },
            },
            template: {
                id: templateId,
                latestVersion: {
                    templateId: latestVersion === null || latestVersion === void 0 ? void 0 : latestVersion.templateId,
                    reference: latestVersion === null || latestVersion === void 0 ? void 0 : latestVersion.reference,
                },
            },
        });
    };
    const value = Object.assign(Object.assign({}, data), { loading,
        selectedTemplate,
        selectedTemplateId,
        createDraft,
        selectTemplate, error: Boolean(error) });
    return <DraftPreviewContext.Provider value={value}>{children}</DraftPreviewContext.Provider>;
}
