import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { initialQuery } from './query';
import { useDownloadOptions } from './useDownloadOptions';
/**
 *
 * @param FileSystemDocument.id
 * @returns A list of available download options for a document
 */
export function useDownloadFilesystemDocument(documentId) {
    const { addToast } = useToast();
    const { data, error, loading } = useQuery(initialQuery, {
        variables: { documentId },
        skip: !documentId,
    });
    const { fileSystemDocument } = data || {};
    const downloadOptions = useDownloadOptions({
        document: fileSystemDocument,
        onSuccess: signedUrl => {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'File download ready',
                    comment: 'Generic message when document render and download is done.',
                }),
            });
            window.location.href = signedUrl;
        },
        onError: () => addToast({
            appearance: 'warning',
            title: t({
                message: 'An error occurred when downloading the file',
                comment: 'Generic error message displayed when a document download failed.',
            }),
        }),
    });
    const noDocument = Boolean(!loading && !(fileSystemDocument === null || fileSystemDocument === void 0 ? void 0 : fileSystemDocument.id));
    const hasError = Boolean(error || noDocument);
    const isLoading = Boolean(loading || downloadOptions.loading);
    return {
        loading: isLoading,
        error: hasError,
        files: downloadOptions.files,
    };
}
