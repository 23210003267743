import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Menu, SearchInput, MenuHeader, MenuItem, MenuList, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { searchFilterCopy, useSearchContext, } from 'app/domains/global-search';
import { useFuse } from 'app/domains/search';
export function AddFilterButton(props) {
    const [open, setOpen] = useState(false);
    const { isFilterActive, addFilterToDraft } = useSearchContext();
    const { filterKeys } = props;
    const filterItems = filterKeys
        .map(item => ({
        paramKey: item,
        label: searchFilterCopy()[item],
    }))
        .sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase())
            return 1;
        if (a.label.toLowerCase() < b.label.toLowerCase())
            return -1;
        return 0;
    });
    const { search, result, reset } = useFuse(filterItems, { keys: ['label'] });
    const filterItemsToDisplay = (result === null || result === void 0 ? void 0 : result.length) > 0 ? result : filterItems;
    const menuItems = filterItemsToDisplay.filter(item => !isFilterActive(item.paramKey));
    const openDropdown = () => setOpen(!open);
    const handleClose = () => {
        reset();
        setOpen(false);
    };
    const handleMenuItemClick = (paramKey) => {
        addFilterToDraft(paramKey);
        handleClose();
    };
    const handleSearch = (event) => {
        search(event.target.value);
    };
    if (menuItems.length === 0)
        return null;
    return (<Menu open={open} onClose={handleClose} target={<Button onClick={openDropdown} appearance="secondary" size="small">
          <Falcon icon="plus"/>
          <Trans comment="Add filter button label">Add Filter</Trans>
        </Button>}>
      <MenuHeader>
        <SearchInput variant="line" autoFocus placeholder={t({
            message: 'Search filter',
            comment: 'Search filter input placeholder text',
        })} onChange={handleSearch}/>
      </MenuHeader>

      <MenuBody>
        <MenuList $density="small">
          {menuItems.map(item => (<MenuItem key={item.label} onClick={() => handleMenuItemClick(item.paramKey)}>
              {item.label}
            </MenuItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
