import { useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, IconButton, Button, TextInput, TextArea, Loader, Alert, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { useCreateTeam } from 'app/domains/teams';
import { CreateTeamErrorMessages } from './CreateTeamErrorMessages';
import { Container } from './styled';
export function CreateTeamDialog(props) {
    const nameRequiredText = t({
        message: 'Name is required',
        comment: 'validation message when no data is provided for name field',
    });
    const schema = object().shape({
        name: string().trim().required(nameRequiredText),
    });
    const { open, onClose } = props;
    const nameRef = useRef(null);
    const { loading, error, createTeam } = useCreateTeam();
    const errorMessage = CreateTeamErrorMessages(error);
    const handleSubmit = (values) => {
        createTeam(values).then(() => {
            onClose();
        });
    };
    if (!open) {
        return null;
    }
    return (<Formik onSubmit={handleSubmit} initialValues={{ name: '', description: '' }} validationSchema={schema}>
      {({ errors: formError, handleChange, values }) => (<Dialog as={Form} onClose={onClose} initialFocusRef={nameRef}>
          <DialogHeader title={t({ message: 'Create new team', comment: 'Create new team modal - title' })} action={<IconButton $round appearance="ghost" onClick={onClose}>
                <Falcon icon="xmark"/>
              </IconButton>}/>
          <DialogContent>
            <Container>
              <TextInput ref={nameRef} onChange={handleChange} value={values.name} name="name" label={t({
                message: 'Name',
                comment: 'Create new team modal - name input label',
            })} invalid={Boolean(formError.name)} description={formError.name}/>
              <TextArea onChange={handleChange} value={values.description} name="description" label="Description"/>
              {error && (<Alert appearance="danger" pale title={t({
                    // eslint-disable-next-line lingui/text-restrictions
                    message: 'Couldn’t create new team',
                    comment: 'Alert error title when create team fails',
                })} message={errorMessage}/>)}
            </Container>
          </DialogContent>
          <DialogFooter>
            <Button disabled={loading} onClick={onClose} appearance="secondary">
              <Trans comment="Create new team modal - cancel button text">Cancel</Trans>
            </Button>
            <Button disabled={loading} type="submit">
              {loading && <Loader />}
              <Trans comment="Create new team modal - create team button text">Create team</Trans>
            </Button>
          </DialogFooter>
        </Dialog>)}
    </Formik>);
}
