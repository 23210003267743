import { t } from '@lingui/macro';
import { DialogHeader, DialogFooter, Alert } from '@pocketlaw/tetris';
import { isBefore, startOfToday } from 'date-fns';
import * as yup from 'yup';
import { FormDialog, SubmitButton } from 'shared/domains/forms';
import { AssigneePermission } from '../AssigneePermission';
import { AssigneesField } from '../AssigneesField';
import { AttachmentAdd } from '../AttachmentAdd';
import { AttachmentField } from '../AttachmentField';
import { CloseTaskDialogButton } from '../CloseTaskDialogButton';
import { DueDateField } from '../DueDateField';
import { PublicField } from '../PublicField';
import { ReminderField } from '../ReminderField';
import { TextAreaField } from '../TextAreaField';
import { TextInputField } from '../TextInputField';
import { StyledDialogContent, InputFieldContainer, LinkFieldContainer, AttachmentsFieldContainer, } from './styled';
export function TaskDialog(props) {
    const schema = yup.object({
        title: yup
            .string()
            .trim()
            .required(t `Task title is required.`),
        description: yup.string().trim(),
        isPublic: yup.bool().required(),
        isCompleted: yup.bool().required(),
        assigneeId: yup.string().trim(),
        dueDate: yup
            .string()
            .trim()
            .when('reminder', {
            is: (reminder) => Boolean(reminder === null || reminder === void 0 ? void 0 : reminder.dueDate),
            then: dueDate => dueDate.required(t `Due date is required to specify reminder`),
        }),
        reminder: yup.object({
            id: yup.string(),
            dueDate: yup
                .string()
                .trim()
                .test('incorrect-date', t({
                message: 'Reminder can not be set to a date in the past',
                comment: 'Error message if the selected reminder date is in the past',
            }), dueDate => {
                if (!dueDate) {
                    return true;
                }
                return isBefore(startOfToday(), new Date(dueDate));
            }),
        }),
    });
    const { title, onSubmit, onClose, error, initialValues, buttonTitle } = props;
    return (<FormDialog onClose={onClose} size="sm" initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      <DialogHeader title={title} action={<CloseTaskDialogButton onClick={onClose}/>}/>

      <StyledDialogContent>
        {error && (<Alert appearance="danger" pale message={t({ message: 'An error occurred.', comment: 'Add task error alert message' })}/>)}
        <InputFieldContainer>
          <TextInputField name="title" label={t({ message: 'Title', comment: 'task title field label' })}/>
          <TextAreaField name="description" label={t({ message: 'Description', comment: 'task description field label' })}/>
        </InputFieldContainer>
        <LinkFieldContainer>
          <PublicField />
          <DueDateField />
          <ReminderField />
          <AssigneesField />
        </LinkFieldContainer>
        <AttachmentsFieldContainer>
          <AttachmentField />
          <AttachmentAdd />
          <AssigneePermission />
        </AttachmentsFieldContainer>
      </StyledDialogContent>
      <DialogFooter>
        <SubmitButton>{buttonTitle}</SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
