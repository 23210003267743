import { t } from '@lingui/macro';
import { getPromptContextByPromptType } from 'app/domains/assistant';
import { ChatMessageRole } from 'shared/domains/apollo/generated/types';
import { Actions } from './Actions';
import { CommentBlock } from './CommentBlock';
import { CommentBubble } from './CommentBubble';
import { Message } from './Message';
import { Prompt } from './Prompt';
import { getBubblePrefixByPromptType, getPromptIconByPromptType, getPromptNameByPromptType, } from './utils';
/**
 * This component represents the comment block in a thread, including the prefix, the message, the prompt and other actions such as like/dislike
 */
export function AssistantComment(props) {
    const { id, content, role, type, status, feedbackScore } = props;
    const prefixMessage = role === ChatMessageRole.User
        ? t({ comment: 'Assistant chat recipient - from user', message: 'You' })
        : getBubblePrefixByPromptType(type);
    const direction = role === ChatMessageRole.User ? 'left' : 'right';
    const showActions = role === ChatMessageRole.Assistant;
    return (<CommentBlock direction={direction} title={prefixMessage}>
      <CommentBubble direction={direction}>
        {role === ChatMessageRole.User && (<Prompt icon={getPromptIconByPromptType(type)} title={getPromptNameByPromptType(type)} subtitle={getPromptContextByPromptType(type)}/>)}
        <Message {...props}/>
      </CommentBubble>
      {showActions && (<Actions status={status} message={content} commentId={id} feedbackScore={feedbackScore}/>)}
    </CommentBlock>);
}
export { CommentBubble as AssistantCommentBubble } from './CommentBubble';
export { CommentBlock as AssistantCommentBlock } from './CommentBlock';
