var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PleditorPlugin } from '../PleditorPlugin';
import { isMobile } from '../utils';
export class CommentsUi extends PleditorPlugin {
    constructor() {
        super(...arguments);
        this.resizeObserver = new ResizeObserver(this.setCommentsDisplayMode.bind(this));
    }
    static get requires() {
        return ['Sidebar', 'AnnotationsUIs', 'ContextualBalloon'];
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            /**
             * Comments ui display mode for mobile and desktop view
             */
            this.setCommentsDisplayMode();
            this.resizeObserver.observe(window.document.body);
        });
    }
    setCommentsDisplayMode() {
        var _a;
        const annotationsUiPlugin = this.editor.plugins.get('AnnotationsUIs');
        const sidebarElement = (_a = this.editor.config.get('sidebar')) === null || _a === void 0 ? void 0 : _a.container;
        if (isMobile(window.innerWidth)) {
            annotationsUiPlugin.switchTo('inline');
            if (sidebarElement) {
                sidebarElement.style.display = 'none';
            }
        }
        else {
            annotationsUiPlugin.switchTo('wideSidebar');
        }
    }
    destroy() {
        // Disconnect resize observer
        this.resizeObserver.disconnect();
    }
}
