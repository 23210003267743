var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useComposers } from '../useComposers';
export function useComposerBrowser() {
    var _a, _b;
    const _c = useComposers(), { data } = _c, rest = __rest(_c, ["data"]);
    const { company, composers } = data || {};
    const companyPlan = (_b = (_a = company === null || company === void 0 ? void 0 : company.plans) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.plan;
    if (!composers) {
        return Object.assign(Object.assign({}, rest), { data: [] });
    }
    const flattenedTemplates = composers.flatMap(composer => {
        const { templates, composerPlans } = composer;
        if (!templates) {
            return [];
        }
        const [metadata] = composer.metadata || [];
        return templates.map(template => {
            var _a;
            return ({
                id: template.id,
                name: metadata.name,
                composerId: composer.id,
                locked: !((_a = composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) === null || _a === void 0 ? void 0 : _a.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (companyPlan === null || companyPlan === void 0 ? void 0 : companyPlan.id))),
                locale: template.marketLocaleCode,
            });
        });
    });
    const sortedTemplates = flattenedTemplates.sort((a, b) => (a.name < b.name ? -1 : 1));
    return Object.assign({ data: sortedTemplates }, rest);
}
