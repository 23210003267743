import { useRef, useState } from 'react';
import { MenuBody, MenuList, Menu, IconButton, Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFuse } from 'app/domains/search';
import { EmptyState } from './EmptyState';
import { StyledMenuHeader, SearchInput } from './styled';
export function SearchableMenu(props) {
    const { renderTarget, items, keys, placeholder, children, initiallyOpen = false, onClose, } = props;
    const [open, setOpen] = useState(initiallyOpen);
    const { search, result, searchString, reset, active } = useFuse(items, { keys });
    const inputRef = useRef(null);
    const itemsToDisplay = active && searchString.length ? result : items;
    const hasResult = Boolean(itemsToDisplay.length);
    const disableReset = !active;
    const handleOnChange = (event) => {
        search(event.target.value);
    };
    const handleResetSearch = () => {
        var _a;
        reset();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const handleClose = () => {
        reset();
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };
    const handleSetOpen = (newState) => {
        setOpen(newState);
        // Reset search state on open
        if (newState) {
            reset();
        }
    };
    const renderProps = { items: itemsToDisplay, open, setOpen: handleSetOpen };
    return (<Menu target={renderTarget(renderProps)} open={open} onClose={handleClose} options={{ placement: 'bottom-start' }}>
      <StyledMenuHeader sticky>
        <Box alignItems="center" justifyContent="space-around" width="100%" px="md">
          <Falcon icon="magnifying-glass" $color="text.100"/>
          <SearchInput autoFocus placeholder={placeholder} onChange={handleOnChange} value={searchString} ref={inputRef}/>
          <IconButton $round disabled={disableReset} appearance="ghost" size="xs" onClick={handleResetSearch}>
            <Falcon icon="close"/>
          </IconButton>
        </Box>
      </StyledMenuHeader>

      <MenuBody>
        {hasResult ? <MenuList>{children(renderProps)}</MenuList> : <EmptyState />}
      </MenuBody>
    </Menu>);
}
