import { gql } from '@apollo/client';
export const mutation = gql `
  mutation ApplyDocumentOperations(
    $documentId: ID!
    $input: FileSystemApplyPleditorOperationsInput!
  ) {
    applyFileSystemPleditorOperations(documentId: $documentId, input: $input) {
      documentId
      complementaryOperations
      versionNumber
    }
  }
`;
