import { RestrictedEditingMode } from 'pleditor';
import { useGetDocumentData } from './useGetDocumentData';
import { BaseComposedEditor } from '../BaseComposedEditor';
import { PleditorError } from '../components/PleditorError';
import { PleditorLoader } from '../components/PleditorLoader';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';
import { useGetUserIdAccess } from '../hooks/useGetUsersAccess';
import { AppApiConnector } from '../plugins/AppApiConnector';
import { Assistant } from '../plugins/Assistant';
import { BlockComment } from '../plugins/BlockComment';
import { CompanyUsers } from '../plugins/CompanyUsers';
import { CustomCommentsArchive } from '../plugins/CustomCommentsArchive';
import { ExternalUsers } from '../plugins/ExternalUsers';
import { FlexGap } from '../plugins/FlexGap';
import { InlineAssistantShortcut } from '../plugins/InlineAssistantShortcut';
import { ReviewPlugin } from '../plugins/Review';
import { SaveHandler } from '../plugins/SaveHandler';
import { ToggleComments } from '../plugins/ToggleComments';
export function AppComposedPleditor() {
    const currentUser = useGetCurrentUser();
    const { data, loading, error } = useGetDocumentData();
    const { usersExternal, usersWithoutAccess, usersWithAccess } = useGetUserIdAccess();
    if (loading) {
        return <PleditorLoader />;
    }
    if (error || !data.body) {
        return <PleditorError />;
    }
    const balloonToolbarItems = ['blockCommentCompact', '|', 'inlineAssistantShortcut'];
    return (<BaseComposedEditor documentId={data.documentId} currentUser={currentUser} usersExternal={usersExternal} usersWithAccess={usersWithAccess} usersWithoutAccess={usersWithoutAccess} extraPlugins={[
            Assistant,
            FlexGap,
            AppApiConnector,
            BlockComment,
            ToggleComments,
            RestrictedEditingMode,
            CompanyUsers,
            ExternalUsers,
            SaveHandler,
            CustomCommentsArchive,
            InlineAssistantShortcut,
            ReviewPlugin,
        ]} toolbar={{
            items: [
                '<->',
                'blockComment',
                '|',
                'customCommentsArchive',
                '|',
                'toggleComments',
                'toggleComposedHighlights',
            ],
        }} balloonToolbar={{ items: balloonToolbarItems }} data={data.body} versionNumber={data.versionNumber}/>);
}
