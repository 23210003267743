import { Command } from 'pleditor';
const HIGHLIGHT = 'AssistantSelectionHighlight';
/**
 * This commands allows us to add the `AssistantSelectionHighlight` to the current `Selection`.
 */
export class AssistantSelectionCommand extends Command {
    constructor(editor) {
        super(editor);
        this.affectsData = false;
    }
    refresh() {
        const { model } = this.editor;
        const { document } = model;
        this.isEnabled = model.schema.checkAttributeInSelection(document.selection, HIGHLIGHT);
    }
    execute(options) {
        const { onlyRemove = false } = options || {};
        const { model } = this.editor;
        const { document } = model;
        const { selection } = document;
        const { activeRanges = [] } = this;
        // Remove active highlights
        model.enqueueChange({ isLocal: false, isUndoable: false }, writer => {
            activeRanges.forEach(range => {
                writer.removeAttribute(HIGHLIGHT, range);
            });
        });
        if (onlyRemove) {
            return;
        }
        // Get ranges from current selection
        const newRanges = Array.from(model.schema.getValidRanges(selection.getRanges(), HIGHLIGHT));
        // Add new highlights
        model.enqueueChange({ isLocal: false, isUndoable: false }, writer => {
            newRanges.forEach(range => {
                writer.setAttribute(HIGHLIGHT, true, range);
            });
        });
        this.activeRanges = newRanges;
    }
}
