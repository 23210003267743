import { gql } from '@apollo/client';
export const query = gql `
  query GetDocumentDataForContextMenu($id: ID!) {
    fileSystemDocument: fileSystemDocument(id: $id) {
      id
      name
      folderId: parentId
    }
  }
`;
