import { gql } from '@apollo/client';
import { GetCommentFragment } from '../utils/commentFragment';
export const query = gql `
  query CommentThreadQuery($id: UUID!) {
    comments: fileSystemDocumentComments(where: { id: { eq: $id } }) {
      ...GetCommentFragment
    }
  }
  ${GetCommentFragment}
`;
