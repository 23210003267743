import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Checkbox, Chip, Menu, MenuBody, MenuHeader, MenuItem, MenuList, SearchInput, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { metadataConfig } from 'shared/domains/metadata';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
import { hasValidValue } from '../MetadataField/utils';
import { RightAligned } from './styled';
export function AddMetadataList() {
    const { data, toggleActiveEntry, activeEntries } = useDocumentMetadata();
    const [open, setOpen] = useState(false);
    const { result, search } = useFuse(data.properties, { keys: ['property.name'] });
    const searchResult = result.length ? result : data.properties;
    const getLabel = (name) => {
        const { label } = metadataConfig.get(name) || {};
        const fallback = t({ message: 'Unknown', comment: 'Metadata: Unknown metadata property' });
        return label || fallback;
    };
    const handleSelectProperty = (propertyName) => {
        toggleActiveEntry(propertyName);
        setOpen(false);
        search('');
    };
    return (<Menu open={open} onClose={() => setOpen(false)} target={<Button size="small" $stretch appearance="secondary" onClick={() => setOpen(!open)}>
          <Trans id="metadata.list.action.show" comment="Metadata actions: Button to show list of available metadata tags to add">
            Add
          </Trans>
          <Falcon icon="plus"/>
        </Button>}>
      <MenuHeader sticky>
        <SearchInput variant="line" onChange={e => search(e.target.value)}/>
      </MenuHeader>

      <MenuBody>
        <MenuList>
          {searchResult.map(({ property, pair }) => (<MenuItem key={property.id} as="label">
              {getLabel(property.name)}
              <RightAligned>
                {hasValidValue(pair.value) ? (<Chip icon={<Falcon icon="check"/>} label={t({
                    message: 'Added',
                    comment: 'Metadata: Information that a property is added',
                })} appearance="success"/>) : (<Checkbox checked={activeEntries.includes(property.name)} onChange={() => {
                    handleSelectProperty(property.name);
                }}/>)}
              </RightAligned>
            </MenuItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
