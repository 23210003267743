var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, Fragment } from 'react';
import { ApolloError } from '@apollo/client';
import { DialogHeader, DialogContent, GridRow, GridCell, Typography, Checkbox, DialogFooter, Button, Loader, } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useCancelApprovalRequest } from 'app/domains/esigning/hooks/useCancelApprovalRequest';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { ApprovalErrorSubcodes } from 'app/domains/esigning/utils';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { FormDialog } from 'shared/domains/forms';
import { ApprovalAlert } from './ApprovalAlert';
import { MessageField } from './MessageField';
import { getLabels } from './labels';
const APPROVAL_ERROR_SUBCODES = [
    ApprovalErrorSubcodes.AlreadyCancelled,
    ApprovalErrorSubcodes.AlreadyRejected,
];
export function CancelApprovalRequestDialog(props) {
    const { origin, onClose } = props;
    const { isRtcAvailable } = usePreview();
    const { documentId, approvalWorkflow } = useEsigning();
    const { refetch } = useLastApprovalRequest(documentId);
    const { cancelApprovalRequest } = useCancelApprovalRequest();
    const { approvalApproved } = approvalWorkflow;
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isApprovalStatusChanged, setIsApprovalStatusChanged] = useState(false);
    const labels = getLabels(approvalApproved, origin);
    const handleChangeCheckbox = () => setChecked(value => !value);
    const handleClose = () => {
        if (isApprovalStatusChanged) {
            refetch();
        }
        onClose();
    };
    const handleError = (error) => {
        var _a;
        setLoading(false);
        if (error instanceof ApolloError) {
            const subcode = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode && APPROVAL_ERROR_SUBCODES.includes(subcode)) {
                setIsApprovalStatusChanged(true);
                if (subcode === ApprovalErrorSubcodes.AlreadyCancelled) {
                    return setAlert({ appearance: 'warning', message: labels.alreadyCancelled });
                }
                if (subcode === ApprovalErrorSubcodes.AlreadyRejected) {
                    return setAlert({ appearance: 'warning', message: labels.alreadyRejected });
                }
            }
        }
        return setAlert({ appearance: 'danger', message: labels.requestError });
    };
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        setAlert(null);
        try {
            if (isRtcAvailable) {
                documentFlush({ state: 'initialized' });
            }
            yield cancelApprovalRequest(values.message);
            handleClose();
        }
        catch (e) {
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            handleError(e);
        }
    });
    const initialValues = {
        message: undefined,
    };
    return (<FormDialog size="sm" onClose={handleClose} onSubmit={handleSubmit} initialValues={initialValues}>
      <DialogHeader title={labels.title}/>
      <DialogContent>
        <GridRow>
          <GridCell width={12}>
            <Typography>{labels.message}</Typography>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell width={12}>
            <MessageField />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell width={12}>
            <Checkbox checked={checked} disabled={loading || isApprovalStatusChanged} onChange={handleChangeCheckbox} label={labels.checkbox}/>
          </GridCell>
        </GridRow>
        {alert && (<GridRow>
            <GridCell width={12}>
              <ApprovalAlert {...alert}/>
            </GridCell>
          </GridRow>)}
      </DialogContent>
      <DialogFooter>
        {isApprovalStatusChanged ? (<Button appearance="primary" onClick={handleClose}>
            {labels.close}
          </Button>) : (<Fragment>
            <Button appearance="secondary" disabled={loading} onClick={handleClose}>
              {labels.cancel}
            </Button>
            <Button appearance="primary" disabled={loading || !checked} type="submit">
              {labels.confirm}
              {loading && <Loader />}
            </Button>
          </Fragment>)}
      </DialogFooter>
    </FormDialog>);
}
