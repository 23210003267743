import { AttributeOperation, Plugin } from 'pleditor';
import { isLocalOperation } from '../SaveHandler/utils';
export class DocumentListsIdCorrectorPlugin extends Plugin {
    init() {
        const { editor } = this;
        editor.model.on('applyOperation', (event, operations) => {
            operations.forEach(operation => {
                /*
                 * we do not need to apply any transformations on the operations
                 * that are applied when the document content is set (during the editor init)
                 */
                if (operation.baseVersion === null) {
                    return;
                }
                if (isLocalOperation(operation) &&
                    operation instanceof AttributeOperation &&
                    operation.key === 'listItemId' &&
                    ['removeAttribute', 'changeAttribute'].includes(operation.type)) {
                    const { range } = operation;
                    const attrItem = range.getContainedElement();
                    if (!attrItem) {
                        return;
                    }
                    const thisElementsListItemId = operation.newValue;
                    const { previousSibling } = attrItem;
                    if ((previousSibling === null || previousSibling === void 0 ? void 0 : previousSibling.getAttribute('listItemId')) === thisElementsListItemId) {
                        const enhancedOperation = operation.clone();
                        Object.assign(enhancedOperation, {
                            extra: Object.assign(Object.assign({}, (enhancedOperation.extra || {})), { copyIdFromPreviousSibling: true }),
                        });
                        Object.assign(operation, { toReplace: [enhancedOperation] });
                    }
                }
            });
        }, { priority: 'high' });
    }
}
