import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Calendar, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDocumentNoticeDate } from 'app/domains/metadata';
import { ChipDropdown } from 'shared/domains/common-ui';
import { useActiveDocument } from 'shared/domains/documents';
import { localeConfig } from 'shared/domains/languages/config';
export function NoticeDateChip() {
    const { id } = useActiveDocument();
    const chipRef = useRef();
    const closeChip = () => { var _a; return (_a = chipRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown(); };
    const localeCode = localeConfig.getActiveLocale().code;
    const action = useDocumentNoticeDate({
        documentId: id,
        onCompleted: closeChip,
    });
    if (!action.data.warning) {
        return null;
    }
    return (<ChipDropdown appearance="warning" ref={chipRef} icon={<Falcon icon="circle-exclamation"/>} label={t({
            comment: 'Actionable insights: Document notice date chip label',
            message: 'Notice date',
        })} secondary={action.data.localizedDate} size="medium" dropdownContent={<LoaderOverlay transparent enabled={action.savingData}>
          <Calendar value={action.data.value} locale={localeCode} onChange={action.update}/>
        </LoaderOverlay>}/>);
}
