import { useEffect, useState } from 'react';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
const ONE_MINUTE = 60000;
export function useIsAccessTokenExpired() {
    const auth = useAuth();
    const [isAccessTokenExpired, setIsAccessTokenExpired] = useState(false);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const interval = setInterval(() => {
            auth.getAccessTokenSilently().catch(() => setIsAccessTokenExpired(true));
        }, ONE_MINUTE);
        return () => clearInterval(interval);
    }, [auth]);
    return { isAccessTokenExpired };
}
