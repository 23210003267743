import { gql } from '@apollo/client';
export const QUERY = gql `
  query getSignRequestAttachmentForDownload($id: ID!) {
    document: fileSystemDocument(id: $id) {
      id
      lastSignRequest {
        id
        attachments {
          id
          signed {
            url
          }
        }
      }
    }
  }
`;
