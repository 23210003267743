import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DeleteDraftDialog } from './DeleteDraftDialog';
export function DraftActionMenu(props) {
    const { result } = props;
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const toggleDeleteDialog = () => setOpenDeleteDialog(!openDeleteDialog);
    return (<Fragment>
      <Menu target={<IconButton $round appearance="ghost" size="small" onClick={() => setOpen(!open)}>
            <Falcon icon="ellipsis-h"/>
          </IconButton>} onClose={() => setOpen(false)} open={open}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={() => {
            setOpenDeleteDialog(true);
            setOpen(false);
        }}>
              <Trans comment="Search Table Row Context Menu item - Delete">Delete</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>

      {openDeleteDialog && <DeleteDraftDialog result={result} onClose={toggleDeleteDialog}/>}
    </Fragment>);
}
