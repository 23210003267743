import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { updateTaskCompletedMutation } from './updateTaskCompletedMutation';
export function useUpdateTaskCompleted() {
    const { addToast } = useToast();
    const [mutate, { error, loading }] = useMutation(updateTaskCompletedMutation);
    const updateTaskCompleted = (options) => {
        const { id, isCompleted, status } = options;
        return mutate({ variables: { id, isCompleted, status } })
            .then(() => {
            addToast({ title: t `Updated task`, appearance: 'success' });
        })
            .catch(() => {
            addToast({ title: t `Failed to update task`, appearance: 'destructive' });
        });
    };
    return { updateTaskCompleted, loading, error };
}
