export function buildEsigningFilter(items) {
    const validItems = items.filter(item => Boolean(item));
    const status = [];
    if (!validItems.length) {
        return undefined;
    }
    validItems.forEach(id => {
        if (id === 'pending') {
            status.push('PENDING', 'OPENED');
        }
        if (id === 'signed') {
            status.push('SIGNED');
        }
        if (id === 'rejected') {
            status.push('REJECTED');
        }
    });
    return { in: status };
}
