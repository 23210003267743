import { searchFilterCopy } from './searchFilterCopy';
import { DateRangeFilter, useDateRangeFilter } from '../DateRangeFilter';
export function ProbationaryPeriodExpirationDate(props) {
    const { startDate, endDate } = props;
    const filter = useDateRangeFilter({
        paramKey: 'probationaryPeriodExpirationDate',
        name: searchFilterCopy().probationaryPeriodExpirationDate,
        initialValues: {
            startDate,
            endDate,
        },
    });
    return <DateRangeFilter filter={filter}/>;
}
