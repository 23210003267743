import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function Error() {
    return (<Container>
      <Alert appearance="danger" title={t({
            message: 'Error',
            comment: 'template permission drawer: failed to load users alert title',
        })} message={t({
            message: 'Failed to load users. Please try again later.',
            comment: 'template permission drawer: failed to load users alert message',
        })} icon={<Falcon icon="cog"/>}/>
    </Container>);
}
