var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import mutation from './mutation';
function useSendMessage(options) {
    const { inviteId, recipientId, onSuccess } = options;
    const [addMessage] = useMutation(mutation);
    const { addToast } = useToast();
    const sendMessage = (values, helpers) => __awaiter(this, void 0, void 0, function* () {
        const { message } = values;
        try {
            yield addMessage({
                variables: {
                    inviteId,
                    recipientId,
                    message,
                },
            });
            onSuccess();
            helpers.resetForm();
        }
        catch (error) {
            addToast({ title: t `Message could not be sent.`, appearance: 'destructive' });
        }
    });
    return sendMessage;
}
export default useSendMessage;
