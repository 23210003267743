var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MUTATION } from './mutation';
export function addSuggestion(apiClient, input) {
    return __awaiter(this, void 0, void 0, function* () {
        const { suggestionId, documentId, type, data, attributes, subType } = input;
        const response = yield apiClient.mutate({
            mutation: MUTATION,
            variables: {
                suggestionId,
                documentId,
                type: type,
                data,
                attributes,
                subType,
            },
        });
        const { createSuggestion } = response.data || {};
        return {
            id: (createSuggestion === null || createSuggestion === void 0 ? void 0 : createSuggestion.id) || '',
            type: createSuggestion === null || createSuggestion === void 0 ? void 0 : createSuggestion.type,
            authorId: (createSuggestion === null || createSuggestion === void 0 ? void 0 : createSuggestion.userId) || '',
            createdAt: (createSuggestion === null || createSuggestion === void 0 ? void 0 : createSuggestion.createdAt) ? new Date(createSuggestion === null || createSuggestion === void 0 ? void 0 : createSuggestion.createdAt) : null,
            attributes: JSON.parse(attributes),
        };
    });
}
