import { useSearchContext } from '../../SearchProvider';
export function useSingleSelectFilter(options) {
    const { items, paramKey, name, loading } = options;
    const { setParamValue, isFilterActive, isFilterInDraft, removeFilterFromDraft, getSingleSelectFilterValue, } = useSearchContext();
    const paramValue = getSingleSelectFilterValue(paramKey) || '';
    const currentItems = items.find(item => item.id && item.id === paramValue);
    const select = (newItem) => {
        setParamValue(paramKey, [newItem.id]);
        removeFilterFromDraft(paramKey);
    };
    const clear = () => {
        setParamValue(paramKey, []);
        removeFilterFromDraft(paramKey);
    };
    const isActive = isFilterActive(paramKey);
    const isInDraft = isFilterInDraft(paramKey);
    const value = (currentItems === null || currentItems === void 0 ? void 0 : currentItems.title) || '';
    return { items, currentItems, select, clear, value, isActive, name, loading, isInDraft };
}
