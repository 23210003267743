import { Fragment, useState } from 'react';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { SectionListItem } from '../../../components';
import { useAttachments } from '../AttachmentsProvider';
export function AttachmentItem(props) {
    const { name, path } = props;
    const { disabled, removeAttachment } = useAttachments();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const showDialog = () => setShowConfirmDialog(true);
    const hideDialog = () => setShowConfirmDialog(false);
    const handleRemoveAttachment = () => {
        removeAttachment(path);
    };
    return (<Fragment>
      {showConfirmDialog && (<ConfirmDeleteDialog onCancel={hideDialog} onConfirm={handleRemoveAttachment}/>)}
      <SectionListItem icon={<Falcon icon="file-pdf"/>} primary={name} endElement={<IconButton $round size="small" appearance="ghost" disabled={disabled} onClick={showDialog}>
            <Falcon icon="trash-can"/>
          </IconButton>}/>
    </Fragment>);
}
