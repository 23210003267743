import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { ToggleButton, SectionSubHeading, Button } from '@pocketlaw/tetris';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSearchContext } from 'app/domains/global-search';
import { Drawer } from 'shared/domains/common-ui';
import { StyledSpacer, DrawerWrapper, Container } from './styled';
export function FilterDrawer(props) {
    const { children } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { getActiveFilters } = useSearchContext();
    const activeFilters = getActiveFilters();
    const handleClick = () => setDrawerOpen(!drawerOpen);
    return (<Container>
      <Spacer>
        <ToggleButton onClick={handleClick} $toggled={Boolean(activeFilters.length)} $stretch>
          <Falcon icon="filter-list" $size={16}/>
          <Trans comment="Button label to open filter drawer">Filters</Trans>
          <span role="presentation"/>
        </ToggleButton>
      </Spacer>
      <Drawer open={drawerOpen} position="right">
        <DrawerWrapper>
          <StyledSpacer>
            <SectionSubHeading noMargin>
              <Trans>Filter search</Trans>
            </SectionSubHeading>
          </StyledSpacer>

          {children}

          <Button $stretch onClick={() => setDrawerOpen(false)} appearance="ghost">
            <Trans>Done</Trans>
          </Button>
        </DrawerWrapper>
      </Drawer>
    </Container>);
}
