const BASE_PATH = '/search';
export function constructGlobalSearchURL(schema) {
    const urlParams = new URLSearchParams();
    if (schema) {
        Object.entries(schema).forEach(([key, values]) => {
            const valueArray = Array.isArray(values) ? values : [values];
            valueArray.forEach(value => {
                urlParams.append(key, value);
            });
        });
    }
    const params = urlParams.toString();
    const url = `${BASE_PATH}?${params}`;
    return url;
}
