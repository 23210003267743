import { useQuery } from '@apollo/client';
import { query } from './query';
import { useActiveDocument } from '../../../../../hooks/usePreview';
export function useGetDocumentData() {
    const { id } = useActiveDocument();
    const { data, loading, error } = useQuery(query, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });
    const { htmlBody, versionNumber } = (data === null || data === void 0 ? void 0 : data.document) || {};
    return {
        loading,
        error,
        data: {
            documentId: id,
            body: htmlBody || '',
            versionNumber: versionNumber || 1,
        },
    };
}
