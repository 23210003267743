import { createContext, useEffect, useState } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { LoadingIndicator } from '../LoadingIndicator';
import { useFileBrowser } from '../useFileBrowser';
const PAGE_SIZE = 50;
export const FileBrowserContext = createContext({});
/**
 * Filesystem browser provider. It provides the data and methods needed to display a file browser.
 * For additional customization, you can use the `useFileBrowser` hook and build custom file browsers.
 */
export function FileBrowserProvider(props) {
    const { disabled: disabledAll = false, limit = PAGE_SIZE, children } = props;
    const [offset, setOffset] = useState(0);
    const { entries, loading, error, onSelect, selected, breadcrumbs, selfId, count, location } = useFileBrowser(Object.assign(Object.assign({}, props), { limit,
        offset }));
    useEffect(() => {
        setOffset(0);
    }, [location]);
    const value = {
        breadcrumbs,
        entries,
        onSelect,
        selfId,
        loading,
        disabledAll,
        selected,
        limit,
        offset,
        setOffset,
        count,
    };
    if (error) {
        return <ErrorMessage />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    return <FileBrowserContext.Provider value={value}>{children}</FileBrowserContext.Provider>;
}
