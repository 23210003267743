import { Fragment } from 'react';
import { TrackChanges } from 'pleditor';
import { BaseFreeTextEditor } from './BaseFreeTextEditor';
import { FeatureAvailability } from './components/FeatureAvailability';
import { PleditorError } from './components/PleditorError';
import { PleditorLoader } from './components/PleditorLoader';
import { useGetCurrentUser } from './hooks/useGetCurrentUser';
import { useGetDocumentData } from './hooks/useGetDocumentData';
import { useGetUserIdAccess } from './hooks/useGetUsersAccess';
import { AppApiConnector } from './plugins/AppApiConnector';
import { Assistant } from './plugins/Assistant';
import { CompanyUsers } from './plugins/CompanyUsers';
import { CustomCommentsArchive } from './plugins/CustomCommentsArchive';
import { EditMode } from './plugins/EditMode';
import { ExternalUsers } from './plugins/ExternalUsers';
import { FlexGap } from './plugins/FlexGap';
import { InlineAssistantShortcut } from './plugins/InlineAssistantShortcut';
import { InlineComment } from './plugins/InlineComment';
import { ReviewPlugin } from './plugins/Review';
import { SaveHandler } from './plugins/SaveHandler';
import { SuggestionsAdapter } from './plugins/SuggestionsAdapter';
import { ToggleComments } from './plugins/ToggleComments';
import { useActiveDocument } from '../../../hooks/usePreview';
export function AppFreeTextPleditor() {
    const { id, company } = useActiveDocument();
    const { data, loading, error } = useGetDocumentData(id, company === null || company === void 0 ? void 0 : company.id);
    const currentUser = useGetCurrentUser();
    const { usersExternal, usersWithoutAccess, usersWithAccess } = useGetUserIdAccess();
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const balloonToolbarItems = [
        'inlineCommentCompact',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'inlineAssistantShortcut',
    ];
    return (<Fragment>
      <FeatureAvailability />
      <BaseFreeTextEditor documentId={id} currentUser={currentUser} data={data.body || ''} versionNumber={data.versionNumber} usersExternal={usersExternal} usersWithAccess={usersWithAccess} usersWithoutAccess={usersWithoutAccess} featureAvailability={data.featureAvailability} extraPlugins={[
            ReviewPlugin,
            Assistant,
            AppApiConnector,
            InlineComment,
            ToggleComments,
            TrackChanges,
            CompanyUsers,
            ExternalUsers,
            SuggestionsAdapter,
            SaveHandler,
            EditMode,
            FlexGap,
            CustomCommentsArchive,
            InlineAssistantShortcut,
        ]} balloonToolbar={{ items: balloonToolbarItems }} toolbar={{
            items: [
                'undo',
                'redo',
                '|',
                'heading',
                '|',
                'bold',
                'italic',
                'strikethrough',
                'underline',
                'bulletedList',
                'numberedList',
                '|',
                'link',
                'alignment',
                'outdent',
                'indent',
                '<->',
                'inlineComment',
                '|',
                'customCommentsArchive',
                '|',
                'toggleComments',
                '|',
                'editMode',
            ],
        }}/>
    </Fragment>);
}
