import { Plugin } from 'pleditor';
import { operationHandler } from './operationHandler';
/**
 * UndoRedo Plugin for Editor.
 *
 * Key Responsibilities:
 * 1. Interception: It intercepts operations before they are sent to the autosave plugin.
 * 2. Modification: It can modify operations, specifically those related to the graveyard (a place where deleted objects are kept).
 *
 * Problem Context:
 * The backend service is stateless with regard to editor instances; it doesn't maintain a live editor instance between calls.
 * Because of this, the backend doesn't have a 'graveyard' (a concept in some editor implementations for tracking deleted items).
 * As a result, directly moving something from the graveyard to the main roots (undoing a delete) isn't possible via backend calls.
 *
 * Solution:
 * This plugin acts as a middleware to handle such graveyard-related operations, ensuring the undo functionality works seamlessly.
 *
 */
export class UndoRedo extends Plugin {
    init() {
        const { editor } = this;
        editor.model.on('applyOperation', (event, operations) => {
            operations.forEach(operation => operationHandler(editor, operation));
        }, { priority: 'highest' });
    }
}
