import { Alert, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledAlert = styled(Alert) `
  margin-top: ${themeGet('spacing.xl')};
`;
export const RightAligned = styled.div `
  display: flex;
  margin-left: auto;
  align-items: center;
`;
