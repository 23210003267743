import { t } from '@lingui/macro';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
export const getPromptNameByPromptType = (type) => {
    switch (type) {
        case ChatMessageType.SelectionSuggest:
        case ChatMessageType.DocumentSuggest:
            return t({
                message: 'Suggest clause',
                comment: 'Document assistant chat - prompt label - suggest clause',
            });
        case ChatMessageType.DocumentSummarize:
        case ChatMessageType.SelectionSummarize:
            return t({
                message: 'Summarize',
                comment: 'Document assistant chat - prompt label - summarize',
            });
        case ChatMessageType.SelectionTranslate:
            return t({
                message: 'Translate',
                comment: 'Document assistant chat - prompt label - translate',
            });
        case ChatMessageType.SelectionExplain:
            return t({ message: 'Explain', comment: 'Document assistant chat - prompt label - explain' });
        case ChatMessageType.SelectionRewrite:
            return t({ message: 'Rewrite', comment: 'Document assistant chat - prompt label - rewrite' });
        case ChatMessageType.DocumentAsk:
        case ChatMessageType.SelectionAsk:
        case ChatMessageType.DocumentAskWithQuotes:
        case ChatMessageType.SelectionAskWithQuotes:
            return t({
                message: 'Question',
                comment: 'Document assistant chat - prompt label - question',
            });
        case ChatMessageType.DocumentCompare:
            return t({
                message: 'Compare',
                comment: 'Document assistant chat - prompt label - compare',
            });
        default:
            return undefined;
    }
};
