import { t, Trans } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { getFirstPlanSortedByOrdinal, isFreePlan } from 'app/domains/companies';
import { getCurrentMarket } from 'app/domains/markets/config';
import { useRole } from 'app/domains/users/hooks/useRole';
import { getBookDemoUrlByMarket } from '../getBookDemoUrlByMarket';
function SubscriptionAlert(props) {
    var _a, _b, _c;
    const { currentPlan, plans, composerPlans } = props;
    const role = useRole();
    const packageName = (_a = plans === null || plans === void 0 ? void 0 : plans.find(isFreePlan)) === null || _a === void 0 ? void 0 : _a.name;
    const planName = (_b = currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.name) !== null && _b !== void 0 ? _b : packageName;
    const nextPlan = getFirstPlanSortedByOrdinal(composerPlans);
    const nextPlanName = (_c = nextPlan === null || nextPlan === void 0 ? void 0 : nextPlan.name) !== null && _c !== void 0 ? _c : t `a plan with more benefits`;
    const marketCode = getCurrentMarket().code;
    const alertMessage = role.can('manage:settings')
        ? t({
            message: `This document is not included in ${planName}. Upgrade to get access.`,
            comment: '',
        })
        : t({
            message: `This contract is not included in ${planName}. Ask your company owner to upgrade to get access.`,
            comment: '',
        });
    return (<Alert appearance="secondary" icon={<Falcon icon="lock-alt"/>} title={t({ message: 'Contract not included', comment: '' })} message={alertMessage}>
      {role.can('manage:settings') && (<Button as={Link} to={`/checkout?planId=${nextPlan === null || nextPlan === void 0 ? void 0 : nextPlan.id}`}>
          <Trans>Get {nextPlanName}</Trans>
        </Button>)}
      <Button as="a" rel="noopener noreferrer" target="_blank" href={getBookDemoUrlByMarket(marketCode)} appearance="secondary">
        <Trans>Talk to us</Trans>
      </Button>
    </Alert>);
}
export default SubscriptionAlert;
