import { useEffect, useRef, useState } from 'react';
import { COLLAPSED_DESCRIPTION_HEIGHT } from './styled';
export function useExpandButton() {
    const [expanded, setExpanded] = useState(false);
    const contentRef = useRef(null);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const toggleExpanded = () => setExpanded(prevState => !prevState);
    useEffect(() => {
        if (contentRef.current === null) {
            return undefined;
        }
        const contentRefCurrent = contentRef.current;
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                if (entry.contentRect.height > COLLAPSED_DESCRIPTION_HEIGHT) {
                    setShowExpandButton(true);
                }
                else {
                    setShowExpandButton(false);
                }
            });
        });
        resizeObserver.observe(contentRefCurrent);
        return () => {
            resizeObserver.unobserve(contentRefCurrent);
        };
    }, []);
    return { contentRef, expanded, toggleExpanded, showExpandButton };
}
