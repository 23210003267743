import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button, Alert, Avatar, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function NoAccessDialog(props) {
    const { onClose } = props;
    return (<Dialog size="md" onClose={onClose}>
      <DialogHeader title={t({
            message: 'Missing permission',
            comment: 'no permission modal - dialog header text',
        })} media={<Avatar shape="square" appearance="danger" size="xl">
            <Falcon icon="xmark"/>
          </Avatar>}/>
      <DialogContent>
        <Alert appearance="danger" message={t({
            message: 'You do not have permission to this template. Ask a company owner to get access to this template',
            comment: 'no permission modal - alert body text',
        })}/>
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={onClose}>
          <Trans comment="No permission modal - close button">Close</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
