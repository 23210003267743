import { TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { ActionCell } from '../cells/ActionCell';
import { AmountCell } from '../cells/AmountCell';
import { BooleanCell } from '../cells/BooleanCell';
import { ContractOwnerCell } from '../cells/ContractOwnerCell';
import { CurrencyCell } from '../cells/CurrencyCell';
import { DateCell } from '../cells/DateCell';
import { DocumentCategory } from '../cells/DocumentCategory';
import { DocumentStatusCell } from '../cells/DocumentStatusCell';
import { DocumentTypeCell } from '../cells/DocumentTypeCell';
import { ESigningStatusCell } from '../cells/ESigningStatusCell';
import { GoverningLawCell } from '../cells/GoverningLawCell';
import { NotesCell } from '../cells/NotesCell';
import { NoticePeriodDays } from '../cells/NoticePeriodDays';
import { NumberCell } from '../cells/NumberCell';
import { ProcessingOfPersonalDataCell } from '../cells/ProcessingOfPersonalDataCell';
import { TextCell } from '../cells/TextCell';
import { TitleCell } from '../cells/TitleCell';
import { TypeCell } from '../cells/TypeCell';
import { UserCell } from '../cells/UserCell';
export function SearchResultsTableCell(props) {
    var _a, _b;
    const { column, result, selected, onSelect } = props;
    const { id: columnId, stickyColumn } = column;
    switch (columnId) {
        case 'title':
            return (<TitleCell headers={columnId} highlight={result.meta.highlight} entityId={result.entityId} name={result.name} type={result.type} stickyColumn={stickyColumn} onSelect={onSelect} selected={selected}/>);
        case 'type':
            return <TypeCell headers={columnId} type={result.type} stickyColumn={stickyColumn}/>;
        case 'createdAt':
            return <DateCell date={result.createdAt} headers={columnId} stickyColumn={stickyColumn}/>;
        case 'createdBy':
            return (<UserCell name={(_b = (_a = result.createdByUser) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'updatedAt':
            return <DateCell date={result.updatedAt} headers={columnId} stickyColumn={stickyColumn}/>;
        case 'terminationDate':
            return (<DateCell date={result.metadataDocumentTerminationDate} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'noticeDate':
            return (<DateCell date={result.metadataDocumentNoticeDate} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'dateSigned':
            return (<DateCell date={result.metadataDocumentDateSigned} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'startDate':
            return (<DateCell date={result.metadataDocumentStartDate} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'documentType':
            return (<DocumentTypeCell documentType={result.metadataDocumentDocumentType} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'esigningStatus':
            return (<ESigningStatusCell status={result.metadataDocumentEsigningStatus} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'contractOwner':
            return (<ContractOwnerCell userIds={result.metadataDocumentContractOwner} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'counterParty':
            return (<TextCell primary={result.metadataDocumentCounterparty} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'currency':
            return (<CurrencyCell headers={columnId} value={result.metadataDocumentCurrency} stickyColumn={stickyColumn}/>);
        case 'governingLaw':
            return (<GoverningLawCell headers={columnId} value={result.metadataDocumentGoverningLaw} stickyColumn={stickyColumn}/>);
        case 'notes':
            return (<NotesCell headers={columnId} text={result.metadataDocumentNotes} stickyColumn={stickyColumn}/>);
        case 'noticePeriodMonths':
            return (<NumberCell headers={columnId} value={result.metadataDocumentNoticePeriodMonths} stickyColumn={stickyColumn}/>);
        case 'originalTermMonths':
            return (<NumberCell value={result.metadataDocumentOriginalTermMonths} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'renewalTermMonths':
            return (<NumberCell headers={columnId} value={result.metadataDocumentRenewalTermMonths} stickyColumn={stickyColumn}/>);
        case 'totalContractCost':
            return (<AmountCell headers={columnId} amount={result.metadataDocumentTotalContractCost} currency={result.metadataDocumentCurrency} stickyColumn={stickyColumn}/>);
        case 'totalContractValue':
            return (<AmountCell headers={columnId} amount={result.metadataDocumentTotalContractValue} currency={result.metadataDocumentCurrency} stickyColumn={stickyColumn}/>);
        case 'materialAgreement':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentMaterialAgreement} stickyColumn={stickyColumn}/>);
        case 'deviateFromStandard':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentDeviateFromStandard} stickyColumn={stickyColumn}/>);
        case 'automaticRenewal':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentAutomaticRenewal} stickyColumn={stickyColumn}/>);
        case 'isSharedExternally':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentIsSharedExternally} stickyColumn={stickyColumn}/>);
        case 'active':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentActive} stickyColumn={stickyColumn}/>);
        case 'outsourcing':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentOutsourcing} stickyColumn={stickyColumn}/>);
        case 'processingOfPersonalData':
            return (<ProcessingOfPersonalDataCell headers={columnId} value={result.metadataDocumentProcessingOfPersonalData} stickyColumn={stickyColumn}/>);
        case 'documentCategory':
            return (<DocumentCategory headers={columnId} value={result.metadataDocumentDocumentCategory} stickyColumn={stickyColumn}/>);
        case 'noticePeriodDays':
            return (<NoticePeriodDays headers={columnId} value={result.metadataDocumentNoticePeriodDays} stickyColumn={stickyColumn}/>);
        case 'documentStatus':
            return (<DocumentStatusCell status={result.metadataDocumentStatus} entityId={result.id} documentId={result.entityId} 
            // eslint-disable-next-line no-underscore-dangle
            typename={result.__typename || ''} type={result.type} headers={columnId} stickyColumn={stickyColumn}/>);
        case 'action':
            return <ActionCell headers={column.id} result={result} stickyColumn={stickyColumn}/>;
        default:
            return (<TableBodyCell headers={columnId}>
          <TableCellEmpty />
        </TableBodyCell>);
    }
}
