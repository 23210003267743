import { themeGet, pxToRem, breakpoints } from '@pocketlaw/tetris';
import styled, { createGlobalStyle } from 'styled-components';
import { assistantHighlightStyles } from './styles/assistantHighlightStyles';
import { balloonToolbarStyles } from './styles/balloonToolbar';
import { commentErrorMessageStyles } from './styles/comment';
import { customBlockStyles } from './styles/customBlock';
import { dropdownStyles } from './styles/dropdownStyles';
import { listStyles } from './styles/list';
import { appMentionBadgeThemeStyles, externalMentionBadgeThemeStyles, mentionStyles, } from './styles/mentions';
import { reviewHighlightStyles } from './styles/reviewRedlineHighlightStyles';
import { revisionHistoryToolbarStyles } from './styles/revisionHistoryStyles';
import { editorSidebarStyles } from './styles/sidebar';
import { suggestionStyles } from './styles/suggestionStyles';
import { tableWithoutHandleStyles, tableStyles } from './styles/tables';
import { themeStyles } from './styles/theme';
import { toolbarStyles } from './styles/toolbar';
import { userAvatar } from './styles/userAvatar';
import { DocumentPageContainer } from '../../styled';
export const PleditorWrapper = styled(DocumentPageContainer) `
  margin-bottom: 0;
  line-height: 1.5;
`;
export const PleditorToolbarContainer = styled.div `
  z-index: 2;
  position: sticky;
  top: 0;
  margin: ${props => (props.$noMargin ? '0' : `0 ${themeGet('spacing.xl')}`)};

  ${toolbarStyles}
  ${revisionHistoryToolbarStyles};
`;
PleditorToolbarContainer.defaultProps = {
    id: 'pleditor-toolbar',
};
export const PleditorContainer = styled.div `
  z-index: 0;
  width: auto;
  display: flex;
  flex: 1;
  justify-content: center;
  gap: ${themeGet('spacing.lg')};

  b,
  strong {
    font-weight: bold !important;
  }

  .ck.ck-content.ck-editor__editable:not(.ck-comment__input .ck.ck-content.ck-editor__editable) {
    flex: 1;
    max-width: ${pxToRem(800)};
    color: ${themeGet('colors.text.300')};
    padding: ${themeGet('spacing.2xl')};
    background-color: ${themeGet('colors.background.50')};

    &:not(.ck-editor__nested-editable).ck-rounded-corners {
      border-radius: ${themeGet('borderRadius.lg')};

      &.ck-focused {
        box-shadow: none;
        border: ${props => props.$isDocumentPreview
    ? `solid ${pxToRem(1)} transparent`
    : `solid ${pxToRem(1)} ${themeGet('colors.gray.100')}`};
      }
    }

    ${breakpoints.xs} {
      padding: ${themeGet('spacing.xl')};
    }

    ${breakpoints.lg} {
      padding: ${themeGet('spacing.4xl')};
    }
  }
`;
export const PleditorSidebar = styled.div `
  width: 25%;
  transition: all 225ms ease-in-out;

  ${editorSidebarStyles}
  ${suggestionStyles}
`;
export const ExternalPleditorStyles = createGlobalStyle `
  ${externalMentionBadgeThemeStyles};
`;
export const AppPleditorStyles = createGlobalStyle `
  ${appMentionBadgeThemeStyles};
`;
export const ComposedPleditorStyles = createGlobalStyle `
  ${tableWithoutHandleStyles};
`;
export const BasePleditorStyles = createGlobalStyle `
  .ck {
    ${themeStyles};
  }
  
  ${commentErrorMessageStyles};
  ${listStyles};
  ${customBlockStyles};
  ${mentionStyles};
  ${userAvatar};
  ${tableStyles};
  ${balloonToolbarStyles};
  ${assistantHighlightStyles};
  ${reviewHighlightStyles};
  ${dropdownStyles};
`;
