import { gql } from '@apollo/client';
// Import error if importing FieldFragment from 'app/domains/templates'
import { FieldFragment } from 'app/domains/templates/hooks/useEditorDraft';
export const draftQuery = gql `
  query draft($composerId: Unstable__UUID!) {
    composer: unstable__composer(id: $composerId) {
      id

      metadata {
        name
        description
        marketLocaleCode
      }

      marketCode
      estimatedTime

      tree {
        id
        version
        fields {
          id
          refId: ref
          ... on GroupField {
            ...FieldFragment
            fields {
              ...FieldFragment
            }
          }
        }
      }

      category {
        id
        metadata {
          title
          marketLocaleCode: market_locale_code
        }
      }

      approval {
        id
        approvers {
          id
          companyUser {
            id
            user {
              id
              name
            }
          }
        }
      }

      templates {
        id
        body

        marketLocale {
          locale
          code
        }

        latestVersion {
          templateId
          reference
          astBody
        }
      }

      composerPlans {
        id
        plan {
          id
          name
          ordinal
          type
        }
      }
    }
  }
  ${FieldFragment}
`;
