import { gql } from '@apollo/client';
export const getUserDocumentAclsQuery = gql `
  query userDocumentAcls($documentId: ID!, $where: GetExternalSharingInvitesArgsWhere) {
    companyUsers {
      id
      role
      user {
        id
      }
    }
    entry: fileSystemEntry(id: $documentId) {
      id
      acls {
        id
        userId
        actions
      }
    }
    invites: externalSharingInvites(where: $where) {
      id
      invitee {
        id
        name
      }
    }
  }
`;
