import { Alert, Drawer, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledAlert = styled(Alert) `
  margin-bottom: ${themeGet('spacing.xl')};
`;
export const StyledDrawer = styled(Drawer) `
  width: 100%;
  max-width: ${pxToRem(360)};
  background-color: ${themeGet('colors.background.50')};
`;
export const ContentContainer = styled.div `
  padding: ${unit('xl')};
`;
