import { useQuery } from '@apollo/client';
import { useSearchContext } from 'app/domains/global-search';
import { getAllMarketCodes, supportedMarketCodes } from 'app/domains/markets/config';
import { GlobalSearchDocument } from './GlobalSearch.gql';
import { transformSearchQuery } from './transformSearchQuery';
export function useSearchQuery(variables, options) {
    const { referrer, sortBy, resultOffset } = useSearchContext();
    const { query } = variables;
    const queryVariables = Object.assign(Object.assign({}, variables), { referrer, offset: resultOffset });
    const userMarketCodes = getAllMarketCodes();
    const excludedMarketCodes = supportedMarketCodes.filter(item => !userMarketCodes.includes(item));
    queryVariables.where = Object.assign(Object.assign({}, queryVariables.where), { marketCode: {
            notIn: excludedMarketCodes,
        } });
    const { sortId, sortOrder } = sortBy || {};
    if (sortId && sortOrder) {
        queryVariables.orderBy = { [sortId]: sortOrder };
    }
    const { data, loading, error } = useQuery(GlobalSearchDocument, {
        variables: queryVariables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        skip: options === null || options === void 0 ? void 0 : options.skip,
    });
    const transformQueryData = transformSearchQuery(data);
    return {
        data: transformQueryData,
        loading,
        error,
        query,
    };
}
