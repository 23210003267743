import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function DeviateFromStandardFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'deviateFromStandard',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().deviateFromStandard,
    });
    return <SingleSelectFilter filter={filter}/>;
}
