import { useQuery } from '@apollo/client';
import { getCurrentMarket } from 'app/domains/markets/config';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { ComposersCountDocument } from './ComposersCount.gql';
import { useCurrentCompanyPlan } from '../../useCurrentCompanyPlan';
export function useComposersCount() {
    var _a, _b;
    const account = useAccount();
    const companyId = ((_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id) || '';
    const { currentPlan } = useCurrentCompanyPlan();
    const currentPlanId = (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id) || '';
    const currentMarket = getCurrentMarket();
    const defaultLanguages = currentMarket.templateLocales.map(({ languageCode }) => languageCode);
    const { data, loading, error } = useQuery(ComposersCountDocument, {
        skip: !currentPlanId,
        variables: { companyId, currentPlanId, marketLocaleCodes: defaultLanguages },
    });
    const { all, company, included } = data || {};
    const composersCount = {
        'templates:all': (all === null || all === void 0 ? void 0 : all.count) || 0,
        'templates:company': (company === null || company === void 0 ? void 0 : company.count) || 0,
        'templates:included': (included === null || included === void 0 ? void 0 : included.count) || 0,
    };
    return {
        loading,
        error,
        composersCount,
    };
}
