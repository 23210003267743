import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function TerminationForCauseFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'terminationForCause',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().terminationForCause,
    });
    return <SingleSelectFilter filter={filter}/>;
}
