import { t } from '@lingui/macro';
import { useGetSignRequestAttachments } from 'app/domains/esigning/hooks/useGetSignRequestAttachments';
import { EmptyAttachmentsState } from './EmptyAttachmentsState';
import { FailedDataRetrievalAlert } from './FailedDataRetrievalAlert';
import { PendingAttachmentItem } from './PendingAttachmentItem';
import { useDownloadAttachment } from './useDownloadAttachment';
import { SectionList } from '../../components';
export function PendingAttachments() {
    const { attachments, error } = useGetSignRequestAttachments();
    const { download, loading: busyDownloadingAttachment, attachmentId } = useDownloadAttachment();
    const attachmentsAmount = attachments.length;
    if (error) {
        return <FailedDataRetrievalAlert />;
    }
    if (attachmentsAmount === 0) {
        return <EmptyAttachmentsState />;
    }
    return (<SectionList title={t({
            comment: 'Label for e-signing document attachments',
            message: `All attachments (${attachmentsAmount})`,
        })}>
      {attachments.map((attachment, index) => attachment &&
            attachment.id && (<PendingAttachmentItem key={attachment.id} id={attachment.id} name={attachment.name} index={index} disabled={busyDownloadingAttachment} downloading={busyDownloadingAttachment && attachmentId === attachment.id} onDownload={download}/>))}
    </SectionList>);
}
