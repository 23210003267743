import { createAssistantCache } from './createAssistantCache';
import { readAssistantCache } from './readAssistantCache';
import { updateAssistantCache } from './updateAssistantCache';
export class AssistantCache {
    constructor() {
        /**
         * Checks which subscriptions that listen to the updated keys
         */
        this.getSubscriptionsForUpdate = (data) => {
            const isSubscribedToUpdatedKeys = (subscription) => {
                const updatedKeys = Object.keys(data);
                return (subscription.type === 'update' && subscription.keys.some(key => updatedKeys.includes(key)));
            };
            return this.subscriptions.filter(isSubscribedToUpdatedKeys);
        };
        /**
         * Checks which subscriptions that listen to the emitted event
         */
        this.getSubscriptionsForEvent = (event) => {
            const isSubscribedToEvent = (subscription) => subscription.type === 'event' && subscription.event === event;
            return this.subscriptions.filter(isSubscribedToEvent);
        };
        /**
         * Unsubscribes all added subscriptions
         */
        this.unsubscribe = () => {
            this.subscriptions = [];
        };
        /**
         * Call this function when entering a new document which should have its own `Assistant` cache
         *
         * @param documentId The document's id
         */
        this.setupForDocument = (documentId) => {
            if (this.documentId === documentId) {
                return;
            }
            createAssistantCache(documentId);
            this.unsubscribe();
            this.documentId = documentId;
        };
        /**
         * @param keys The keys you want to listen to updates on
         * @param callback The callback you want to run when one of the corresponding keys to the event is updated
         *
         * Example: When listening to the `['selectionRange', 'selectionText']` keys the callback only runs when one of those keys are updated
         */
        this.onUpdate = (keys, callback) => {
            this.subscriptions.push({ type: 'update', keys, callback });
        };
        /**
         * @param event The event you want to listen to
         * @param callback The callback you want to run when one of the keys is updated
         *
         * Example: When listening to the `assistant:suggestion` event the callback only runs when the `suggestionText` key is updated
         */
        this.onEvent = (event, callback) => {
            this.subscriptions.push({ type: 'event', event, callback });
        };
        /**
         * @param callback The callback that was used for `onKeys` or `onEvent` that you want to unsubscribe from
         */
        this.off = (callback) => {
            this.subscriptions = this.subscriptions.filter(subscription => subscription.callback !== callback);
        };
        /**
         * Update the cached `Assistant` item for the active document
         *
         * When calling `update` all subscribers that listen to the updated keys will be notified
         *
         * Example: When updating the `suggestionText` key, the subscribers of the `suggestionText` key are notified
         */
        this.update = (data) => {
            const subscriptionsToNotify = this.getSubscriptionsForUpdate(data);
            const updatedAssistant = updateAssistantCache(this.documentId, data);
            if (updatedAssistant) {
                subscriptionsToNotify.forEach(subscription => {
                    subscription.callback(updatedAssistant);
                });
            }
        };
        /**
         * Update the cached `Assistant` item for the active document by emitting an event with corresponding data
         *
         * When calling `emit` all subscribers that listen to the event and the updated keys will be notified
         *
         * Example: When emitting the `assistant:suggestion` event, the subscribers of the `assistant:suggestion` event are notified
         */
        this.emit = (event, data) => {
            const subscriptionsToNotify = [
                ...this.getSubscriptionsForEvent(event),
                ...this.getSubscriptionsForUpdate(data),
            ];
            const updatedAssistant = updateAssistantCache(this.documentId, data);
            if (updatedAssistant) {
                subscriptionsToNotify.forEach(subscription => {
                    subscription.callback(updatedAssistant);
                });
            }
        };
        /**
         *
         * @returns The active `AssistantCacheItem`
         */
        this.read = () => {
            const cache = readAssistantCache(this.documentId);
            if (cache) {
                return cache;
            }
            const nonInitializedCache = {
                __typename: 'Assistant',
                id: '',
                selectionRange: null,
                selectionText: '',
                suggestionText: '',
            };
            return nonInitializedCache;
        };
        this.documentId = '';
        this.subscriptions = [];
    }
}
export const assistantCache = new AssistantCache();
