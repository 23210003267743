import { t } from '@lingui/macro';
import { SingleSelectFilter, useSingleSelectFilter } from '../../SingleSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function MaterialAgreementFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'materialAgreement',
        items: [
            { id: 'yes', title: t({ message: 'Yes', comment: 'Search material agreement filter: Yes' }) },
            { id: 'no', title: t({ message: 'No', comment: 'Search material agreement filter: No' }) },
            {
                id: 'set',
                title: t({ message: 'Set', comment: 'Search material agreement filter: Yes or no' }),
            },
            {
                id: 'not-set',
                title: t({ message: 'Not set', comment: 'Search material agreement filter: Not set' }),
            },
        ],
        name: searchFilterCopy().materialAgreement,
    });
    return <SingleSelectFilter filter={filter}/>;
}
