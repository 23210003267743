import gql from 'graphql-tag';
// Note: the limit filter aims to fit as many item as possible without the need of scrolling
export const searchQuery = gql `
  query advancedSearch($query: String!, $referrer: String, $where: SearchArgsWhere) {
    search(
      query: $query
      limit: 10
      referrer: $referrer
      where: $where
      modifiers: { isFuzzy: true, searchType: all }
    ) {
      results {
        id
        entityId
        type: entityType
        name
        createdAt
        categoryId
        meta {
          highlight
        }
        ... on SearchResultTemplate {
          metadataTemplateTitle {
            marketLocaleCode
            text
          }
        }
      }
    }
  }
`;
