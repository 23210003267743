var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { ConfirmDialog, useToast } from '@pocketlaw/tetris';
import { DeleteDraftDocument } from './mutation.gql';
export function DeleteDraftDialog(props) {
    const { onClose, result } = props;
    const { id, entityId, __typename } = result;
    const { addToast } = useToast();
    const [deleteDraft, { loading }] = useMutation(DeleteDraftDocument, {
        update(cache) {
            cache.modify({
                id: cache.identify({ id, __typename }),
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
        },
    });
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield deleteDraft({
                variables: { id: entityId },
            });
            onClose();
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Draft deleted',
                    comment: 'Delete draft success toast',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Error occurred while deleting draft',
                    comment: 'Delete draft error toast',
                }),
            });
        }
    });
    return (<ConfirmDialog heading={<Trans comment="Title for delete draft dialog (from draft view and files)">
          Delete draft
        </Trans>} appearance="danger" size="sm" loading={loading} confirm={<Trans comment="Delete draft button label">Delete</Trans>} cancel={<Trans comment="Cancel button label">Cancel</Trans>} onConfirm={handleConfirm} onCancel={onClose}>
      <Trans comment="Warning message for delete draft dialog">
        Are you sure you want to delete this draft? This action is irreversible.
      </Trans>
    </ConfirmDialog>);
}
