export function buildSingleSelectFilter(id) {
    if (!id) {
        return undefined;
    }
    return { eq: id };
}
export function buildBooleanFilter(value) {
    if (value === undefined) {
        return undefined;
    }
    return { eq: value };
}
