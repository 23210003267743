import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogContent, DialogHeader, DialogFooter, Button, Tabs, TabNav, Tab, TabContent, AvatarWithLabel, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FileBrowser } from 'app/domains/filesystem';
import { ContractTemplateList } from 'app/domains/templates';
import { EntryKind } from 'shared/domains/apollo/generated/types';
function AttachmentBrowser(props) {
    const { onAdd, onCancel } = props;
    const [selected, setSelected] = useState();
    const title = t({
        message: 'Attach to',
        comment: 'Task link attachment dialog title',
    });
    const handleSelectDocument = (entry) => {
        setSelected({ type: 'document', id: entry.id });
    };
    const handleSelectComposer = (id) => {
        setSelected({ type: 'template', id });
    };
    const handleOnAdd = () => {
        if (!selected) {
            return;
        }
        onAdd(selected);
    };
    return (<Dialog onClose={onCancel}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
            <Falcon icon="file-upload"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Tabs>
          <TabNav>
            <Tab id="documents">
              <Trans>Documents</Trans>
            </Tab>
            <Tab id="templates">
              <Trans>Templates</Trans>
            </Tab>
          </TabNav>
          <TabContent tabId="documents">
            <FileBrowser onSelect={handleSelectDocument} selectable={entry => entry.kind === EntryKind.Document}/>
          </TabContent>
          <TabContent tabId="templates">
            <ContractTemplateList onSelect={({ templateId }) => handleSelectComposer(templateId)}/>
          </TabContent>
        </Tabs>
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button appearance="primary" onClick={handleOnAdd} disabled={!selected}>
          <Trans>Add</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
export { AttachmentBrowser };
