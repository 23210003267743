import { ListItem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledListItem = styled(ListItem) `
  justify-content: space-between;
`;
export const EmptyContainer = styled.div `
  padding: ${themeGet('spacing.5xl')};
  padding-top: ${themeGet('spacing.md')};
`;
export const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${themeGet('spacing.xl')};
`;
