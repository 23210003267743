import { Fragment } from 'react';
import { Box } from '@pocketlaw/tetris';
import { useSearchContext, AddFilterButton, } from 'app/domains/global-search';
import { ActionContainer, FilterContainer } from './styled';
export function SearchFiltersToolbar(props) {
    const { filterItems, actions, filters } = props;
    const { getActiveFilters, getParamValue } = useSearchContext();
    const filtersInDraft = getParamValue('filter_draft');
    const filtersToRender = [...getActiveFilters(), ...filtersInDraft];
    const activeFilters = filtersToRender
        .map(name => filterItems.find(item => item.name === name))
        .filter((item) => Boolean(item === null || item === void 0 ? void 0 : item.name));
    const filterKeys = filterItems
        .filter(({ inDropdown }) => inDropdown !== false)
        .map(item => item.name);
    return (<Box flexDirection="column" gap="md">
      <ActionContainer>
        <AddFilterButton filterKeys={filterKeys}/>
        {actions}
      </ActionContainer>

      <FilterContainer>
        {filters}
        {activeFilters.map(({ name, component }) => (<Fragment key={name}>{component}</Fragment>))}
      </FilterContainer>
    </Box>);
}
