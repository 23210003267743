import { t } from '@lingui/macro';
import { ErrorAlert } from './ErrorAlert';
import { Loader } from './Loader';
import { PDFDataProvider } from './PDFDataProvider';
import { Preview } from './Preview';
import { usePDF } from './usePDF';
export function VirtualizedPDFPreview() {
    const { pdf, loading, error } = usePDF();
    if (error) {
        return <ErrorAlert />;
    }
    if (loading) {
        return (<Loader message={t({ message: 'Loading PDF...', comment: 'Message when loading a PDF' })}/>);
    }
    return (<PDFDataProvider pdf={pdf}>
      <Preview />
    </PDFDataProvider>);
}
