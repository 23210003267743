import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { getErrorMessage } from './utils';
import { useAttachments } from '../AttachmentsProvider';
import { AlertContainer } from './styled';
export function AttachmentsAlerts() {
    const { exceedsMaxNumberOfAttachments, fileSizeTooBigWarning, maxNumberOfAttachments } = useAttachments();
    const [, { error }] = useField('attachments');
    const errorMessage = getErrorMessage(error);
    const exceedsMaxNumberOfAttachmentsMessage = t({
        message: `You cannot upload more than ${maxNumberOfAttachments} attachments`,
        comment: 'This is an info message that will be visible when a user has uploaded the maximum number of allowed attachments',
    });
    const fileSizeTooBigMessage = t({
        comment: 'Error message when the size limit has been exceeded when uploading an attachment',
        message: `The file you selected is too large (max 10 mb).`,
    });
    return (<AlertContainer>
      {fileSizeTooBigWarning && (<Alert appearance="warning" icon={<Falcon icon="exclamation-triangle"/>} message={fileSizeTooBigMessage}/>)}
      {exceedsMaxNumberOfAttachments && (<Alert appearance="info" icon={<Falcon icon="info-circle"/>} message={exceedsMaxNumberOfAttachmentsMessage}/>)}
      {errorMessage && (<Alert appearance="danger" icon={<Falcon icon="exclamation-triangle"/>} message={errorMessage}/>)}
    </AlertContainer>);
}
