import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { ListSubHeader, Radio, SearchInput, useGeneratedId, MenuHeader, MenuList, MenuItem, MenuBody, } from '@pocketlaw/tetris';
import { useFuse } from 'app/domains/search';
import { RightAlign } from './styled';
export function SingleSelectList(props) {
    const { onSelect, items, selectedItem } = props;
    const name = useGeneratedId('search-single-select');
    const { result, search } = useFuse(items, {
        keys: ['title'],
    });
    const itemsToDisplay = (result === null || result === void 0 ? void 0 : result.length) ? result : items;
    const handleSearch = (event) => {
        search(event.target.value);
    };
    return (<Fragment>
      <MenuHeader>
        <SearchInput variant="line" stretch placeholder={t({
            message: 'Search',
            comment: 'Single select search input placeholder',
        })} autoFocus onChange={handleSearch}/>
      </MenuHeader>

      <MenuBody>
        <MenuList $density="small">
          {itemsToDisplay.map(item => {
            const isChecked = item.id === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id);
            if (!item.id) {
                return <ListSubHeader key={item.title}>{item.title}</ListSubHeader>;
            }
            return (<MenuItem key={item.id} as="label" $selected={isChecked}>
                {item.title}
                <RightAlign>
                  <Radio checked={isChecked} onChange={() => onSelect(item)} name={name}/>
                </RightAlign>
              </MenuItem>);
        })}
        </MenuList>
      </MenuBody>
    </Fragment>);
}
