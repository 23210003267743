export function buildCategoryFilter(currentItems) {
    const validItems = currentItems.filter(item => Boolean(item));
    if (validItems.length === 0) {
        return undefined;
    }
    if (validItems.includes('uncategorized')) {
        return { isNull: true };
    }
    return { in: validItems };
}
