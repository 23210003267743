import { AttributeOperation } from 'pleditor';
export function hasIncomingGraveyardNode(operation) {
    var _a;
    const isSplit = operation.type === 'split';
    const hasGraveyardPosition = Boolean(operation.graveyardPosition);
    const hasExtraGraveyardData = Boolean((_a = operation.extra) === null || _a === void 0 ? void 0 : _a.gy);
    return isSplit && hasGraveyardPosition && hasExtraGraveyardData;
}
export function isAttributeOperation(operation) {
    return (operation instanceof AttributeOperation &&
        ['removeAttribute', 'changeAttribute'].includes(operation.type) &&
        operation.key === 'listItemId');
}
/**
 * Creates a new batch with operations from JSON data.
 * @param editor
 * @param operations
 * @returns
 */
export function createComplementaryOperations(editor, operations) {
    if (!Array.isArray(operations) || !operations.length) {
        return undefined;
    }
    const batch = editor.model.createBatch({ isLocal: false, isUndoable: false });
    for (const jsonOperation of operations) {
        const operation = editor.model.createOperationFromJSON(jsonOperation);
        Object.assign(operation, {
            isComplementary: true,
        });
        if (jsonOperation.extra) {
            Object.assign(operation, { extra: jsonOperation.extra });
        }
        batch.addOperation(operation);
    }
    return batch.operations;
}
