import { useQuery } from '@apollo/client';
import { HIDDEN_CATEGORIES } from 'app/domains/categories/utils/hiddenCategories';
import { getSearchCategoriesQuery } from './query';
import { MultiSelectFilter, useMultiselectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function TemplateCategoryFilter() {
    var _a, _b;
    const { data, loading } = useQuery(getSearchCategoriesQuery, { variables: { hiddenCategoryIds: HIDDEN_CATEGORIES } });
    const transformedCategories = (_b = (_a = data === null || data === void 0 ? void 0 : data.categories) === null || _a === void 0 ? void 0 : _a.map(item => {
        var _a, _b;
        return ({
            id: item.id,
            title: (_b = (_a = item.metadata[0]) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
        });
    })) !== null && _b !== void 0 ? _b : [];
    const filter = useMultiselectFilter({
        items: transformedCategories,
        paramKey: 'templateCategory',
        name: searchFilterCopy().templateCategory,
        loading,
    });
    return <MultiSelectFilter filter={filter}/>;
}
