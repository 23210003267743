var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans, Plural, t } from '@lingui/macro';
import { Button, Dialog, DialogHeader, DialogFooter, Column, Grid, LabelHeading, useToast, Chip, Typography, AvatarWithLabel, } from '@pocketlaw/tetris';
import { Text, Spacer } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useCompanyFeatures } from 'app/domains/companies';
import { MarketFlag } from 'app/domains/markets/components/MarketFlag';
import { getMarketByCode } from 'app/domains/markets/config';
import { useCreateDraft } from 'app/domains/templates';
import { useMobile } from 'shared/domains/common-ui';
import { useGtm } from 'shared/domains/tracking';
import { DescriptionColumn } from './DescriptionColumn';
import { NoAccessDialog } from './NoAccessDialog';
import { Preview } from './Preview';
import SubscriptionAlert from './SubscriptionAlert';
import { TemplateApprovers } from './TemplateApprovers';
import { TemplateLanguage } from './TemplateLanguage';
import { useCreateDraftDialog } from './useCreateDraftDialog';
import { StyledDialogContent } from './styled';
export function CreateContractDraftDialog(props) {
    var _a, _b;
    const { onDraft, onClose, templateId, composerId } = props;
    const { isFeatureAvailable } = useCompanyFeatures();
    const { push: gtmPush } = useGtm();
    const { addToast } = useToast();
    const [selectedTemplateId, setSelectedTemplateId] = useState(templateId);
    const createDraft = useCreateDraft();
    const query = useCreateDraftDialog(composerId);
    const useApprovalWorkflow = isFeatureAvailable('approval');
    const isMobile = useMobile();
    const columnWidth = isMobile ? '50%' : '25%';
    const [graphQLErrors] = ((_a = query.error) === null || _a === void 0 ? void 0 : _a.graphQLErrors) || [];
    const { code: errorCode } = (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.extensions) || {};
    const { plans, composer, templates, currentPlan, locked, marketCode, previewFiles, composerPlans, category, estimatedTime, } = query.data || {};
    const PlansObjectArray = (composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) || [];
    const FilteredPlansObjectArray = PlansObjectArray === null || PlansObjectArray === void 0 ? void 0 : PlansObjectArray.filter(Boolean);
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);
    const hasPreviewFiles = ((_b = previewFiles === null || previewFiles === void 0 ? void 0 : previewFiles.files) === null || _b === void 0 ? void 0 : _b.length) > 0;
    const market = getMarketByCode(marketCode);
    const handleSelectTemplate = (tid) => {
        const params = new URLSearchParams(window.location.search);
        params.set('id', tid);
        const search = params.toString();
        const url = `${window.location.pathname}?${search}`;
        window.history.replaceState({}, '', url);
        setSelectedTemplateId(tid);
    };
    const handleCreateDraft = () => __awaiter(this, void 0, void 0, function* () {
        var _c, _d;
        const version = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.latestVersion;
        try {
            const { data } = yield createDraft.mutate({
                composerId,
                templateId: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id,
                version: (_c = composer === null || composer === void 0 ? void 0 : composer.tree) === null || _c === void 0 ? void 0 : _c.version,
                templateVersionTemplateId: version === null || version === void 0 ? void 0 : version.templateId,
                templateVersionReference: version === null || version === void 0 ? void 0 : version.reference,
            });
            if (onDraft) {
                gtmPush({
                    event: 'template_start',
                    form: templateId,
                });
                const { id } = ((_d = data === null || data === void 0 ? void 0 : data.created) === null || _d === void 0 ? void 0 : _d.returning[0]) || {};
                const newId = id || '';
                onDraft({ id: newId });
            }
        }
        catch (_e) {
            addToast({
                appearance: 'warning',
                title: t({
                    message: 'An error occurred',
                    comment: 'Error message displayed in composer/template dialog when creating a draft',
                }),
            });
        }
    });
    if (query.loading) {
        return null;
    }
    if (errorCode === 'FORBIDDEN') {
        return <NoAccessDialog onClose={onClose}/>;
    }
    return (<Dialog size="md" onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" appearance={locked ? 'secondary' : 'primary'} title={composer.name}>
            <Falcon icon="file-alt"/>
          </AvatarWithLabel>}/>

      <StyledDialogContent $noPaddingBottom={hasPreviewFiles}>
        <Grid>
          {locked && (<Column>
              <SubscriptionAlert plans={plans} currentPlan={currentPlan} composerPlans={FilteredPlansObjectArray}/>
            </Column>)}

          <Column>
            <TemplateLanguage templates={templates} selectedTemplateId={selectedTemplateId} onSelect={handleSelectTemplate}/>
          </Column>

          <Column>
            <DescriptionColumn>{composer.description}</DescriptionColumn>
          </Column>

          <Column width={columnWidth}>
            <LabelHeading>
              <Trans comment="Heading displaying the jurisdiction for a composer when creating a draft">
                Jurisdiction
              </Trans>
            </LabelHeading>
            <Spacer>
              <MarketFlag code={market.code}/>
              <Text>{market.getName()}</Text>
            </Spacer>
          </Column>

          <Column width={columnWidth}>
            <LabelHeading>
              <Trans comment="Heading displaying the topic/category for a composer when creating a draft">
                Topic
              </Trans>
            </LabelHeading>
            <Chip size="small" appearance="primary" variant="outline" label={category.title}/>
          </Column>

          {estimatedTime && (<Column width={columnWidth}>
              <LabelHeading>
                <Trans comment="Heading displaying the estimated time to complete a composer when creating a draft">
                  Estimated time
                </Trans>
              </LabelHeading>
              <Typography $appearance="200" $fontSize="small">
                <Plural value={estimatedTime} one="# minute" other="# minutes"/>
              </Typography>
            </Column>)}

          {useApprovalWorkflow && (<Column width={columnWidth}>
              <TemplateApprovers composerId={composerId}/>
            </Column>)}

          {hasPreviewFiles && (<Column width="100%">
              <Preview previewFiles={previewFiles}/>
            </Column>)}
        </Grid>
      </StyledDialogContent>

      <DialogFooter>
        {!(selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) && (<Text appearance="light">
            <Trans comment="Validation message when creating contract to select a template language">
              Select template language to continue
            </Trans>
          </Text>)}

        <Button appearance="secondary" onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
        <Button appearance="primary" onClick={handleCreateDraft} disabled={locked || !(selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) || createDraft.state.loading || query.loading}>
          <Trans>Create</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
