import { t } from '@lingui/macro';
import { IconButton, Loader, useTetrisTheme } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SectionListItem } from '../../../components';
export function PendingAttachmentItem(props) {
    const { id, name, index, disabled, downloading, onDownload } = props;
    const { themeGet } = useTetrisTheme();
    const handleClick = () => onDownload(id);
    const fallbackName = t({
        comment: 'fallback for sign request attachment without name',
        message: 'Attachment',
    });
    const itemName = name || `${fallbackName} ${index}`;
    return (<SectionListItem icon={<Falcon icon="file-pdf"/>} primary={itemName} endElement={downloading ? (<Loader size={8} primaryColor={themeGet('colors.indigo.500')}/>) : (<IconButton $round size="small" appearance="ghost" disabled={disabled} onClick={handleClick}>
            <Falcon icon="down-to-line"/>
          </IconButton>)}/>);
}
