import { t } from '@lingui/macro';
import { ButtonView, Plugin } from 'pleditor';
import { v4 as uuid } from 'uuid';
import { CommentPlus } from './icons';
export class InlineComment extends Plugin {
    static get pluginName() {
        return 'InlineComment';
    }
    init() {
        const { editor } = this;
        const tooltip = t({
            comment: 'Pleditor - Inline comment tooltip',
            message: 'Add inline comment',
        });
        const label = t({ comment: 'Add inline comment button text', message: 'Add' });
        const icon = CommentPlus;
        const className = 'pleditor-toolbar-fa-icon-button';
        const createButton = (withText) => {
            const button = new ButtonView();
            button.set({
                label,
                tooltip,
                icon,
                withText,
                class: className,
            });
            button.on('execute', () => {
                const threadId = uuid();
                this.selectBlock();
                editor.execute('addCommentThread', { threadId });
            });
            return button;
        };
        editor.ui.componentFactory.add('inlineComment', () => createButton(true));
        editor.ui.componentFactory.add('inlineCommentCompact', () => createButton(false));
    }
    // If no characters are selected, select the whole block the cursor is currently in.
    selectBlock() {
        const { selection } = this.editor.model.document;
        const [range] = Array.from(selection.getRanges());
        const [block] = Array.from(selection.getSelectedBlocks());
        if (range.isCollapsed) {
            // Select the whole block in the editor
            this.editor.model.change(writer => {
                writer.setSelection(block, 'on');
            });
        }
    }
}
