import { gql } from '@apollo/client';
export const createDraftDialogQuery = gql `
  query createDraftDialog($companyId: UUID!, $composerId: Unstable__UUID!, $marketCode: String!) {
    company(id: $companyId) {
      id
      plans {
        id
        plan {
          id
          name
        }
      }
    }

    plans(where: { market_code: { _eq: $marketCode } }) {
      id
      type
      name
      ordinal
    }

    composer: unstable__composer(id: $composerId) {
      id
      metadata {
        name
        description
        marketLocaleCode
      }

      marketCode
      estimatedTime

      tree {
        id
        version
      }

      category {
        id
        themeId: theme_id
        metadata {
          title
          marketLocaleCode: market_locale_code
        }
      }

      templates {
        id
        marketLocale {
          locale
          code
        }

        latestVersion {
          templateId
          reference
        }

        previewFiles
      }

      composerPlans {
        id
        plan {
          id
          name
          ordinal
          type
        }
      }
    }
  }
`;
