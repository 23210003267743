import { t } from '@lingui/macro';
import { View } from 'pleditor';
export const buildEmptyView = (hidden) => {
    const emptyView = new View();
    emptyView.setTemplate({
        tag: 'p',
        children: [
            t({
                message: 'There are no archived comment threads.',
                comment: 'Info text to show no archived comment threads',
            }),
        ],
        attributes: {
            class: ['ck-comments-archive__info', hidden ? 'ck-hidden' : ''],
        },
    });
    return emptyView;
};
export const buildTitleView = () => {
    const titleView = new View();
    titleView.setTemplate({
        tag: 'p',
        children: [t({ message: 'Comments Archive', comment: 'Comment archive dropdown title text' })],
        attributes: { class: ['ck-comments-archive__header'] },
    });
    return titleView;
};
