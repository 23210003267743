import client from 'app/domains/apollo';
import { getAssistantQuery } from './query';
import { readAssistantCache } from './readAssistantCache';
export const createAssistantCache = (documentId) => {
    const cache = readAssistantCache(documentId);
    if (cache) {
        return;
    }
    const assistant = {
        __typename: 'Assistant',
        id: documentId,
        selectionRange: null,
        selectionText: '',
        suggestionText: '',
    };
    client.writeQuery({
        query: getAssistantQuery,
        variables: { documentId },
        data: { assistant },
    });
};
