import { hasIncomingGraveyardNode, isAttributeOperation, createComplementaryOperations, } from './utils';
export function applyCollaboratorOperations(editor, jsonOperations) {
    const operations = createComplementaryOperations(editor, jsonOperations);
    if (!operations) {
        return;
    }
    /**
     * Complementary operations might contain extra data about nodes that should be inserted
     * in to the local editors graveyard. This extra data is needed because if some other user
     * moves something from their local graveyard back in to main root. We need to know about
     * those nodes.
     * The graveyard will be populated before we perform any other tasks.
     */
    editor.model.change(writer => {
        var _a, _b;
        for (const operation of operations) {
            if (hasIncomingGraveyardNode(operation)) {
                const { graveyard } = writer.model.document;
                const { name, attributes } = ((_a = operation.extra) === null || _a === void 0 ? void 0 : _a.gy) || {};
                if (!name) {
                    return;
                }
                const graveyardChild = writer.createElement(name, attributes);
                // eslint-disable-next-line no-underscore-dangle
                graveyard._appendChild(graveyardChild);
                if (!operation.extra) {
                    operation.extra = {};
                }
                operation.extra.gyElement = graveyardChild;
            }
            /**
             * As BE and FE create their own instance of Pleditor. it causes mismatch between FE and BE attributes which are auto generated e.g listItemId.
             * we receive these mismatched attributes in operation which causes applyOperation to fail.
             *
             * This if statement finds the operation that manipulates the listItemId attribute and handles the mismatch
             *
             * On a separate occasion, we need to instruct the local editor that a certain paragraph (in Pleditor, the list
             * items themselves are paragraphs with special attributes) is actually under the sane list number as the previous
             * sibling
             */
            if (isAttributeOperation(operation)) {
                const { range } = operation;
                // Get the element where the operation will be applied
                const containedElement = range.getContainedElement();
                if (containedElement) {
                    // Assigning the value of current listItemId attribute where the operation will be applied to the old value of operations.
                    // Here we are making sure that the batch operation matches with the FE editor data so that applyOperation does not fail
                    // eslint-disable-next-line no-param-reassign
                    operation.oldValue = containedElement.getAttribute('listItemId');
                    if (operation.type === 'changeAttribute' &&
                        ((_b = operation.extra) === null || _b === void 0 ? void 0 : _b.copyIdFromPreviousSibling)) {
                        const { previousSibling } = containedElement;
                        if (previousSibling && previousSibling.getAttribute('listItemId')) {
                            // eslint-disable-next-line no-param-reassign
                            operation.newValue = previousSibling.getAttribute('listItemId');
                        }
                    }
                }
            }
        }
        operations.forEach(operation => {
            var _a;
            const graveyardChild = (_a = operation.extra) === null || _a === void 0 ? void 0 : _a.gyElement;
            if (graveyardChild) {
                const [path] = graveyardChild.getPath();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                operation.graveyardPosition.path[0] = path;
            }
            /**
             * Apply all operations that has been created, if any.
             * We will get the path to the potential graveyard child, and update
             * the graveyardPosition path on the operation to keep it in sync while
             * applying operations.
             */
            writer.model.applyOperation(operation);
        });
    });
}
