import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function ActiveFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'active',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().active,
    });
    return <SingleSelectFilter filter={filter}/>;
}
