import { t } from '@lingui/macro';
import { MenuFooter } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { SubmitButton } from 'shared/domains/forms';
export function FormSubmitButton() {
    const [field] = useField('id');
    const saveButtonText = t({
        message: 'Create',
        comment: 'Document metadata: Save button',
        id: 'metadata.field.action.create',
    });
    const editButtonText = t({
        message: 'Update',
        comment: 'Document metadata: Update button',
        id: 'metadata.field.action.update',
    });
    return (<MenuFooter sticky>
      <SubmitButton $stretch size="small">
        {field.value ? editButtonText : saveButtonText}
      </SubmitButton>
    </MenuFooter>);
}
