import { Form } from 'shared/domains/forms';
import { FormField } from './FormField';
import { useMetadataField } from '../useMetadataField';
import { validateValue } from '../utils';
import { validationSchema } from '../validationSchema';
export function MetadataForm(props) {
    const { onSubmit } = props;
    const { pair, property } = useMetadataField();
    const validatedValue = validateValue(pair.value, property.type);
    return (<Form enableReinitialize initialValues={{
            id: pair.id,
            value: validatedValue,
        }} onSubmit={onSubmit} validationSchema={validationSchema}>
      <FormField />
    </Form>);
}
