import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Menu, MenuList, MenuItem, MenuDivider, IconButton, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAttachments } from '../../AttachmentsProvider';
export function Dropdown(props) {
    const { origin, onUpload, onSelect } = props;
    const { disabled, uploading, loading } = useAttachments();
    const [open, setOpen] = useState(false);
    const handleShowMenu = () => setOpen(true);
    const handleHideMenu = () => setOpen(false);
    const handleSelect = () => {
        handleHideMenu();
        onSelect();
    };
    const handleUpload = () => {
        handleHideMenu();
        onUpload();
    };
    const buttonProps = {
        disabled: disabled || loading || uploading,
        onClick: handleShowMenu,
    };
    const emptyStateButton = (<Button appearance="secondary" size="small" {...buttonProps}>
      <Falcon icon="plus"/>
      <Trans comment="No attachments added empty state - call to action label">
        Add attachment
      </Trans>
    </Button>);
    const listActionButton = (<IconButton $round size="small" appearance="ghost" {...buttonProps}>
      <Falcon icon="plus"/>
    </IconButton>);
    const target = origin === 'emptyState' ? emptyStateButton : listActionButton;
    return (<Menu open={open} onClose={handleHideMenu} target={target}>
      <MenuBody>
        <MenuList>
          <MenuItem onClick={handleSelect}>
            <Falcon icon="arrow-pointer"/>
            <Trans comment="Option for e-signing attachment">
              Attach from your PocketLaw files
            </Trans>
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleUpload}>
            <Falcon icon="up-from-bracket"/>
            <Trans comment="Option for e-signing attachment">Upload attachment</Trans>
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
