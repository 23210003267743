var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EditorCommentFragment } from './fragments';
import { createCommentThreadMutation } from './mutation';
export function addCommentThread(apiClient, newThread) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { anchorId, body, documentId, id } = newThread;
        const response = yield apiClient.mutate({
            mutation: createCommentThreadMutation,
            variables: {
                anchorId,
                body,
                documentId,
                id,
            },
            update(cache, { data }) {
                cache.modify({
                    fields: {
                        fileSystemDocumentComments(existingCommentThreads = []) {
                            const newCommentThreadRef = cache.writeFragment({
                                data: data === null || data === void 0 ? void 0 : data.createComment,
                                fragment: EditorCommentFragment,
                                fragmentName: 'EditorCommentFragment',
                            });
                            return [...existingCommentThreads, newCommentThreadRef];
                        },
                    },
                });
            },
        });
        const { data } = response || {};
        const { createComment } = data || {};
        const comments = [
            {
                commentId: (createComment === null || createComment === void 0 ? void 0 : createComment.id) || '',
                content: (createComment === null || createComment === void 0 ? void 0 : createComment.body) || '',
                authorId: ((_a = createComment === null || createComment === void 0 ? void 0 : createComment.user) === null || _a === void 0 ? void 0 : _a.id) || '',
                createdAt: new Date((createComment === null || createComment === void 0 ? void 0 : createComment.createdAt) || ''),
                isFromAdapter: true,
            },
        ];
        return {
            threadId: (createComment === null || createComment === void 0 ? void 0 : createComment.id) || '',
            comments,
            isFromAdapter: true,
        };
    });
}
