var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { z } from 'zod';
import { assistantCache, AssistantContext, AssistantViewType, } from 'app/domains/assistant';
import { ChatMessageRole, ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { useActiveDocument, useDocumentDrawer } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import { Context } from './Context';
import { UpdateChatMessageContentFragmentDoc } from './UpdateChatMessageContent.gql';
import { useAssistantSuggestions } from './useAssistantSuggestions';
import { useCacheOperations } from './useCacheOperations';
import { useChatWithDocument } from './useChatWithDocument';
import { useCreateDocumentChat } from './useCreateDocumentChat';
import { useDeleteDocumentChat } from './useDeleteDocumentChat';
import { useGetAvailableAssistantCredits } from './useGetAvailableAssistantCredits';
import { getLastChatQuery, useGetLastDocumentChat } from './useGetLastDocumentChat';
import { useCancelDocumentChatMessage } from '../../hooks/useCancelDocumentChatMessage';
const zServerSentMessage = z.tuple([
    z.string(),
    z.tuple([z.string(), z.string()]),
]);
export function Provider(props) {
    const { cache } = useApolloClient();
    const { children } = props;
    const { id: documentId } = useActiveDocument();
    const { collapse, expanded, openDrawer, closeDrawer, isDrawerOpen } = useDocumentDrawer();
    const { addToast } = useToast();
    const { availableCredits, loading: loadingCredits } = useGetAvailableAssistantCredits();
    const { thread, chatId, loading: loadingThread, polling: pollingThread, resolved, resolutionStatus, } = useGetLastDocumentChat();
    const { addTemporaryMessages, clearTemporaryItems } = useCacheOperations();
    const [assistantContext, setAssistantContext] = useState(AssistantContext.Document);
    const [view, setView] = useState(AssistantViewType.Dashboard);
    const updateView = (newView) => {
        if (newView === AssistantViewType.Dashboard && expanded) {
            collapse();
        }
        setView(newView);
    };
    const [selectedText, setSelectedText] = useState('');
    const [createNewChat, { loading: creatingNewChat }] = useCreateDocumentChat();
    const [deleteChat, { loading: deletingChat }] = useDeleteDocumentChat();
    const [promptAssistant, { loading: promptingAssistant }] = useChatWithDocument();
    const isAssistantDrawerOpen = isDrawerOpen('app:assistant');
    const [firstRender, setFirstRender] = useState(true);
    const [getLastChat] = useLazyQuery(getLastChatQuery);
    const { suggestions, clearSuggestions, fetchingSuggestions, requestSuggestions } = useAssistantSuggestions();
    const clearThread = () => {
        deleteChat(chatId)
            .then(() => updateView(AssistantViewType.Dashboard))
            .catch(error => {
            Sentry.captureException(error);
            addToast({
                title: t({
                    message: 'We could not delete the conversation. Try again',
                    comment: 'Document assistant - error when trying to delete the current chat ',
                }),
                appearance: 'destructive',
            });
        });
    };
    // show the last active conversation when the assistant drawer is opened only on the first render
    useEffect(() => {
        if (firstRender && thread.length > 0) {
            updateView(AssistantViewType.Chat);
            setFirstRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thread, firstRender]);
    const addComment = (input, options) => __awaiter(this, void 0, void 0, function* () {
        const { type, message } = input;
        const { onCompleted } = options || {};
        updateView(AssistantViewType.Chat);
        const handlePrompt = (id) => __awaiter(this, void 0, void 0, function* () {
            addTemporaryMessages({
                message,
                type,
                chatId: id,
            });
            yield promptAssistant({
                data: input.data,
                selection: selectedText,
                type,
                chatId: id,
                message,
            }).then(result => {
                var _a, _b, _c;
                if (onCompleted) {
                    onCompleted();
                }
                if (result === undefined) {
                    return;
                }
                const assistantMessage = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.lastChat.messages[0]) !== null && _b !== void 0 ? _b : null;
                if (assistantMessage === null) {
                    return;
                }
                const token = (_c = assistantMessage.streamToken) !== null && _c !== void 0 ? _c : null;
                if (token === null) {
                    return;
                }
                const CHAT_URL = process.env.ASSISTANT_CHAT_STREAM_URL;
                const evtSource = new EventSource(`${CHAT_URL}/chat/${token}`);
                evtSource.onmessage = event => {
                    const [, [kind, content]] = zServerSentMessage.parse(JSON.parse(event.data));
                    switch (kind) {
                        case 'MSG': {
                            cache.updateFragment({
                                id: cache.identify({
                                    id: assistantMessage.id,
                                    __typename: 'ChatMessage',
                                }),
                                fragment: UpdateChatMessageContentFragmentDoc,
                            }, data => (Object.assign(Object.assign({}, data), { content: data === null || data.content === null ? content : data.content + content })));
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                };
                // The server will close the connection after sending the end token, this is treated as
                // an error state by the EventSource, and we thus close the connection.
                evtSource.onerror = () => {
                    evtSource.close();
                    getLastChat({
                        fetchPolicy: 'network-only',
                        variables: {
                            documentId,
                            messagesLimit: 1,
                            messagesWhere: {
                                id: { in: [assistantMessage.id] },
                            },
                        },
                    });
                };
            });
            clearTemporaryItems({ chatId: id });
        });
        // when type is null, it means that it is a follow-up question from the chat
        // therefore we should not create a new chat, just send the prompt
        if (!type && chatId) {
            return handlePrompt(chatId);
        }
        return createNewChat()
            .then(newChatId => {
            if (!newChatId) {
                Sentry.captureMessage('Document assistant returned no chat id after creating a new document chat');
                return;
            }
            handlePrompt(newChatId);
        })
            .catch(error => {
            Sentry.captureException(error);
            addToast({
                title: t({
                    message: 'We could not initiate a new chat. Try again later.',
                    comment: 'Document assistant - error when creating a new chat ',
                }),
                appearance: 'destructive',
            });
            updateView(AssistantViewType.Dashboard);
        });
    });
    const handleSuggestions = () => {
        updateView(AssistantViewType.Suggestions);
        requestSuggestions();
    };
    const openAssistantDrawer = useCallback((context) => {
        openDrawer('app:assistant');
        setAssistantContext(context);
    }, [openDrawer]);
    const closeAssistantDrawer = () => {
        closeDrawer('app:assistant');
        setSelectedText('');
        assistantCache.update({ selectionRange: null, selectionText: '' });
    };
    const changeContext = (context) => setAssistantContext(context);
    useEffect(() => {
        assistantCache.setupForDocument(documentId);
        const onPleditorTextSelected = (assistant) => {
            const { selectionText } = assistant;
            if (!isAssistantDrawerOpen) {
                openAssistantDrawer(AssistantContext.Selection);
            }
            else {
                setAssistantContext(AssistantContext.Selection);
            }
            updateView(AssistantViewType.Dashboard);
            setSelectedText(selectionText);
        };
        assistantCache.onEvent('pleditor:on-text-selected', onPleditorTextSelected);
        return () => {
            assistantCache.off(onPleditorTextSelected);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId, isAssistantDrawerOpen, openAssistantDrawer]);
    const { cancel, loading: isCancellingStreaming } = useCancelDocumentChatMessage();
    const message = thread.at(-1);
    const isPendingAssistantMessage = (message === null || message === void 0 ? void 0 : message.role) === ChatMessageRole.Assistant && (message === null || message === void 0 ? void 0 : message.status) === ChatMessageStatus.Pending;
    const isStreaming = isPendingAssistantMessage && (message === null || message === void 0 ? void 0 : message.content) !== null && (message === null || message === void 0 ? void 0 : message.content) !== '';
    const cancelStreaming = () => {
        const lastChatMessage = thread.at(-1);
        if (lastChatMessage === undefined) {
            return;
        }
        if (lastChatMessage.role !== ChatMessageRole.Assistant) {
            return;
        }
        cancel(lastChatMessage.id);
    };
    const hasCredits = availableCredits > 0;
    const loading = promptingAssistant || pollingThread || creatingNewChat || deletingChat;
    const selectionContextWithoutSelectedText = !selectedText && assistantContext === AssistantContext.Selection;
    const cannotSubmitUserMessage = loading ||
        !hasCredits ||
        (selectionContextWithoutSelectedText && view !== AssistantViewType.Chat) ||
        isPendingAssistantMessage;
    const value = {
        context: assistantContext,
        selectedText,
        openAssistantDrawer,
        closeAssistantDrawer,
        changeContext,
        thread,
        addComment,
        clearThread,
        loading,
        initiating: loadingThread || loadingCredits,
        creating: creatingNewChat,
        clearing: deletingChat,
        hasCredits,
        resolved,
        resolutionStatus,
        view,
        updateView,
        suggestions,
        requestSuggestions: handleSuggestions,
        fetchingSuggestions,
        clearSuggestions,
        cancelStreaming,
        isStreaming: !promptingAssistant && isStreaming,
        isCancellingStreaming,
        isPendingAssistantMessage,
        cannotSubmitUserMessage,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
