var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { DeleteViewDocument } from './mutation.gql';
export function useDeleteViewMutation() {
    const [mutate, { data, loading, error }] = useMutation(DeleteViewDocument);
    const deleteView = (viewId) => __awaiter(this, void 0, void 0, function* () {
        return mutate({
            variables: {
                id: viewId,
            },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id: viewId, __typename: 'SearchCustomView' }),
                    fields(_, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
    });
    return {
        deleteView,
        data,
        loading,
        error,
    };
}
