var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import * as Sentry from 'shared/domains/sentry';
import { applyCollaboratorOperations } from './applyCollaboratorOperations';
import { getComplementaryData } from './getComplementaryData';
import { getDocumentType } from './getDocumentType';
import { documentType } from '../../documentType';
import { PleditorPlugin } from '../PleditorPlugin';
import { SaveHandler } from '../SaveHandler';
/**
 * Plugin to keep track on and synchronize collaborative sessions.
 * Several users might update the same document in parallel. This plugin will try
 * to synchronize all those updates. And apply the operations needed.
 * The plugin will also collect a list of collaborators currently active on the document.
 */
export class CollaboratorSession extends PleditorPlugin {
    static get pluginName() {
        return 'CollaboratorSession';
    }
    static get requires() {
        return ['ApiConnector', 'Autosave'];
    }
    constructor(editor) {
        super(editor);
        this.set({
            state: undefined,
            collaborators: [],
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.decorate('setOperations');
            this.registerEvents();
        });
    }
    registerEvents() {
        const { editor } = this;
        /**
         * When focusing the editor, sync the session. This will also trigger on focusing the
         * browser tab.
         */
        editor.editing.view.document.on('change:isFocused', (evt, data, isFocused) => {
            if (isFocused) {
                this.synchronize();
            }
        });
    }
    synchronize() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.shouldSynchronize() === false) {
                return;
            }
            this.set('state', 'synchronizing');
            const { lastKnownBackendVersion } = this.editor.plugins.get(SaveHandler);
            const { documentId } = this;
            try {
                yield this.synchronizeDocumentType();
                const { collaborators, complementaryOperations } = yield getComplementaryData(this.apiConnector, documentId, lastKnownBackendVersion);
                this.setOperations(complementaryOperations);
                this.setCollaborators(collaborators);
                this.set('state', 'synchronized');
            }
            catch (error) {
                this.handleErrors(error);
            }
        });
    }
    synchronizeDocumentType() {
        return __awaiter(this, void 0, void 0, function* () {
            const type = yield getDocumentType(this.apiConnector, this.documentId);
            documentType(type);
        });
    }
    shouldSynchronize() {
        const saveHandler = this.editor.plugins.get(SaveHandler);
        const isLoading = this.state === 'synchronizing' || saveHandler.state === 'saving';
        const hasError = this.state === 'error' || saveHandler.state === 'error';
        const shouldSynchronize = !isLoading && !hasError;
        return shouldSynchronize;
    }
    setCollaborators(collaborators) {
        this.set({
            collaborators: collaborators || [],
        });
    }
    setOperations(operations) {
        applyCollaboratorOperations(this.editor, operations);
    }
    handleErrors(reason) {
        const notificationPlugin = this.editor.plugins.get('Notification');
        this.set('state', 'error');
        notificationPlugin.showWarning(t({
            message: 'Error synchronizing the document',
            comment: 'Notification error message when a synchronization error occurs in the editor.',
        }));
        this.editor.enableReadOnlyMode('sync_error');
        Sentry.captureException(reason, { tags: { feature: 'pleditor' } });
    }
    // eslint-disable-next-line class-methods-use-this
    destroy() {
        documentType(undefined);
    }
}
