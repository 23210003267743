import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function EmptyState() {
    return (<Box alignItems="center" justifyContent="center" flexDirection="column" gap="md" py="2xl">
      <Falcon icon="magnifying-glass" $size={16} $color="gray.700"/>
      <Typography $color="gray.500">
        <Trans comment="Searchable menu empty state">No results</Trans>
      </Typography>
    </Box>);
}
