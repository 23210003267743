import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { SectionList } from '../../../components';
import { AttachmentItem } from '../AttachmentItem';
import { AttachmentsAlerts } from '../AttachmentsAlerts';
import { AttachmentsEmptyState } from '../AttachmentsEmptyState';
import { useAttachments } from '../AttachmentsProvider';
import { UploadAttachmentDropdown } from '../UploadAttachmentDropdown';
export function AttachmentsRoot() {
    const { attachments } = useAttachments();
    const amount = attachments.length;
    const empty = amount === 0;
    const title = t({
        comment: 'Label for e-signing attachments',
        message: `All attachments (${amount})`,
    });
    return (<Fragment>
      <AttachmentsAlerts />
      {empty ? (<AttachmentsEmptyState />) : (<SectionList title={title} endElement={<UploadAttachmentDropdown origin="listAction"/>}>
          {attachments.map(attachment => (<AttachmentItem key={attachment.path} {...attachment}/>))}
        </SectionList>)}
    </Fragment>);
}
