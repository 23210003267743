import { useQuery } from '@apollo/client';
import { Context } from './Context';
import { editorDraftQuery } from './query';
import { useUpdateDraft } from '../useUpdateDraft';
export function EditorDraftProvider(props) {
    const { children, draftId, currentRef } = props;
    const { updateDraft, loading: mutationLoading, error: mutationError } = useUpdateDraft();
    const { data, loading: queryLoading, error: queryError, updateQuery, } = useQuery(editorDraftQuery, {
        variables: { id: draftId },
    });
    const loading = queryLoading;
    const isSaving = () => mutationLoading || updateDraft.isPending();
    const error = Boolean(queryError);
    const saveError = Boolean(mutationError);
    const value = { data, currentRef, loading, isSaving, error, saveError, updateQuery, updateDraft };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
