import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { getInviteCategories, } from './query';
export function useGetComposers() {
    const { addToast } = useToast();
    return useQuery(getInviteCategories, {
        onError: () => {
            addToast({
                title: t({
                    message: 'Something went wrong.',
                    comment: 'user invite modal - error message fetching categories',
                }),
                appearance: 'destructive',
            });
        },
    });
}
