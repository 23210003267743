import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { SearchInput, Alert, MenuHeader, MenuBody } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { MetadataFileBrowser } from './FileBrowser';
import { SearchResultEntries } from './SearchResultEntries';
import { SelectedEntriesList } from './SelectedEntriesList';
import { useSearchQuery } from './useSearchQuery';
import { useSelectedEntries } from './useSelectedEntries';
import { FormSubmitButton } from '../FormSubmitButton';
import { StyledErrorMessage } from './styled';
/**
 * A React component that renders a searchable file browser with the ability to select multiple files and folders.
 * The selected entries are displayed in a list below the search input.
 */
export function FilesystemEntriesField() {
    const { setFieldValue, values } = useFormikContext();
    const entries = useSelectedEntries(values.value);
    const { search, result, isSearching, loading } = useSearchQuery();
    const handleSearch = useDebouncedCallback((ev) => {
        search(ev.target.value);
    }, 250);
    const handleSelectEntry = (value) => {
        const added = values.value.includes(value);
        let newValues;
        if (added) {
            newValues = values.value.filter(existingValue => existingValue !== value);
        }
        else {
            newValues = [...values.value, value];
        }
        setFieldValue('value', newValues);
    };
    const placeholder = t({
        message: 'Search',
        comment: 'Metadata: Filesystem entries search input placeholder text.',
    });
    const hasSelectedItems = Boolean(values.value && values.value.length);
    const filteredResult = result === null || result === void 0 ? void 0 : result.filter(entry => !entries.data.map(({ id }) => id).includes(entry.id));
    const hasSearchResult = Boolean(filteredResult && (filteredResult === null || filteredResult === void 0 ? void 0 : filteredResult.length));
    return (<Fragment>
      <MenuHeader sticky>
        <SearchInput stretch variant="line" placeholder={placeholder} onChange={handleSearch}/>
      </MenuHeader>

      <MenuBody>
        {hasSelectedItems && <SelectedEntriesList handleSelect={handleSelectEntry}/>}

        {!isSearching && <MetadataFileBrowser handleSelect={handleSelectEntry}/>}

        {isSearching && hasSearchResult && (<SearchResultEntries entries={filteredResult} handleSelect={handleSelectEntry} loading={loading}/>)}

        {isSearching && !hasSearchResult && !loading && (<Alert appearance="info" message={t({
                message: 'No files or folders found',
                comment: 'Metadata search, no search result',
            })}/>)}
      </MenuBody>

      <StyledErrorMessage name="value"/>

      <FormSubmitButton />
    </Fragment>);
}
