import { gql } from '@apollo/client';
export const removeMutation = gql `
  mutation removeComposerPermissions($composerIds: [Unstable__UUID!]!, $userIds: [String!]!) {
    unstable__deleteComposerPermissions(
      input: [READ]
      where: { composerIds: $composerIds, userIds: $userIds }
    ) {
      id
    }
  }
`;
