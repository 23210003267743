import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { TetrisThemeProvider, StyleReset, ToastProvider, useIsMobile, TooltipProvider, } from '@pocketlaw/tetris';
import { BrowserRouter } from 'react-router-dom';
import apollo from 'app/domains/apollo';
import AuthProvider from 'app/domains/auth/hooks/useAuth';
import { localTheme } from 'app/domains/common-ui';
import { UniversalSearchProvider } from 'app/domains/global-search';
import { getCurrentMarket } from 'app/domains/markets/config';
import { isMobileVar } from 'shared/domains/apollo/utils/cacheVars';
import { ErrorBoundary } from 'shared/domains/errors';
import { GtmProvider } from 'shared/domains/tracking';
import { Routes } from './screens';
export function Root(props) {
    const { auth, language } = props;
    const { getTetrisTheme } = localTheme;
    const marketCode = getCurrentMarket().code;
    const isMobile = useIsMobile();
    useEffect(() => {
        isMobileVar(isMobile);
    }, [isMobile]);
    useEffect(() => {
        if (document.documentElement.lang !== language) {
            document.documentElement.lang = language;
        }
    }, [language]);
    return (<TetrisThemeProvider theme={getTetrisTheme()}>
      <StyleReset />
      <I18nProvider i18n={i18n}>
        <ErrorBoundary>
          <ApolloProvider client={apollo}>
            <BrowserRouter basename={marketCode}>
              <AuthProvider client={auth} locale={language}>
                <TooltipProvider>
                  <ToastProvider>
                    <GtmProvider>
                      <UniversalSearchProvider>
                        <Routes />
                      </UniversalSearchProvider>
                    </GtmProvider>
                  </ToastProvider>
                </TooltipProvider>
              </AuthProvider>
            </BrowserRouter>
          </ApolloProvider>
        </ErrorBoundary>
      </I18nProvider>
    </TetrisThemeProvider>);
}
