var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { query } from './query';
import { GetSuggestionFragment } from '../utils/suggestionFragment';
import { transformSuggestionData } from '../utils/transformSuggestionData';
export function getSuggestion(apiConnector, input) {
    return __awaiter(this, void 0, void 0, function* () {
        const { suggestionId } = input;
        const cachedSuggestion = apiConnector.cache.readFragment({
            id: `FileSystemDocumentSuggestion:${suggestionId}`,
            fragment: GetSuggestionFragment,
        });
        if (cachedSuggestion) {
            return { suggestion: transformSuggestionData(cachedSuggestion), user: cachedSuggestion.user };
        }
        const { data } = yield apiConnector.query({
            query,
            variables: {
                id: suggestionId,
            },
        });
        const { suggestions } = data || {};
        const [newSuggestion] = suggestions || [];
        return { suggestion: transformSuggestionData(newSuggestion), user: newSuggestion.user };
    });
}
