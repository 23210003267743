var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { DialogHeader, DialogFooter, Alert, AvatarWithLabel, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import * as yup from 'yup';
import { TextAreaField, FormDialog, SubmitButton, TextField } from 'shared/domains/forms';
import { captureException } from 'shared/domains/sentry';
import { AddLabelAutoComplete } from './Fields/AddLabelAutoComplete';
import { SwitchField } from './Fields/SwitchField';
import { useUpsertPrompt } from './useUpsertPrompt';
import { usePromptLibrary } from '../PromptLibraryProvider';
import { StyledDialogContent, InputFieldContainer, SwitchFieldContainer } from './styled';
export function EditPromptDialog(props) {
    var _a, _b, _c, _d;
    const schema = yup.object({
        name: yup
            .string()
            .trim()
            .required(t({
            comment: 'Prompt name is required validation message in create/update prompt modal for property `name`',
            message: 'Prompt name is required.',
        }))
            .max(70, t({
            comment: 'Prompt name is too long validation message in create/update prompt modal for property `name`',
            message: 'Prompt name is too long.',
        })),
        content: yup
            .string()
            .trim()
            .required(t({
            comment: 'Validation message in create/update prompt modal for property `description`',
            message: 'Prompt description is required.',
        })),
        isPublic: yup.bool().required(),
        labels: yup.array(yup.string().trim().required()).required(),
    });
    const { onClose, error } = props;
    const { setCurrentPromptID, allLabels, currentPromptID, prompts } = usePromptLibrary();
    const handleClose = () => {
        setCurrentPromptID(undefined);
        onClose();
    };
    const currentPrompt = prompts.find(prompt => prompt.id === currentPromptID);
    const initialValues = {
        name: (_a = currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.name) !== null && _a !== void 0 ? _a : '',
        content: (_b = currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.content) !== null && _b !== void 0 ? _b : '',
        isPublic: (_c = currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.isPublic) !== null && _c !== void 0 ? _c : false,
        labels: (_d = currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.labels) !== null && _d !== void 0 ? _d : [],
    };
    const { upsertPrompt, error: upsertPromptError } = useUpsertPrompt(currentPromptID);
    const handleSubmit = (values_1, _e) => __awaiter(this, [values_1, _e], void 0, function* (values, { setSubmitting }) {
        try {
            yield upsertPrompt({
                input: {
                    name: values.name,
                    content: values.content,
                    isPublic: values.isPublic,
                    labels: values.labels,
                },
            });
            handleClose();
        }
        catch (err) {
            captureException(err);
        }
        finally {
            setSubmitting(false);
        }
    });
    return (<FormDialog onClose={handleClose} size="md" initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      <DialogHeader title={currentPromptID
            ? t({ message: 'Edit prompt', comment: 'Edit prompt dialog title' })
            : t({ message: 'Create prompt', comment: 'Create prompt dialog title' })} action={<IconButton appearance="ghost" $round onClick={handleClose} aria-label={t({ message: 'Close', comment: 'close prompt edit dialog button' })}>
            <Falcon icon="xmark"/>
          </IconButton>}/>

      <StyledDialogContent>
        {(error || upsertPromptError) && (<Alert appearance="danger" pale message={t({
                message: 'An error occurred.',
                comment: 'Create prompt error alert message',
            })}/>)}
        <InputFieldContainer>
          <TextField autoComplete="off" name="name" label={t({ message: 'Name', comment: 'Prompt name field label' })}/>
          <TextAreaField autosizeHeight maxHeight={250} name="content" label={t({ message: 'Content', comment: 'Prompt content field label' })}/>
        </InputFieldContainer>

        <SwitchFieldContainer>
          <AvatarWithLabel size="sm" appearance="light" title={t({
            message: 'Visible to everyone in your company',
            comment: 'Prompt  public field text to toggle public prompt',
        })}>
            <Falcon icon="eye"/>
          </AvatarWithLabel>
          <SwitchField name="isPublic"/>
        </SwitchFieldContainer>

        <AddLabelAutoComplete suggestions={allLabels}/>
      </StyledDialogContent>
      <DialogFooter>
        <SubmitButton>
          <Trans comment="Upsert prompt save button">Save</Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
