import { useQuery } from '@apollo/client';
import { QUERY } from './query';
export function useAttachementsInForm(documentId) {
    var _a;
    const { data, loading } = useQuery(QUERY, {
        variables: {
            documentId,
        },
    });
    const { attachments = [] } = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.lastSignRequest) || {};
    return { formAttachments: attachments, loading };
}
