import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { DocumentActionMenu } from './DocumentActionMenu';
import { DraftActionMenu } from './DraftActionMenu';
export function SearchResultActionMenu(props) {
    const { result } = props;
    const { type } = result;
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return <DocumentActionMenu result={result}/>;
        case SearchEntityTypeEnum.Draft:
            return <DraftActionMenu result={result}/>;
        default:
            return null;
    }
}
