import { Button, pxToRem, Table, themeGet, Typography } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledTable = styled(Table) `
  margin-top: ${pxToRem(7)};
`;
export const StyledTypography = styled(Typography) `
  ${props => (props === null || props === void 0 ? void 0 : props.$uppercase) && `text-transform: uppercase;`}
  white-space: nowrap;
`;
export const SelectedText = styled(Button).attrs(() => ({
    as: 'span',
    appearance: 'secondary',
    size: 'small',
})) `
  cursor: regular;

  ${props => props.disabled &&
    `
    color: ${themeGet('colors.text.200')};
    background-color: ${themeGet('colors.gray.0')};
    border-color: ${themeGet('colors.gray.100')};
    opacity: 0.5;
  `}

  &:not(:disabled) {
    &:hover {
      background-color: ${themeGet('colors.gray.0')};
      border-color: ${themeGet('colors.border.100')};
      cursor: unset;
    }
  }
`;
