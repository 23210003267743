var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { init as defaultInit, configureScope, captureException as defaultCaptureException, captureMessage as defaultCaptureMessage, extraErrorDataIntegration, } from '@sentry/browser';
import { getUserAgentRegex } from 'browserslist-useragent-regexp';
const SENSITIVE_BREADCRUMB_TYPES = ['http'];
const CUSTOM_BREADCRUMB_CATEGORIES = ['ui.click', 'ui.input'];
const CUSTOM_TAG_NAME = 'data-ui-event';
// eslint-disable-next-line no-restricted-syntax
export * from '@sentry/browser';
export function captureException(exception, captureContext) {
    if (process.env.APP_ENV === 'local') {
        // eslint-disable-next-line no-console
        console.error('[Local Sentry]:', exception, captureContext);
    }
    return defaultCaptureException(exception, captureContext);
}
/**
 * Function to wrap Sentry's captureMessage method. In local environment
 * it will emit a console.error in order to more easily debug errors.
 * @param args
 * @returns
 */
export function captureMessage(message, captureContext) {
    if (process.env.APP_ENV === 'local') {
        // eslint-disable-next-line no-console
        console.error('[Local Sentry]:', message, captureContext);
    }
    return defaultCaptureMessage(message, captureContext);
}
export function init(options) {
    const { application } = options, initOptions = __rest(options, ["application"]);
    defaultInit(Object.assign({ dsn: process.env.SENTRY_DSN, release: process.env.SENTRY_RELEASE, environment: process.env.APP_ENV, beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.type && SENSITIVE_BREADCRUMB_TYPES.includes(breadcrumb.type)) {
                return null;
            }
            const { category } = breadcrumb;
            if (category && CUSTOM_BREADCRUMB_CATEGORIES.includes(category)) {
                const { target } = (hint === null || hint === void 0 ? void 0 : hint.event) || {};
                const { value } = (target === null || target === void 0 ? void 0 : target.attributes.getNamedItem(CUSTOM_TAG_NAME)) || {};
                if (value) {
                    // eslint-disable-next-line no-param-reassign
                    breadcrumb.message = value;
                }
            }
            return breadcrumb;
        }, integrations: [extraErrorDataIntegration()] }, initOptions));
    const supportedBrowserPattern = getUserAgentRegex({
        allowHigherVersions: true,
        // If this is updated then we need to update the browserslist in `babel.config.cjs`.
        browsers: ['>1%', 'not dead', 'not ie <= 11', 'not op_mini all'],
    });
    configureScope(scope => {
        scope.setTag('application', application);
        scope.setTag('browser.supported', supportedBrowserPattern.test(navigator.userAgent));
    });
}
