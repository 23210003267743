import { useState, useMemo, useEffect } from 'react';
import { TextItemLayer } from '../TextItemLayer';
import { Container } from './styled';
export function TextLayer(props) {
    const { scale = 1, page } = props;
    const [{ items, styles }, setState] = useState({ items: [], styles: {} });
    const viewport = useMemo(() => page.getViewport({ scale }), [scale, page]);
    useEffect(() => {
        page.getTextContent().then(textContent => {
            setState({ items: textContent.items, styles: textContent.styles });
        });
    }, [page]);
    return (<Container $width={viewport.width} $height={viewport.height}>
      {items.map((item, index) => (<TextItemLayer 
        // eslint-disable-next-line react/no-array-index-key
        key={index} scale={scale} viewport={viewport} str={item.str} transform={item.transform} textStyle={styles[item.fontName]}/>))}
    </Container>);
}
