import { gql } from '@apollo/client';
import { GetSuggestionFragment } from '../utils/suggestionFragment';
export const query = gql `
  query GetDocumentSuggestionById($id: UUID!) {
    suggestions: fileSystemDocumentSuggestions(where: { id: { eq: $id } }) {
      ...GetSuggestionFragment
    }
  }
  ${GetSuggestionFragment}
`;
