import { Fragment, createElement, Children } from 'react';
import toReact from 'rehype-react';
import sanitize from 'rehype-sanitize';
import gfm from 'remark-gfm';
import parse from 'remark-parse';
import toHtml from 'remark-rehype';
import { unified } from 'unified';
import { Superscript } from './Superscript';
import { footNotesToQuotes } from './footNotesToQuotes';
import { SelfExpandingTable } from './table';
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Anchor, Strong, Emphasis, Delete, BlockQuote, Hairline, UnorderedList, OrderedList, ListItem, TableRow, TableHead, TableDataCell, } from './styled';
const componentMapping = {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    a: Anchor,
    strong: Strong,
    em: Emphasis,
    del: Delete,
    blockquote: BlockQuote,
    hr: Hairline,
    ul: UnorderedList,
    ol: OrderedList,
    li: ListItem,
    table: SelfExpandingTable,
    tr: TableRow,
    th: TableHead,
    td: TableDataCell,
    sup: Superscript,
};
const markupSchema = {
    ancestors: {
        tbody: ['table'],
        td: ['table'],
        th: ['table'],
        thead: ['table'],
        tr: ['table'],
    },
    attributes: {
        a: ['href'],
        blockquote: ['cite'],
        del: ['cite'],
        sup: ['data-footnote-definition'],
        '*': [],
    },
    protocols: {
        cite: ['http', 'https'],
        href: ['http', 'https', 'irc', 'ircs', 'mailto', 'xmpp'],
    },
    strip: ['script'],
    tagNames: [
        'a',
        'blockquote',
        'br',
        'del',
        'div',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hr',
        'li',
        'ol',
        'p',
        'sup',
        'strong',
        'table',
        'tbody',
        'td',
        'th',
        'thead',
        'tr',
        'ul',
    ],
};
const mapChildren = (children) => Children.map(children, child => {
    if (child.type === Fragment) {
        return mapChildren(child.props.children);
    }
    return child;
});
export function stringToReact(content) {
    if (content) {
        const { result } = unified()
            .use(parse)
            .use(gfm)
            .use(footNotesToQuotes)
            .use(toHtml)
            .use(sanitize, markupSchema)
            .use(toReact, { Fragment, createElement, components: componentMapping })
            .processSync(content);
        const children = mapChildren(result);
        if (Children.count(children) === 0) {
            return undefined;
        }
        return children;
    }
    return undefined;
}
