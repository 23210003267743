import { Alignment, Autosave, Bold, CloudServices, Comments, EasyImage, Essentials, ExportWord, Heading, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, ImportWord, Indent, IndentBlock, Italic, Link, List, Notification, PageBreak, Paragraph, Permissions, RealTimeCollaborativeEditing, RealTimeCollaborativeComments, RealTimeCollaborativeRevisionHistory, RealTimeCollaborativeTrackChanges, RestrictedEditingMode, RevisionHistory, RevisionTracker, Strikethrough, Table, TrackChanges, Underline, ComposedHighlight, TableCellProperties, TableCaption, TableColumnResize, TableProperties, TableToolbar, FontBackgroundColor, FontColor, FontSize, BalloonToolbar, AdjacentListsSupport, } from 'pleditor';
import { AppApiConnector } from '../plugins/AppApiConnector';
import { AssistantRTC } from '../plugins/AssistantRTC';
import { BlockComment } from '../plugins/BlockComment';
import { CommentsUi } from '../plugins/CommentsUi';
import { ComposedHighlightToolbar } from '../plugins/ComposedHighlightToolbar';
import { CustomCommentsArchive } from '../plugins/CustomCommentsArchive';
import { DisableTableDragDrop } from '../plugins/DisableTableDragDrop';
import { EditMode } from '../plugins/EditMode';
import { ExternalApiConnector } from '../plugins/ExternalApiConnector';
import { ExternalSuggestions } from '../plugins/ExternalSuggestions';
import { FlexGap } from '../plugins/FlexGap';
import { HandleDocumentFlushedPlugin } from '../plugins/HandleDocumentFlushedPlugin';
import { ImportWordRTC } from '../plugins/ImportWordRTC';
import { InlineAssistantShortcut } from '../plugins/InlineAssistantShortcut';
import { InlineComment } from '../plugins/InlineComment';
import { OffsetPlugin } from '../plugins/OffsetPlugin';
import { ReviewPlugin } from '../plugins/Review';
import { RevisionHistoryToolbarHelper } from '../plugins/RevisionHistoryToolbarHelper';
import { SessionUsers } from '../plugins/SessionUsers';
import { ToastHandler } from '../plugins/ToastHandler';
import { ToggleComments } from '../plugins/ToggleComments';
import { TopToolbar } from '../plugins/TopToolbar';
import { UndoRedo } from '../plugins/UndoRedo';
import { UserMentionsRTC } from '../plugins/UserMentionsRTC';
import { UsersManagerRTC } from '../plugins/UsersManagerRTC';
const BASE_PLUGINS = [
    // CKEditor
    Alignment,
    Autosave,
    BalloonToolbar,
    Bold,
    CloudServices,
    Comments,
    Essentials,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Italic,
    Indent,
    IndentBlock,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    RealTimeCollaborativeEditing,
    RealTimeCollaborativeComments,
    RealTimeCollaborativeTrackChanges,
    RealTimeCollaborativeRevisionHistory,
    RevisionHistory,
    RevisionTracker,
    TrackChanges,
    Strikethrough,
    Table,
    Underline,
    // Custom
    TopToolbar,
    FlexGap,
    CommentsUi,
    CustomCommentsArchive,
    OffsetPlugin,
    ToastHandler,
    ToggleComments,
    SessionUsers,
    HandleDocumentFlushedPlugin,
    UserMentionsRTC,
    UsersManagerRTC,
    ComposedHighlight,
];
// Composed
const BASE_COMPOSED_PLUGINS = [
    ...BASE_PLUGINS,
    BlockComment,
    ComposedHighlightToolbar,
    DisableTableDragDrop,
    RestrictedEditingMode,
    // // Base Composed
    // [-] Autosave,
    // [ ] EditorDocumentState,
    // [ ] Users,
    // [ ] UsersManager,
    // [ ] UserPermissions,
    // [ ] HtmlAttributeSupport,
    // [x] ComposedHighlightsSupport,
    // [x] Comments,
    // [x] CommentsUi,
    // [ ] CommentMentions,
    // [ ] CommentsAdapter,
    // [x] ToastHandler,
    // [x] OffsetPlugin,
    // [ ] CollaboratorSession,
    // [x] DisableTableDragDrop,
    // [x] TopToolbar,
];
export const APP_COMPOSED_PLUGINS = [
    AppApiConnector,
    ...BASE_COMPOSED_PLUGINS,
    AssistantRTC,
    InlineAssistantShortcut,
    ReviewPlugin,
    // // App Composed
    // [x] Assistant,
    // [x] FlexGap,
    // [-] AppApiConnector,
    // [x] BlockComment,
    // [x] ToggleComments,
    // [x] RestrictedEditingMode,
    // [ ] CompanyUsers,
    // [ ] ExternalUsers,
    // [-] SaveHandler,
    // [ ] CustomCommentsArchive,
    // [x] InlineAssistantShortcut,
];
export const EXTERNAL_COMPOSED_PLUGINS = [
    ExternalApiConnector,
    ...BASE_COMPOSED_PLUGINS,
    // // External Composed
    // [x] FlexGap,
    // [-] ExternalApiConnector,
    // [x] BlockComment,
    // [x] ToggleComments,
    // [x] RestrictedEditingMode,
    // [-] SaveHandler,
];
// Free Text
const BASE_FREE_TEXT_PLUGINS = [
    ...BASE_PLUGINS,
    InlineComment,
    UndoRedo,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    EasyImage,
    TableToolbar,
    TableCaption,
    TableProperties,
    TableColumnResize,
    TableCellProperties,
    // // Base Free Text
    // [-] Autosave,
    // [ ] EditorDocumentState,
    // [x] UndoRedo,
    // [ ] Users,
    // [ ] UsersManager,
    // [ ] UserPermissions,
    // [x] Comments,
    // [x] CommentsUi,
    // [ ] CommentsAdapter,
    // [ ] CommentMentions,
    // [x] ToastHandler,
    // [x] OffsetPlugin,
    // [-] CollaboratorSession,
    // [ ] DocumentListsIdCorrectorPlugin,
    // [x] TopToolbar,
];
export const APP_FREE_TEXT_PLUGINS = [
    AppApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    AssistantRTC,
    EditMode,
    ExportWord,
    ImportWord,
    ImportWordRTC,
    InlineAssistantShortcut,
    RevisionHistoryToolbarHelper,
    ReviewPlugin,
    // // App Free Text
    // [x] Assistant,
    // [-] AppApiConnector,
    // [x] InlineComment,
    // [x] ToggleComments,
    // [x] TrackChanges,
    // [ ] CompanyUsers,
    // [ ] ExternalUsers,
    // [-] SuggestionsAdapter,
    // [-] SaveHandler,
    // [x] EditMode,
    // [x] FlexGap,
    // [ ] CustomCommentsArchive,
    // [x] InlineAssistantShortcut,
];
export const EXTERNAL_FREE_TEXT_PLUGINS = [
    ExternalApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    ExternalSuggestions,
    // // External Free Text
    // [x] FlexGap,
    // [-] ExternalApiConnector,
    // [x] InlineComment,
    // [x] ToggleComments,
    // [-] SaveHandler,
    // [x] TrackChanges,
    // [-] SuggestionsAdapter,
    // [x] ExternalSuggestions,
];
