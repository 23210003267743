var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { GetDocumentHtmlBodyDocument } from './query.gql';
import { getColorPickerColors } from '../../hooks/useConfig/utils';
import { useGetEditorToken } from '../../hooks/useGetEditorToken';
export const useSandboxData = (options) => {
    var _a;
    const { refs, plugins, toolbarItems, balloonToolbarItems, currentUser, featureAvailability } = options;
    const { id: documentId } = useActiveDocument();
    const { sidebarContainerRef } = refs;
    const licenseKey = process.env.PLEDITOR_LICENSE_KEY;
    const imageUploadUrl = process.env.PLEDITOR_IMAGE_UPLOAD_URL;
    const _b = useQuery(GetDocumentHtmlBodyDocument, { variables: { documentId } }), { data } = _b, rest = __rest(_b, ["data"]);
    const htmlBody = ((_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.htmlBody) || '';
    const getEditorToken = useGetEditorToken(documentId);
    const featureAvailabilityFallback = Object.assign({ suggestions: false }, featureAvailability);
    const config = {
        licenseKey,
        documentId,
        currentUser,
        featureAvailability: featureAvailabilityFallback,
        language: 'en',
        plugins: [...plugins],
        toolbar: {
            shouldNotGroupWhenFull: false,
            items: toolbarItems,
        },
        balloonToolbar: {
            items: balloonToolbarItems,
        },
        comments: {
            editorConfig: {},
        },
        table: {
            defaultHeadings: { rows: 1 },
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
        },
        fontSize: {
            supportAllValues: true,
            options: [9, 11, 13, 'default', 17, 19, 21],
        },
        fontColor: {
            columns: 12,
            colorPicker: false,
            colors: getColorPickerColors(),
        },
        fontBackgroundColor: {
            columns: 12,
            colorPicker: false,
            colors: getColorPickerColors(),
        },
        sidebar: {
            preventScrollOutOfView: true,
            container: sidebarContainerRef,
        },
        indentBlock: {
            classes: ['ck-custom-block-indent-1', 'ck-custom-block-indent-2', 'ck-custom-block-indent-3'],
        },
        cloudServices: {
            tokenUrl: getEditorToken,
            uploadUrl: imageUploadUrl,
        },
        image: {
            insert: {
                type: 'auto',
                integrations: ['upload'],
            },
            toolbar: [
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'toggleImageCaption',
                '|',
                'resizeImage',
            ],
            resizeUnit: '%',
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null,
                },
                {
                    name: 'resizeImage:80',
                    value: '80',
                },
                {
                    name: 'resizeImage:60',
                    value: '60',
                },
                {
                    name: 'resizeImage:40',
                    value: '40',
                },
            ],
        },
    };
    return Object.assign({ config, htmlBody }, rest);
};
