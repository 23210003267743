import { gql } from '@apollo/client';
export const getUsersAccessByComposersQuery = gql `
  query getUsersAccessByComposers($composerIds: [Unstable__UUID!]) {
    composers: unstable__composers(where: { id: { in: $composerIds } }) {
      acls {
        allowedActions
        userId
      }
    }

    companyUsers {
      id
      userId: user_id
      role
      user {
        id
        name
      }
    }
  }
`;
