import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function ErrorAlert() {
    return (<Container>
      <Alert appearance="danger" icon={<Falcon icon="circle-xmark"/>} title={t({
            message: 'Could not load PDF',
            comment: 'Title for loading pdf error',
        })} message={t({
            message: 'The PDF could not be loaded, please try again',
            comment: 'Message for loading pdf error',
        })}/>
    </Container>);
}
