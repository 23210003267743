import { Trans, plural, t } from '@lingui/macro';
import { Alert, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Entry } from '../Entry';
import { Container, ItemsContainer } from './styled';
export function Entries(props) {
    const { entries, folderName } = props;
    if (entries.length === 0) {
        return null;
    }
    return (<Container>
      <Typography>
        <Trans comment="Delete folder dialog - Are you sure you want to delete message">
          Are you sure you want to delete the folder <strong>{folderName}</strong>? This action is
          irreversible.
        </Trans>
      </Typography>
      <Alert appearance="danger" icon={<Falcon icon="exclamation"/>} title={t({
            comment: 'Delete folder dialog - Alert title',
            message: 'This folder contains items',
        })} message={plural(entries.length, {
            one: '# item will be deleted',
            other: '# items will be deleted',
        })}/>
      <ItemsContainer>
        {entries.map(({ id, kind, name }) => (<Entry key={id} kind={kind} title={name}/>))}
      </ItemsContainer>
    </Container>);
}
