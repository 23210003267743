import { gql } from '@apollo/client';
export const query = gql `
  query GetFreeTextDocument($id: ID!, $companyId: UUID!) {
    document: fileSystemDocument(id: $id) {
      id
      htmlBody
      updatedAt
      versionNumber
    }

    fileSystemCompanyFeatures(where: { companyId: { eq: $companyId } }) {
      id
      companyId
      feature
    }
  }
`;
