import { onError } from '@apollo/client/link/error';
import * as Sentry from 'shared/domains/sentry';
// Log any GraphQL errors or network error that occurred to Sentry
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/
const errorLink = onError(options => {
    const { graphQLErrors, networkError, operation } = options;
    const { sentry } = operation.getContext();
    const ignoreCodes = (sentry === null || sentry === void 0 ? void 0 : sentry.ignore) || [];
    if (networkError) {
        const { message, name } = networkError;
        Sentry.captureMessage(`[Network error]: Name: "${name}". ${message}`, {
            level: 'error',
            tags: {
                error_type: 'network_error',
                operation_name: operation.operationName,
            },
        });
    }
    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            const { message, extensions } = error;
            let sentryMessage = '';
            if (sentry && typeof sentry.message === 'function') {
                sentryMessage = sentry.message({ error, operation });
            }
            else {
                sentryMessage = message;
            }
            const shouldIgnore = ignoreCodes.find(code => (extensions === null || extensions === void 0 ? void 0 : extensions.code) === code || (extensions === null || extensions === void 0 ? void 0 : extensions.subcode) === code);
            if (shouldIgnore) {
                return;
            }
            Sentry.captureMessage(`[GraphQL error]: In "${operation.operationName}". ${sentryMessage}`, {
                level: 'error',
                tags: {
                    error_type: 'graphql_error',
                    operation_name: operation.operationName,
                },
            });
        });
    }
});
export { errorLink };
