var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Pauses the execution for a specified number of milliseconds
export const waitFor = (ms) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise(resolve => {
        setTimeout(() => resolve(undefined), ms);
    });
});
// Checks if the operation is of type Plop
export function isPlop(operation) {
    return Array.isArray(operation.toReplace);
}
// Checks if the operation is of type MoveOperation
export function isMoveOperation(operation) {
    return operation.sourcePosition !== undefined;
}
// Verifies if the operation modifies the document
export const isDocumentOperation = (operation) => operation.isDocumentOperation;
// Replaces the operation with another operation if available. This can occur with
// the UndoRedo plugin that intercepts and transforms operations.
export function replaceOperation(operations, operation) {
    const plop = isPlop(operation);
    if (!plop) {
        return [...operations, operation];
    }
    const { toReplace } = operation;
    if (Array.isArray(toReplace)) {
        return [...operations, ...toReplace];
    }
    return [...operations, operation];
}
// Recalculate the baseVersion of a operation based on the previous operations.
export function recalculateBaseVersion(baseVersion = 0) {
    return (currentPlop, currentIndex) => {
        const modifiedPlop = Object.assign(Object.assign({}, currentPlop), { baseVersion: baseVersion + currentIndex });
        return modifiedPlop;
    };
}
// Checks if the operation moves nodes from the graveyard to the root
export function isNotMoveFromGraveyardOperation(operation) {
    var _a, _b;
    if (!isMoveOperation(operation)) {
        return true;
    }
    return ((_b = (_a = operation.sourcePosition) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.rootName) !== '$graveyard';
}
// Complementary operations are operations received from the backend.
// They will end up here again, and we need to filter them out to avoid sending them back.
export function isNotComplementary(operation) {
    return !('isComplementary' in operation && operation.isComplementary);
}
// The first batch of operations contains the base version 0, which represents the entire
// document. It will be interpreted as an 'add' operation by the backend, causing duplication
// of the document content.
export const isNotBaseVersion = (operation) => operation.baseVersion !== 0;
// Check to see if the operation was created in this editor
// https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_model_batch-Batch.html#member-isLocal
export const isLocalOperation = (operation) => { var _a; return !operation.batch || ((_a = operation.batch) === null || _a === void 0 ? void 0 : _a.isLocal); };
