import { Trans, t } from '@lingui/macro';
import { Banner } from '@pocketlaw/tetris';
import { Container, Viewer, Image, Paper } from './styled';
export function Preview(props) {
    const { previewFiles } = props;
    const { files, prefix } = previewFiles;
    return (<Container>
      <Banner appearance="info">
        <Trans>
          Preview example. The final document depends on the data you add in the composer.
        </Trans>
      </Banner>
      {files.map(file => (<Viewer key={file}>
          <Paper>
            <Image src={`${process.env.CDN_URL}/${prefix}${file}`} alt={t `Preview example`}/>
          </Paper>
        </Viewer>))}
    </Container>);
}
