import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Table, Alert, Box, LoaderOverlay, ButtonGroup } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { DocumentStatusAction } from './DocumentStatusAction';
import { SearchResultTableBody } from './SearchResultsTableBody';
import { SearchResultsTableHead } from './SearchResultsTableHead';
import { TableContext } from './TableContext';
import { useSelected } from './useSelected';
import { useSearchContext } from '../SearchProvider';
import { SearchResultsNoMatch } from '../SearchResultsNoMatch';
import { SearchResultsPagination } from '../SearchResultsPagination';
import { SearchTableColumnToggle } from '../SearchTableColumnToggle';
import { SelectedText } from './styled';
export function SearchResultsTable(props) {
    const { results = [], loading, error, totalHitsCount = 0, limit = 0 } = props;
    const featureFlag = useFeatureFlag('feature_document_status');
    const [selected, setSelected] = useSelected(results);
    const { hiddenColRows, sortBy, setSortBy } = useSearchContext();
    const [activeAction, setActiveAction] = useState();
    const selectedCount = selected.length;
    const resultCount = results.length;
    const noResults = resultCount === 0 && !loading;
    const { sortId, sortOrder } = sortBy || {};
    const handleSort = (newSortId, newSortState) => {
        if (newSortId) {
            setSortBy(newSortId, newSortState);
        }
    };
    if (error) {
        return (<Alert appearance="danger" icon={<Falcon icon="circle-xmark"/>} message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Error alert message',
            })}/>);
    }
    if (noResults) {
        return <SearchResultsNoMatch />;
    }
    return (<TableContext.Provider value={{ activeAction, setActiveAction, selected, setSelected, results }}>
      <Box flexDirection="column" gap="lg">
        <Box gap="lg" alignItems="center" borderRadius="sm">
          {featureFlag && (<ButtonGroup>
              <SelectedText disabled={selectedCount === 0}>
                <Trans comment="Bulk action: Selected count">{selectedCount} selected</Trans>
              </SelectedText>
              <DocumentStatusAction />
            </ButtonGroup>)}

          <Box ml="auto" gap="md">
            <SearchResultsPagination disabled={loading} totalCount={totalHitsCount} limit={limit}/>
            <SearchTableColumnToggle />
          </Box>
        </Box>

        <LoaderOverlay transparent enabled={loading}>
          <Table hideColRows={hiddenColRows} sortId={sortId} sortOrder={sortOrder} onSort={handleSort}>
            <SearchResultsTableHead />
            <SearchResultTableBody />
          </Table>
        </LoaderOverlay>
      </Box>
    </TableContext.Provider>);
}
