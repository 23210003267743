import { useEsigning } from 'app/domains/esigning';
export const useDocumentLocked = () => {
    const { signed, creating, pending, cancelling, submittingCancellation, submittingCreation, approvalWorkflow, } = useEsigning();
    const { approvalPending, approvalApproved } = approvalWorkflow;
    if (signed) {
        return {
            locked: true,
            reason: 'esigning:signed',
        };
    }
    if (creating || pending || cancelling || submittingCancellation || submittingCreation) {
        return {
            locked: true,
            reason: 'esigning:pending',
        };
    }
    if (approvalApproved) {
        return {
            locked: true,
            reason: 'approval:approved',
        };
    }
    if (approvalPending) {
        return {
            locked: true,
            reason: 'approval:pending',
        };
    }
    return { locked: false, reason: null };
};
