import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function EmptyAttachmentsState() {
    return (<EmptyState withAvatar icon={<Falcon icon="file-circle-info"/>} title={t({
            comment: 'No attachments added to sign request info - title',
            message: 'No attachments added',
        })} description={t({
            comment: 'No attachments added to sign request info - description',
            message: 'No attachments were added to the sign request',
        })}/>);
}
