var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { CustomError } from 'shared/domains/errors/CustomError';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { uploadAttachmentMutation } from './mutation';
export function useUploadAttachment() {
    const { data: accountData } = useAccount();
    const { companyId = '' } = accountData;
    const [mutate, mutateState] = useMutation(uploadAttachmentMutation, {
        variables: { path: companyId },
    });
    const uploadAttachment = (file) => __awaiter(this, void 0, void 0, function* () {
        const createPostRequest = (options) => {
            var _a;
            const { url = '', fields = {} } = ((_a = options === null || options === void 0 ? void 0 : options.data) === null || _a === void 0 ? void 0 : _a.signed) || {};
            // The mutation returns JSON for the fields value which is converted to unknown
            // so we have to hack our way around it
            const typedFields = fields;
            const body = new FormData();
            Object.entries(typedFields).forEach(([key, value]) => {
                body.append(key, value);
            });
            body.append('file', file);
            return { url, body, key: typedFields.key };
        };
        const runPostRequest = (_a) => __awaiter(this, [_a], void 0, function* ({ url, body, key }) {
            var _b;
            const response = yield fetch(url, { method: 'POST', body });
            if (!response.ok) {
                const bodyResponse = yield response.text();
                const xmlErrorDocument = new DOMParser().parseFromString(bodyResponse, 'text/xml');
                const element = xmlErrorDocument.querySelector('Code');
                const code = (_b = element === null || element === void 0 ? void 0 : element.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\s+/g, '');
                throw new CustomError('failed to upload file to S3', {
                    data: {
                        bodyResponse,
                        code,
                        status: response.status,
                        statusText: response.statusText,
                    },
                });
            }
            return {
                path: key,
                name: file.name,
            };
        });
        return mutate({
            variables: {
                path: companyId,
                mimeType: file.type,
                filename: file.name,
            },
        })
            .then(createPostRequest)
            .then(runPostRequest);
    });
    return [uploadAttachment, mutateState];
}
