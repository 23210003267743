import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSearchContext } from '../SearchProvider';
export function RemoveAllButton(props) {
    const { className } = props;
    const { clearFilters, getActiveFilters } = useSearchContext();
    const activeFilters = getActiveFilters();
    if (activeFilters.length < 1) {
        return null;
    }
    return (<Button onClick={() => clearFilters(activeFilters)} appearance="ghost" size="small" className={className}>
      <Falcon icon="remove"/>
      <Trans comment="Remove all search filters button label">Remove all</Trans>
    </Button>);
}
