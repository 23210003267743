import { gql } from '@apollo/client';
export const getAssistantQuery = gql `
  query GetAssistant($documentId: UUID!) {
    assistant: getAssistant(id: $documentId) @client {
      id
      selectionRange
      selectionText
      suggestionText
    }
  }
`;
