import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function ConversionAlert(props) {
    const { loading } = props;
    const LoadingText = loading
        ? t({
            message: 'Converting document to PDF…',
            comment: 'Alert message when the selected document is being converted in the attachment file browser',
        })
        : t({
            message: 'This document will be converted to a PDF when you send the contract for signing',
            comment: 'Alert message when the selected document will be converted in the attachment file browser',
        });
    return (<Container>
      <Alert appearance="info" icon={<Falcon icon="circle-info"/>} message={LoadingText}/>
    </Container>);
}
