import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { MenuBody, MenuItem, MenuList, Switch } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { ErrorMessage } from 'shared/domains/forms';
import { FormSubmitButton } from './FormSubmitButton';
import { RightAlign } from './styled';
export function BooleanField() {
    const [field, , helpers] = useField({
        name: 'value',
        type: 'checkbox',
    });
    const { value } = field;
    const handleOnChange = (event) => {
        const { checked } = event.target;
        helpers.setValue(checked);
    };
    const trueLabel = t({ message: 'Yes', comment: 'Metadata: Boolean true value' });
    const falseLabel = t({ message: 'No', comment: 'Metadata: Boolean false value' });
    return (<Fragment>
      <MenuBody>
        <MenuList>
          <MenuItem as="label">
            {value ? trueLabel : falseLabel}
            <RightAlign>
              <Switch checked={value} onChange={handleOnChange}/>
            </RightAlign>
          </MenuItem>
        </MenuList>
        <ErrorMessage name="value"/>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
