import { gql } from '@apollo/client';
export const getCompanyUsersQuery = gql `
  query getCompanyUsers {
    companyUsers {
      id
      user {
        id
        name
        email
      }
    }
  }
`;
