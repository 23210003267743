var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sanitizeCommentBody } from './sanitizeCommentBody';
export function transformThread(comment) {
    return __awaiter(this, void 0, void 0, function* () {
        const { id = '', body = '', user, createdAt, resolved, resolvedAt = '', resolvedBy, replies = [], } = comment || {};
        const { id: userId = '' } = user || {};
        const sanitizedBody = yield sanitizeCommentBody(body);
        const commentThread = {
            id,
            commentId: id,
            content: sanitizedBody,
            authorId: userId,
            createdAt: new Date(createdAt || ''),
            attributes: { lastContent: sanitizedBody },
        };
        const transformedRepliesPromises = replies.map((reply) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const sanitizedReplyBody = yield sanitizeCommentBody(reply.body);
            return {
                id: reply.id,
                commentId: reply.id || '',
                content: sanitizedReplyBody,
                authorId: ((_a = reply.user) === null || _a === void 0 ? void 0 : _a.id) || '',
                createdAt: new Date(reply.createdAt || ''),
                attributes: { lastContent: sanitizedReplyBody },
            };
        }));
        const transformedReplies = yield Promise.all(transformedRepliesPromises);
        return {
            id,
            threadId: id,
            comments: [commentThread, ...transformedReplies],
            isFromAdapter: true,
            unlinkedAt: null,
            isResolved: resolved,
            resolvedAt: resolvedAt ? new Date(resolvedAt) : null,
            resolvedBy,
            attributes: {},
            context: {},
        };
    });
}
