import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { ErrorMessage } from 'shared/domains/forms';
export const FolderButton = styled.button `
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
export const RightAlign = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const StyledErrorMessage = styled(ErrorMessage) `
  padding: ${unit('md')} ${unit('md')} 0 ${unit('md')};
`;
