var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { DialogHeader, DialogContent, useToast, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { boolean, object, string } from 'yup';
import { FormDialog } from 'shared/domains/forms';
import { Body } from './Body';
import { ErrorMessage } from './ErrorMessage';
import { Footer } from './Footer';
import { useCreateExternalSharingInvite } from './useCreateExternalSharingInvite';
import { usePreview } from '../../hooks/usePreview';
export function ShareDocumentDialog(props) {
    const schema = object().shape({
        email: string()
            .email(t `This must be a valid email.`)
            .required(t `This is a required field.`),
        forceConvertDocument: boolean(),
        convertDocument: boolean().when('forceConvertDocument', {
            is: (forceConvertDocument) => forceConvertDocument,
            then: boolean().oneOf([true]),
            otherwise: boolean().oneOf([false]),
        }),
    });
    const { onClose, onSubmit } = props;
    const { createInvite, error } = useCreateExternalSharingInvite();
    const { addToast } = useToast();
    const { isComposed } = usePreview();
    const initialValues = { email: '', forceConvertDocument: isComposed };
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        const { email, forceConvertDocument } = values;
        try {
            yield createInvite(email, forceConvertDocument);
            addToast({ title: t `Invite sent`, appearance: 'success' });
            if (onSubmit) {
                onSubmit();
            }
            onClose();
        }
        catch (exception) {
            addToast({
                title: t({
                    message: 'There was a problem sending the invite',
                    comment: 'Generic error message in toast when inviting a external user to a document',
                }),
                appearance: 'warning',
            });
        }
    });
    const title = t({
        message: 'Share with people',
        comment: 'share document dialog title',
    });
    return (<FormDialog validateOnMount initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} onClose={onClose} size="sm">
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
            <Falcon icon="share-square"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Body />
        <ErrorMessage message={error}/>
      </DialogContent>
      <Footer onClose={onClose}/>
    </FormDialog>);
}
