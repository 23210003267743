import { Fragment, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { LoaderOverlay } from '@pocketlaw/tetris';
import { DecoupledEditor } from 'pleditor';
import { usePleditor } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import { SANDBOX_PLUGINS } from './plugins';
import { useSandboxData } from './useSandboxData';
import { CompanyLogo } from '../../components/CompanyLogo';
import { CriticalError } from '../../components/CriticalError';
import { FeatureAvailability } from '../../components/FeatureAvailability';
import { documentState } from '../../documentState';
import { useIsLayoutReady } from '../../hooks/useIsLayoutReady';
import { ComposedHighlightStyles } from '../../plugins/ComposedHighlightToolbar';
import { BasePleditorStyles, PleditorContainer, PleditorSidebar, PleditorWrapper, } from '../../styled';
import { RevisionHistoryEditor } from '../RTCPleditor/RevisionHistoryEditor';
export function SandboxPleditor(props) {
    const { toolbarItems, balloonToolbarItems, currentUser, featureAvailability } = props;
    const { setPleditor } = usePleditor();
    const commentSidebarRef = useRef(null);
    const editorContainerRef = useRef(null);
    const viewerContainerRef = useRef(null);
    const viewerEditorElementRef = useRef(null);
    const viewerSidebarContainerRef = useRef(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const isLayoutReady = useIsLayoutReady();
    const { htmlBody, config, loading } = useSandboxData({
        plugins: SANDBOX_PLUGINS,
        currentUser,
        toolbarItems,
        balloonToolbarItems,
        featureAvailability,
        refs: {
            editorContainerRef: editorContainerRef.current,
            viewerContainerRef: viewerContainerRef.current,
            viewerEditorElementRef: viewerEditorElementRef.current,
            viewerSidebarContainerRef: viewerSidebarContainerRef.current,
            sidebarContainerRef: commentSidebarRef.current,
        },
    });
    const handleEditorReady = (editor) => {
        setEditorLoaded(true);
        setPleditor(editor);
        editor.data.set(htmlBody);
    };
    const handleEditorError = (e) => {
        documentState('error');
        Sentry.captureException(e, { tags: { feature: 'pleditor' } });
    };
    return (<LoaderOverlay label="Loading Sandbox Editor..." enabled={!editorLoaded || loading}>
      <FeatureAvailability />
      <BasePleditorStyles />
      <ComposedHighlightStyles />
      <CriticalError />
      {/* Editor view */}
      <PleditorWrapper ref={editorContainerRef}>
        <PleditorContainer>
          {isLayoutReady && (<Fragment>
              <CompanyLogo sidebar={commentSidebarRef.current} container={editorContainerRef.current} editorLoaded={editorLoaded}/>
              <CKEditor editor={DecoupledEditor} config={config} onReady={handleEditorReady} onError={handleEditorError}/>
            </Fragment>)}
          <PleditorSidebar ref={commentSidebarRef}/>
        </PleditorContainer>
      </PleditorWrapper>
      {/* Revision history editor view */}
      <RevisionHistoryEditor containerRef={viewerContainerRef} editorRef={viewerEditorElementRef} sidebarRef={viewerSidebarContainerRef}/>
    </LoaderOverlay>);
}
