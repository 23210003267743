import { gql } from '@apollo/client';
export const duplicateDocumentDialogQuery = gql `
  query duplicateDocumentDialog($id: ID!, $companyId: UUID!) {
    document: fileSystemDocument(id: $id) {
      id
      type
      files {
        id
      }
      draft {
        id
        composerId: composer_id
        version
        result
        templateVersionReference: template_version_reference
        templateVersionTemplateId: template_version_template_id
        composer: unstable__composer {
          id
          tree {
            id
            version
          }
          composerPlans {
            id
            plan {
              id
            }
          }
        }
      }
    }
    company(id: $companyId) {
      id
      plans {
        id
        plan {
          id
        }
      }
    }
  }
`;
