import { useRef, useMemo, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, Column, Grid, AvatarWithLabel, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useCreateDraft } from 'app/domains/templates';
import { PageLoader } from 'shared/domains/common-ui';
import { isUploadedDocument } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import CreateEmptyDraftButton from './CreateEmptyDraftButton';
import CreatePrefilledDraftButton from './CreatePrefilledDraftButton';
import ErrorAlert from './ErrorAlert';
import NotsupportedDocumentTypeDialog from './NotsupportedDocumentTypeDialog';
import UpgradeAlert from './UpgradeAlert';
import useDuplicateDocumentDialog from './useDuplicateDocumentDialog';
import useLatestTemplateVersion from './useLatestTemplateVersion';
export function DuplicateDocumentDialog(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { id, onDuplicated, onClose, isFreeText = false } = props;
    const query = useDuplicateDocumentDialog(id);
    const { document, company } = (_a = query === null || query === void 0 ? void 0 : query.data) !== null && _a !== void 0 ? _a : {};
    const { draft } = document !== null && document !== void 0 ? document : {};
    const templateId = (draft === null || draft === void 0 ? void 0 : draft.templateVersionTemplateId) || '';
    const dialogTitle = t({
        message: 'Create copy',
        comment: 'duplicate document dialog title',
    });
    const templateQuery = useLatestTemplateVersion({
        id: templateId,
        skip: !templateId,
    });
    const { reference: latestTemplateVersionReference } = ((_c = (_b = templateQuery.data) === null || _b === void 0 ? void 0 : _b.templateVersions) === null || _c === void 0 ? void 0 : _c[0]) || {};
    const currentPlan = (_e = (_d = company === null || company === void 0 ? void 0 : company.plans) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.plan;
    const includedInPlan = Boolean((_h = (_g = (_f = draft === null || draft === void 0 ? void 0 : draft.composer) === null || _f === void 0 ? void 0 : _f.composerPlans) === null || _g === void 0 ? void 0 : _g.map(({ plan }) => plan)) === null || _h === void 0 ? void 0 : _h.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id)));
    const uploadedDocument = isUploadedDocument(document || {});
    const createPrefilledDraftRef = useRef(null);
    const createEmptyDraftRef = useRef(null);
    const upgradeLinkRef = useRef(null);
    const hasDraftTemplateVersion = Boolean(draft === null || draft === void 0 ? void 0 : draft.templateVersionReference);
    const createPrefilledDraftDisabled = !includedInPlan || !hasDraftTemplateVersion;
    const createEmptyDraftDisabled = !includedInPlan;
    const initialFocusRef = useMemo(() => {
        if (!createPrefilledDraftDisabled) {
            return createPrefilledDraftRef;
        }
        if (!createEmptyDraftDisabled) {
            return createEmptyDraftRef;
        }
        return upgradeLinkRef;
    }, [createPrefilledDraftDisabled, createEmptyDraftDisabled]);
    const { mutate: createDraft, state: { loading, error }, } = useCreateDraft();
    const handleCreateDraft = useCallback((options) => {
        const { withResult } = options || {};
        const defaults = {
            composerId: (draft === null || draft === void 0 ? void 0 : draft.composerId) || '',
            templateId,
            templateVersionTemplateId: templateId,
        };
        function makeVariables() {
            var _a, _b;
            if (withResult) {
                const { result, version, templateVersionReference } = draft || {};
                return Object.assign(Object.assign({}, defaults), { result: result, version: version || '', templateVersionReference: templateVersionReference !== null && templateVersionReference !== void 0 ? templateVersionReference : latestTemplateVersionReference });
            }
            return Object.assign(Object.assign({}, defaults), { result: [], version: ((_b = (_a = draft === null || draft === void 0 ? void 0 : draft.composer) === null || _a === void 0 ? void 0 : _a.tree) === null || _b === void 0 ? void 0 : _b.version) || '', templateVersionReference: latestTemplateVersionReference });
        }
        createDraft(makeVariables())
            .then(response => {
            var _a;
            const { data } = response;
            if (onDuplicated) {
                onDuplicated({ id: ((_a = data === null || data === void 0 ? void 0 : data.created) === null || _a === void 0 ? void 0 : _a.returning[0].id) || '' });
            }
        })
            .catch(graphqlError => {
            Sentry.captureException(graphqlError);
        });
    }, [draft, templateId, createDraft, latestTemplateVersionReference, onDuplicated]);
    if (isFreeText) {
        return null;
    }
    if (query.loading) {
        return (<Dialog size="sm" onClose={onClose}>
        <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={dialogTitle}>
              <Falcon icon="copy"/>
            </AvatarWithLabel>}/>
        <DialogContent>
          <PageLoader />
        </DialogContent>
        <DialogFooter>
          <Button type="button" appearance="secondary" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </DialogFooter>
      </Dialog>);
    }
    if (uploadedDocument) {
        return <NotsupportedDocumentTypeDialog onClose={onClose}/>;
    }
    return (<Dialog size="sm" initialFocusRef={initialFocusRef} onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={dialogTitle}>
            <Falcon icon="copy"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Grid directions="vertical" spacing={2}>
          <Column width="100%">
            <Typography $appearance="300">
              <Trans>How do you want to create the new copy?</Trans>
            </Typography>
          </Column>
          <CreatePrefilledDraftButton ref={createPrefilledDraftRef} outdated={createPrefilledDraftDisabled} onCreateDraft={handleCreateDraft} loading={loading || templateQuery.loading} disabled={createPrefilledDraftDisabled}/>
          <CreateEmptyDraftButton ref={createEmptyDraftRef} onCreateDraft={handleCreateDraft} loading={loading || templateQuery.loading} disabled={createEmptyDraftDisabled}/>
          {error && <ErrorAlert />}
          {!includedInPlan && <UpgradeAlert ref={upgradeLinkRef}/>}
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
