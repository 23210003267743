var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Chip, TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { metadataConfig } from 'shared/domains/metadata';
export function DocumentTypeCell(props) {
    var _a, _b;
    const { documentType } = props, rest = __rest(props, ["documentType"]);
    const translatedDocumentType = (_b = (_a = metadataConfig
        .get('DocumentType')) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.find(item => item.value === documentType);
    if (!documentType) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <Chip label={translatedDocumentType === null || translatedDocumentType === void 0 ? void 0 : translatedDocumentType.text} appearance="secondary" variant="outline"/>
    </TableBodyCell>);
}
