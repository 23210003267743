import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantChatActions } from 'app/domains/assistant';
import { emails } from 'shared/domains/config';
import { Padder } from './styled';
export function NoCredits() {
    const emailHref = `mailto:${emails.support}`;
    return (<Fragment>
      <Padder>
        <Alert appearance="warning" icon={<Falcon icon="cog"/>} title={t({
            comment: 'Warning title when document assistant credit is empty',
            message: 'No credits',
        })} message={t({
            comment: 'Warning message when document assistant credit is empty',
            message: 'Contact support to add new credits.',
        })}>
          <Button as="a" appearance="secondary" size="small" href={emailHref}>
            <Trans comment="Warning button label when document assistant credit is empty">
              Contact support
            </Trans>
          </Button>
        </Alert>
      </Padder>
      <AssistantChatActions />
    </Fragment>);
}
