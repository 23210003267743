var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { insightsDashboardMock } from 'app/domains/insights/mocks';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { GetDashboardsForSidebarDocument } from './GetDashboardsForSidebar.gql';
const defaultDashboard = insightsDashboardMock();
export function useListDashboards() {
    const featureFlag = useFeatureFlag('feature_new_insights_save');
    const _a = useQuery(GetDashboardsForSidebarDocument, {
        skip: !featureFlag,
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { searchInsightsDashboards = [] } = data || {};
    const dashboards = featureFlag ? searchInsightsDashboards : [defaultDashboard];
    return Object.assign({ data: dashboards }, rest);
}
