import { InsertOperation, AttributeOperation, Position, Range, } from 'pleditor';
import { replaceOperation, isMoveOperation, isSplitOperation, cloneEditorNode, getNodesFromSourcePosition, addGraveyardChild, isComplementary, } from './utils';
/**
 * This function will modify any operations that would move text or nodes from the graveyard to the main root
 *
 * 1. SplitOperation handling
 *    - If the operation is a SplitOperation, it checks whether the split operation involves moving
 *      an element node from the 'graveyard'. If so it will copy the graveyard child and append it to
 *      a cloned instance of the operation. This needs to be done in order to instruct BE on what node
 *      should be inserted by the SplitOperation since BE do not have any persisted graveyard.
 *    - If the graveyard child has a 'listItemId' attribute we will also create one additional
 *      AttributeOperation with the required data to instruct on what to do.
 *
 * 2. ReinsertOperation Handling (a special case of MoveOperation):
 *    - If the operation is a ReinsertOperation (re-inserting nodes or text from the graveyard),
 *      this function replaces it with a new InsertOperation.
 *    - For text reinsertion, it extracts the necessary text content from the local 'graveyard'
 *      and creates a new text node with this content. This new node is then inserted via an
 *      InsertOperation.
 *    - For node reinsertion, it retrieves the required nodes from the local 'graveyard', and
 *      creates a new InsertOperation to insert these nodes at the desired position.
 *
 */
export function operationHandler(editor, operation) {
    var _a;
    const { graveyard } = editor.model.document;
    const { version } = editor.model.document.history;
    const root = editor.model.document.getRoot('main');
    const splitOperation = isSplitOperation(operation);
    const moveOperation = isMoveOperation(operation);
    const complementaryOperation = isComplementary(operation);
    const isGraveyardOperation = splitOperation || moveOperation;
    if (!root || complementaryOperation || !isGraveyardOperation) {
        return;
    }
    if (splitOperation) {
        const toReplaceOperations = [];
        const nodePath = (_a = operation.graveyardPosition) === null || _a === void 0 ? void 0 : _a.path;
        const graveyardChild = graveyard.getNodeByPath(nodePath);
        const listItemId = graveyardChild.getAttribute('listItemId');
        const parentElement = operation.splitPosition.parent;
        const shouldCreateAttributeOperation = listItemId && parentElement.is('element');
        const clonedOperation = addGraveyardChild(operation, graveyardChild);
        toReplaceOperations.push(clonedOperation);
        if (shouldCreateAttributeOperation) {
            const copyIdFromPreviousSibling = Boolean(parentElement.getAttribute('listItemId') === listItemId);
            if (copyIdFromPreviousSibling) {
                const sourceRange = new Range(operation.insertionPosition, operation.insertionPosition.getShiftedBy(1));
                const attrOperation = new AttributeOperation(sourceRange, 'listItemId', listItemId, listItemId, version);
                Object.assign(attrOperation, { extra: { copyIdFromPreviousSibling } });
                toReplaceOperations.push(attrOperation);
            }
        }
        if (toReplaceOperations.length) {
            replaceOperation(operation, toReplaceOperations);
        }
    }
    if (moveOperation) {
        const nodePath = operation.sourcePosition.path;
        const { path, stickiness } = operation.targetPosition;
        const toReplaceOperations = [];
        const graveyardChild = cloneEditorNode(graveyard.getNodeByPath(nodePath));
        const position = new Position(root, path, stickiness);
        // If the MoveOperation is moving text (Text proxies)
        if (graveyardChild.is('$text')) {
            const nodes = getNodesFromSourcePosition(operation);
            const insertOperation = new InsertOperation(position, nodes, version);
            replaceOperation(operation, insertOperation);
        }
        // If the MoveOperation is moving Nodes
        else {
            let firstListItemId = null;
            // Collect all graveyard children as a array.
            const graveyardChildren = [...Array(operation.howMany).keys()]
                .map(index => {
                // NOTE: This might be possible to do with Range({ shallow: true })
                // Using the getNodesFromSourcePosition util func
                const gyChild = graveyard.getNodeByPath(operation.sourcePosition.getShiftedBy(index).path);
                return gyChild;
            })
                .reduce((nodes, node) => {
                if (node) {
                    return [...(nodes || []), node];
                }
                return nodes;
            }, []);
            for (let index = 0; index < graveyardChildren.length; index += 1) {
                const gyChild = graveyardChildren[index];
                const currentListItemId = gyChild.getAttribute('listItemId');
                const hasListItemIds = Boolean(currentListItemId || firstListItemId);
                const isFirstChild = currentListItemId && index === 0;
                const currentAndFirstIdMatch = !isFirstChild && hasListItemIds && currentListItemId === firstListItemId;
                if (isFirstChild) {
                    firstListItemId = currentListItemId;
                    const previousNode = root.getNodeByPath(position.getShiftedBy(-1).path);
                    const previousListItemId = previousNode.getAttribute('listItemId');
                    if (previousListItemId === firstListItemId) {
                        const attrRange = new Range(position, position.getShiftedBy(1));
                        const attrOperation = new AttributeOperation(attrRange, 'listItemId', currentListItemId, currentListItemId, version);
                        Object.assign(attrOperation, { extra: { copyIdFromPreviousSibling: true } });
                        toReplaceOperations.push(attrOperation);
                    }
                }
                if (currentAndFirstIdMatch) {
                    const attrRange = new Range(position.getShiftedBy(index), position.getShiftedBy(index + 1));
                    const attrOperation = new AttributeOperation(attrRange, 'listItemId', currentListItemId, currentListItemId, version);
                    Object.assign(attrOperation, { extra: { copyIdFromPreviousSibling: true } });
                    toReplaceOperations.push(attrOperation);
                }
            }
            const insertOperation = new InsertOperation(position, graveyardChildren, version);
            replaceOperation(operation, [insertOperation, ...toReplaceOperations]);
        }
    }
}
