import gql from 'graphql-tag';
export const QUERY = gql `
  query getAttachmentFromLastSignRequestForForm($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      lastSignRequest {
        id
        attachments {
          id
          name
          path
        }
      }
    }
  }
`;
