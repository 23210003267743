import { pxToRem, unit } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const listStyles = css `
  .ck :is(ul, ol, li, menu, dl, dd) {
    margin: revert;
    padding: revert;
  }

  .ck.ck-content {
    ol {
      margin-left: ${pxToRem(24)};
      min-width: ${pxToRem(24)};
      list-style: none;
      counter-reset: item;

      padding: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        margin: 0;
      }

      li {
        margin-top: ${unit('xl')};

        &::marker {
          content: '';
        }

        &:before {
          float: left;
          margin-left: -${pxToRem(24)};
          margin-right: ${pxToRem(8)};
        }

        &:has(> h1) {
          &:before {
            font-size: ${pxToRem(30)};
            font-weight: bold;
          }
        }

        &:has(> h2) {
          &:before {
            font-size: ${pxToRem(24)};
            font-weight: bold;
          }
        }

        &:has(> h3) {
          &:before {
            font-size: ${pxToRem(18)};
            font-weight: bold;
          }
        }

        &:has(> h4) {
          &:before {
            font-size: ${pxToRem(16)};
            font-weight: bold;
          }
        }
      }

      > li {
        &:before {
          content: counters(item, '.') '.';
          counter-increment: item;
        }
      }

      table ol {
        counter-reset: nestedItem;

        > li {
          &:before {
            content: counters(nestedItem, '.') '.';
            counter-increment: nestedItem;
          }
        }
      }
    }
  }
`;
