import { useParams } from 'react-router-dom';
import { ROOT, FolderPickerDialog, useMoveEntry } from 'app/domains/filesystem';
export function MoveDialog(props) {
    const { type, payload, onClose } = props;
    const { folderId } = useParams();
    const { id } = payload;
    const [move, { loading }] = useMoveEntry({ kind: type, id });
    const handleSubmit = (folder) => {
        move((folder === null || folder === void 0 ? void 0 : folder.id) || ROOT).then(onClose);
    };
    return (<FolderPickerDialog entryId={payload.id} entryType={type} actionType="move" defaultParentId={folderId} loading={loading} onSubmit={handleSubmit} onClose={onClose}/>);
}
