var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { IconButton, Menu, MenuList, MenuItem, MenuDivider, useToast, Loader, Tooltip, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog, } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { useRecipientsByStatus } from 'app/domains/esigning/hooks/useRecipientsByStatus';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
import { EditSignatoryDialog } from './EditSignatoryDialog';
import { RemoveScriveSignatoryDialog } from './RemoveScriveSignatoryDialog';
import { RemoveSignatoryDialog } from './RemoveSignatoryDialog';
import { useUpdateSignRequestSignatory } from './useUpdateSignRequestSignatory';
import { mapDialogSignatory } from './utils';
import { LoaderContainer } from './styled';
import { DialogType, ErrorSubcodes } from './types';
export function PendingActions(props) {
    const { signatory, isNotOnlyParty } = props;
    const { id, mobile, authenticationMode, isDefault } = signatory;
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { canRemoveSignatory: canRemoveParty, canUseEditPendingSignatory } = providerProperties;
    const { refetch: refetchLastSignRequest } = useLastSignRequest();
    const { allSignatories } = useRecipientsByStatus();
    const { addToast } = useToast();
    const [updateSignRequestSignatory] = useUpdateSignRequestSignatory(id);
    const [updatingSignatory, setUpdatingSignatory] = useState(false);
    const canEditMobile = authenticationMode === EsigningProviderAuthenticationModeSlug.SmsOtp && Boolean(mobile);
    const [dialogType, setDialogType] = useState(DialogType.None);
    const [renderActions, setRenderActions] = useState(false);
    const showActions = () => setRenderActions(true);
    const hideActions = () => setRenderActions(false);
    const closeDialog = () => setDialogType(DialogType.None);
    const handleEditEmail = () => {
        hideActions();
        setDialogType(DialogType.EditEmail);
    };
    const handleEditMobile = () => {
        hideActions();
        setDialogType(DialogType.EditMobile);
    };
    const handleEditSignatory = () => {
        hideActions();
        setDialogType(DialogType.EditSignatory);
    };
    const handleRemoveParty = () => {
        hideActions();
        setDialogType(canRemoveParty ? DialogType.RemoveSignatory : DialogType.RemoveSignatoryScrive);
    };
    const handleSubmitSignatoryChanges = (newSignatory) => __awaiter(this, void 0, void 0, function* () {
        setUpdatingSignatory(true);
        updateSignRequestSignatory(newSignatory)
            .then(() => {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Successfully updated party!',
                    comment: 'Toast message for successfully updating a signing party',
                }),
            });
        })
            .catch(error => {
            var _a;
            if (error instanceof ApolloError) {
                const subcode = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
                if (subcode === ErrorSubcodes.CannotModifySignedSigner) {
                    refetchLastSignRequest();
                    addToast({
                        appearance: 'warning',
                        title: t({
                            comment: 'Toast title for error when updating a signing party that has signed',
                            message: 'Could not update the party',
                        }),
                        message: t({
                            comment: 'Toast message for error when updating a signing party that has signed',
                            message: 'The party has already signed the document',
                        }),
                    });
                    return;
                }
            }
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Could not update the party',
                    comment: 'Toast message for error when updating a signing party',
                }),
            });
        })
            .finally(() => {
            setUpdatingSignatory(false);
        });
    });
    if (isDefault) {
        return (<Tooltip position="left" title={t({
                comment: 'Tooltip for default signatory (signing and non signing party)',
                message: 'Default party',
            })}>
        <IconButton $round size="small" appearance="ghost">
          <Falcon icon="lock"/>
        </IconButton>
      </Tooltip>);
    }
    return (<Fragment>
      {dialogType === DialogType.RemoveSignatory && (<RemoveSignatoryDialog signatoryId={id} onClose={closeDialog}/>)}
      {dialogType === DialogType.RemoveSignatoryScrive && (<RemoveScriveSignatoryDialog onClose={closeDialog}/>)}
      {dialogType === DialogType.EditEmail && (<EditSignatoryDialog formKey="email" signatoryId={id} onClose={closeDialog}/>)}
      {dialogType === DialogType.EditMobile && (<EditSignatoryDialog formKey="mobile" signatoryId={id} onClose={closeDialog}/>)}
      {dialogType === DialogType.EditSignatory && (<ESignSignatoryFormDialog hideSaveContact type="Signatory" initialValues={mapDialogSignatory(signatory)} allSignatories={allSignatories.map(mapDialogSignatory)} onClose={closeDialog} onSubmit={handleSubmitSignatoryChanges}/>)}
      <Menu open={renderActions} onClose={hideActions} target={updatingSignatory ? (<LoaderContainer>
              <Loader size={5}/>
            </LoaderContainer>) : (<IconButton $round size="small" appearance="ghost" onClick={showActions}>
              <Falcon icon="ellipsis-v"/>
            </IconButton>)}>
        <MenuBody>
          <MenuList>
            {canUseEditPendingSignatory ? (<MenuItem onClick={handleEditSignatory}>
                <Falcon icon="pencil"/>
                <Trans comment="Label for edit e-signing party">Edit party</Trans>
              </MenuItem>) : (<Fragment>
                <MenuItem onClick={handleEditEmail}>
                  <Falcon icon="envelope"/>
                  <Trans comment="Label for edit e-signing party email">Edit email</Trans>
                </MenuItem>
                {canEditMobile && (<MenuItem onClick={handleEditMobile}>
                    <Falcon icon="phone"/>
                    <Trans comment="Label for edit e-signing party mobile">Edit mobile</Trans>
                  </MenuItem>)}
              </Fragment>)}
            {isNotOnlyParty && (<Fragment>
                <MenuDivider />
                <MenuItem onClick={handleRemoveParty}>
                  <Falcon icon="trash"/>
                  <Trans comment="Label for removing e-signing party">Withdraw</Trans>
                </MenuItem>
              </Fragment>)}
          </MenuList>
        </MenuBody>
      </Menu>
    </Fragment>);
}
