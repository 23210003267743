import { t } from '@lingui/macro';
import { MultiSelectFilter, useMultiselectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function ESigningFilter() {
    const filter = useMultiselectFilter({
        paramKey: 'esigning',
        items: [
            {
                id: 'pending',
                title: t({ message: 'Pending', comment: 'Search e-sign filter: Pending status' }),
            },
            {
                id: 'signed',
                title: t({ message: 'Signed', comment: 'Search e-sign filter: Signed status' }),
            },
            {
                id: 'rejected',
                title: t({ message: 'Rejected', comment: 'Search e-sign filter: Rejected status' }),
            },
        ],
        name: searchFilterCopy().esigning,
    });
    return <MultiSelectFilter filter={filter}/>;
}
