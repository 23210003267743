var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useState } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useComposerCategories } from './useComposerCategories';
import { useComposers } from './useComposers';
import { useComposersCount } from './useComposersCount';
import { useCreateDraftWrapper } from './useCreateDraftWrapper';
export { useComposerData } from './hook';
export const TemplateDataContext = createContext(null);
export function ComposerDataProvider(props) {
    const { initialComposerId = null, children, onClose } = props;
    const { composersCount, loading: loadingCount, error: countError } = useComposersCount();
    const { categories, loading: loadingCategories, error: categoriesError, } = useComposerCategories();
    const { createDraft } = useCreateDraftWrapper();
    const { addToast } = useToast();
    const history = useHistory();
    const loading = loadingCount || loadingCategories;
    const error = Boolean(countError || categoriesError);
    const [selectedComposerId, setSelectedComposerId] = useState(initialComposerId);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [templatesFilter, setTemplatesFilter] = useState(composersCount['templates:company'] !== undefined && composersCount['templates:company'] > 0
        ? 'templates:company'
        : 'templates:included');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const filterData = {
        selectedCategoryIds,
        searchQuery,
        templatesFilter,
        selectedLanguages,
    };
    const { composers, loading: loadingComposers, error: composersError } = useComposers(filterData);
    const selectComposer = (id) => setSelectedComposerId(id);
    const goBackToSelect = () => setSelectedComposerId(null);
    const addCategoryId = (id) => setSelectedCategoryIds([...selectedCategoryIds, id]);
    const removeCategoryId = (id) => setSelectedCategoryIds(selectedCategoryIds.filter(categoryId => categoryId !== id));
    const addSelectedLanguage = (languageCode) => {
        if (!selectedLanguages.includes(languageCode)) {
            setSelectedLanguages([...selectedLanguages, languageCode]);
        }
    };
    const removeSelectedLanguage = (languageCode) => setSelectedLanguages(selectedLanguages.filter(code => code !== languageCode));
    const createDocument = (options) => __awaiter(this, void 0, void 0, function* () {
        try {
            const draftId = yield createDraft(options);
            history.push(`/draft/${draftId}`);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({ comment: 'Create draft error - Title', message: 'Could not create draft' }),
                message: t({
                    comment: 'Create draft error - Message',
                    message: 'Something went wrong while trying to create the draft, please try again',
                }),
            });
        }
    });
    const value = {
        composers,
        error: Boolean(error || composersError),
        categories,
        loading: Boolean(loading || loadingComposers),
        selectedComposerId,
        selectedCategoryIds,
        searchQuery,
        templatesAmount: composersCount,
        templatesFilter,
        selectedLanguages,
        goBackToSelect,
        addCategoryId,
        removeCategoryId,
        selectComposer,
        setSearchQuery,
        setTemplatesFilter,
        addSelectedLanguage,
        removeSelectedLanguage,
        createDocument,
        closeDialog: onClose,
    };
    return <TemplateDataContext.Provider value={value}>{children}</TemplateDataContext.Provider>;
}
