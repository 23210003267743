import { createContext, useCallback, useState } from 'react';
import { isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { addToSearchString } from './utils';
export const Context = createContext({});
export function SearchProvider(props) {
    const { children, referrer, columnConfigs } = props;
    const [hiddenColRows, setHiddenColRows] = useState([]);
    const [resultOffset, setResultOffset] = useState(0);
    const history = useHistory();
    const setParamValue = useCallback((paramKey, value = []) => {
        const newSearchString = addToSearchString({
            paramKey,
            value,
            searchString: history.location.search,
        });
        setResultOffset(0);
        history.replace({
            search: newSearchString,
        });
    }, [history]);
    const getParamValue = useCallback((paramKey) => {
        const searchParams = new URLSearchParams(history.location.search);
        return searchParams.getAll(paramKey);
    }, [history]);
    const getMultiSelectFilterValue = useCallback((paramKey) => getParamValue(paramKey), [getParamValue]);
    const getSingleSelectFilterValue = useCallback((paramKey) => {
        const [singleSelectValue] = getParamValue(paramKey);
        return singleSelectValue || undefined;
    }, [getParamValue]);
    const getBooleanFilterValue = useCallback((paramKey) => {
        const [booleanValue] = getParamValue(paramKey);
        if (booleanValue) {
            return booleanValue === 'true';
        }
        return undefined;
    }, [getParamValue]);
    const getDateRangeFilterValue = useCallback((paramKey) => {
        const [dateRangeString] = getParamValue(paramKey);
        const [startDate, endDate] = (dateRangeString === null || dateRangeString === void 0 ? void 0 : dateRangeString.split(',')) || [];
        const data = {};
        if (startDate && isValid(new Date(startDate))) {
            Object.assign(data, {
                startDate: new Date(startDate),
            });
        }
        if (endDate && isValid(new Date(endDate))) {
            Object.assign(data, {
                endDate: new Date(endDate),
            });
        }
        return data;
    }, [getParamValue]);
    const getSimpleTextFilterValue = useCallback((paramKey) => {
        const [simpleText] = getParamValue(paramKey);
        return simpleText || '';
    }, [getParamValue]);
    const getNumberRangeFilterValue = useCallback((paramKey) => {
        const [numberRange] = getParamValue(paramKey);
        const [from, to] = (numberRange === null || numberRange === void 0 ? void 0 : numberRange.split(',')) || [];
        return {
            from: from ? Number(from) : undefined,
            to: to ? Number(to) : undefined,
        };
    }, [getParamValue]);
    const isFilterActive = useCallback((paramKey) => { var _a; return ((_a = getParamValue(paramKey)) === null || _a === void 0 ? void 0 : _a.length) > 0; }, [getParamValue]);
    const getActiveFilters = useCallback(() => {
        const searchParam = new URLSearchParams(history.location.search);
        const activeParamKeys = Array.from(searchParam.keys());
        const activeFilters = activeParamKeys.filter((paramKey, index, self) => self.indexOf(paramKey) === index &&
            paramKey !== 'filter_draft' &&
            paramKey !== 'query' &&
            paramKey !== 'sort');
        return activeFilters;
    }, [history]);
    const [searchQueryText] = getParamValue('query') || [];
    const clearFilters = (keys) => {
        const searchParams = new URLSearchParams(history.location.search);
        keys.forEach(key => searchParams.delete(key));
        history.replace({ search: searchParams.toString() });
    };
    const addFilterToDraft = useCallback((paramKey) => {
        setParamValue('filter_draft', [paramKey]);
    }, [setParamValue]);
    const removeFilterFromDraft = useCallback((paramKey) => {
        const newDraftFilters = (getParamValue('filter_draft') || []).filter(key => key !== paramKey);
        setParamValue('filter_draft', newDraftFilters);
    }, [getParamValue, setParamValue]);
    const isFilterInDraft = useCallback((paramKey) => (getParamValue('filter_draft') || []).includes(paramKey), [getParamValue]);
    const [sortState = 'updatedAt_desc'] = getParamValue('sort');
    const [sortId, sortOrder] = (sortState === null || sortState === void 0 ? void 0 : sortState.split('_')) || [];
    const setSortBy = (id, order) => {
        setParamValue('sort', [`${id}_${order}`]);
    };
    const value = {
        query: searchQueryText || '',
        referrer,
        clearFilters,
        setParamValue,
        getParamValue,
        isFilterActive,
        getActiveFilters,
        addFilterToDraft,
        removeFilterFromDraft,
        isFilterInDraft,
        getMultiSelectFilterValue,
        getDateRangeFilterValue,
        getNumberRangeFilterValue,
        getSingleSelectFilterValue,
        getSimpleTextFilterValue,
        getBooleanFilterValue,
        hiddenColRows,
        setHiddenColRows,
        setSortBy,
        sortBy: {
            sortId,
            sortOrder: sortOrder,
        },
        setResultOffset,
        resultOffset,
        columnConfigs,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
