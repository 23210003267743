import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useActiveDocument } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import { QUERY } from './query';
export function useDownloadAttachment() {
    const { id: documentId } = useActiveDocument();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [attachmentId, setAttachmentId] = useState();
    const [getAttachments] = useLazyQuery(QUERY);
    const handler = (id) => {
        setLoading(true);
        setAttachmentId(id);
        getAttachments({
            variables: {
                id: documentId,
            },
        })
            .then(response => {
            var _a, _b, _c;
            const { attachments } = ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.lastSignRequest) || {};
            const attachment = attachments === null || attachments === void 0 ? void 0 : attachments.find(file => (file === null || file === void 0 ? void 0 : file.id) === id);
            return (_c = attachment === null || attachment === void 0 ? void 0 : attachment.signed) === null || _c === void 0 ? void 0 : _c.url;
        })
            .then(url => {
            if (!url) {
                throw Error('Failed to download attachment as no URL was found');
            }
            window.location.href = url;
        })
            .catch(error => {
            addToast({
                title: t({
                    comment: 'Notification when attachment download could not go through',
                    message: 'Attachment could not be downloaded. Try again.',
                }),
                appearance: 'destructive',
            });
            Sentry.captureException(error);
        })
            .finally(() => {
            setLoading(false);
            setAttachmentId(undefined);
        });
    };
    return { download: handler, loading, attachmentId };
}
