import { useReactiveVar } from '@apollo/client';
import { t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button } from '@pocketlaw/tetris';
import { documentState } from '../../documentState';
export function CriticalError() {
    const state = useReactiveVar(documentState);
    const title = t({ message: 'Error', comment: 'Pleditor: Unrecoverable error title in dialog' });
    const text = t({
        message: 'A unrecoverable error occurred and we need to reload the document.',
        comment: 'Pleditor: Unrecoverable error text in dialog',
    });
    const confirmText = t({
        message: 'Reload',
        comment: 'Pleditor: Unrecoverable error confirm button.',
    });
    const handleOnClick = () => window.location.reload();
    if (state !== 'error') {
        return null;
    }
    return (<Dialog>
      <DialogHeader title={title}/>
      <DialogContent>{text}</DialogContent>
      <DialogFooter>
        <Button appearance="danger" onClick={handleOnClick}>
          {confirmText}
        </Button>
      </DialogFooter>
    </Dialog>);
}
