import { Alignment, Bold, Comments, Essentials, FontSize, FontColor, FontBackgroundColor, Heading, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, EasyImage, Indent, IndentBlock, Italic, Link, List, AdjacentListsSupport, Notification, PageBreak, Paragraph, Permissions, Strikethrough, Table, TableToolbar, TableCaption, TableProperties, TableColumnResize, TableCellProperties, Underline, ComposedHighlight, CloudServices, BalloonToolbar, } from 'pleditor';
import { AppApiConnector } from '../../plugins/AppApiConnector';
import { CommentsUi } from '../../plugins/CommentsUi';
import { CustomCommentsArchive } from '../../plugins/CustomCommentsArchive';
import { EditMode } from '../../plugins/EditMode';
import { FlexGap } from '../../plugins/FlexGap';
import { InlineAssistantShortcut } from '../../plugins/InlineAssistantShortcut';
import { InlineComment } from '../../plugins/InlineComment';
import { OffsetPlugin } from '../../plugins/OffsetPlugin';
import { ReviewPlugin } from '../../plugins/Review';
import { ToastHandler } from '../../plugins/ToastHandler';
import { ToggleComments } from '../../plugins/ToggleComments';
import { TopToolbar } from '../../plugins/TopToolbar';
import { UndoRedo } from '../../plugins/UndoRedo';
export const SANDBOX_PLUGINS = [
    // CKEditor
    Alignment,
    BalloonToolbar,
    Bold,
    CloudServices,
    Comments,
    EasyImage,
    Essentials,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Italic,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    Strikethrough,
    Table,
    TableToolbar,
    TableCaption,
    TableColumnResize,
    TableProperties,
    TableCellProperties,
    Underline,
    // Custom
    ComposedHighlight,
    AppApiConnector,
    TopToolbar,
    FlexGap,
    CommentsUi,
    CustomCommentsArchive,
    OffsetPlugin,
    ToastHandler,
    ToggleComments,
    InlineComment,
    EditMode,
    InlineAssistantShortcut,
    ReviewPlugin,
    UndoRedo,
];
