import { useQuery } from '@apollo/client';
import { query } from './query';
export function useGetDocumentData(documentId, companyId) {
    /**
     * Fetch policy is set to 'no-cache'
     * This is done now so we do not re-fetch on changes in the cache but
     * let the editor decide on how the document should update once loaded.
     */
    const { data, loading, error } = useQuery(query, {
        fetchPolicy: 'network-only',
        skip: !documentId || !companyId,
        variables: {
            id: documentId,
            companyId,
        },
    });
    const { htmlBody, updatedAt, versionNumber } = (data === null || data === void 0 ? void 0 : data.document) || {};
    const suggestionFeature = data === null || data === void 0 ? void 0 : data.fileSystemCompanyFeatures.find(companyFeature => companyFeature.feature === 'SUGGESTIONS');
    const featureAvailability = {
        suggestions: Boolean(suggestionFeature),
    };
    return {
        loading,
        error,
        data: {
            body: htmlBody || '',
            updatedAt: updatedAt || '',
            versionNumber: versionNumber || 1,
            featureAvailability,
        },
    };
}
