import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function ProcessingOfPersonalDataFilter() {
    var _a, _b;
    const processingOfPersonalDataOptions = (_b = (_a = metadataConfig
        .get('ProcessingOfPersonalData')) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(type => ({
        id: type.value,
        title: type.text,
    }));
    const filter = useSingleSelectFilter({
        paramKey: 'processingOfPersonalData',
        items: processingOfPersonalDataOptions || [],
        name: searchFilterCopy().processingOfPersonalData,
    });
    return <SingleSelectFilter filter={filter}/>;
}
