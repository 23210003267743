var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Menu, MenuBody, MenuList, MenuItem, IconButton, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDeleteTask } from '../../../hooks';
import { useTasks } from '../../TasksProvider';
export function PreviewMenuButton(props) {
    const { taskId, completed } = props;
    const [isOpen, setOpen] = useState(false);
    const { handleEditTask } = useTasks();
    const { mutate, loading } = useDeleteTask();
    const { addToast } = useToast();
    const handleDelete = (id) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield mutate(id);
            addToast({
                title: t({
                    message: 'Task deleted',
                    comment: 'Toast message when successfully deleted a task',
                }),
                appearance: 'success',
            });
        }
        catch (deleteMutationError) {
            addToast({
                title: t({
                    message: 'An error occurred',
                    comment: 'Toast message when failed to delete task',
                }),
                appearance: 'destructive',
            });
        }
    });
    return (<Menu open={isOpen} onClose={() => setOpen(false)} target={<IconButton size="small" $round appearance="ghost" disabled={loading} onClick={() => setOpen(!isOpen)}>
          <Falcon icon="ellipsis-v"/>
        </IconButton>}>
      <MenuBody>
        <MenuList>
          {!completed && (<MenuItem onClick={() => handleEditTask(taskId)}>
              <Falcon icon="pen-to-square"/>
              <Trans comment="Task preview modal edit list button">Edit</Trans>
            </MenuItem>)}
          <MenuItem onClick={() => handleDelete(taskId)}>
            <Falcon icon="trash"/>
            <Trans comment="Task preview modal delete list button">Delete</Trans>
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
