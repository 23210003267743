import { Plugin } from 'pleditor';
export class HtmlAttributeSupport extends Plugin {
    init() {
        const { editor } = this;
        /**
         * Support for id attribute
         */
        editor.model.schema.extend('$block', { allowAttributes: ['block-id'] });
        /**
         * This should avoid copying block-id attribute when pressed enter.
         * but its a known issue https://github.com/ckeditor/ckeditor5/issues/8428
         */
        editor.model.schema.setAttributeProperties('block-id', {
            copyOnEnter: false,
        });
        editor.conversion.attributeToAttribute({
            view: 'id',
            model: 'block-id',
            converterPriority: 'low',
        });
    }
}
