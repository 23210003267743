var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { buildDocumentEntityTypeFilter, useSearchArgs, useSearchContext, } from 'app/domains/global-search';
import { SaveCustomSearchViewDocument } from './mutation.gql';
export function useSaveViewMutation() {
    const [mutate, { data, loading, error }] = useMutation(SaveCustomSearchViewDocument);
    const { query, sortBy, getMultiSelectFilterValue } = useSearchContext();
    const { getSearchWhereArgs } = useSearchArgs();
    const saveView = (viewName) => __awaiter(this, void 0, void 0, function* () {
        const input = {
            name: viewName,
            query,
            where: Object.assign(Object.assign({}, getSearchWhereArgs()), { entityType: buildDocumentEntityTypeFilter(getMultiSelectFilterValue('type')) }),
        };
        if (sortBy) {
            const { sortId, sortOrder } = sortBy;
            input.orderBy = [{ [sortId]: sortOrder }];
        }
        return mutate({
            variables: { input },
            update: (cache, result) => {
                var _a;
                const newView = (_a = result.data) === null || _a === void 0 ? void 0 : _a.createSearchCustomView;
                cache.modify({
                    fields: {
                        searchCustomViews(existing = []) {
                            return [...existing, newView];
                        },
                    },
                });
            },
        });
    });
    return {
        saveView,
        data,
        loading,
        error,
    };
}
