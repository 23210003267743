import { t } from '@lingui/macro';
import { Chip, ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetPath } from './useGetPath';
import { useSimpleTextFilter } from '../../SimpleTextFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function FilesystemPathFilter() {
    const filter = useSimpleTextFilter({
        name: searchFilterCopy().mpath,
        paramKey: 'mpath',
    });
    const { data, error, loading } = useGetPath(filter.value);
    const handleOnClick = () => {
        if (!loading) {
            filter.clear();
        }
    };
    if (!filter.value)
        return null;
    if (error)
        return null;
    return (<Chip size="large" appearance="secondary" label={t({ message: 'Path', comment: 'Search filter: Path' })} secondary={data.path} action={<ChipAction onClick={handleOnClick}>
          {loading ? <Falcon icon="spinner"/> : <Falcon icon="remove"/>}
        </ChipAction>}/>);
}
