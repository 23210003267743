import { t } from '@lingui/macro';
import { useGeneratedId } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { isDate } from 'date-fns';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { StyledButton } from './styled';
export function DateButton(props) {
    const { date, label, onClick } = props;
    const id = useGeneratedId('dateButton');
    const safeDate = isDate(date) ? date.toISOString() : date;
    const formattedDate = localizedFormatDate(safeDate);
    return (<div>
      <span id={id}>
        <Text appearance="neutral" weight="semibold">
          {label}
        </Text>
      </span>
      <StyledButton aria-describedby={id} appearance="ghost" $stretch onClick={onClick} type="button">
        <Falcon icon="calendar" $size={16}/>
        {date
            ? formattedDate
            : t({
                message: 'Set date',
                comment: 'Date range filter: "Set date" button label',
            })}
      </StyledButton>
    </div>);
}
