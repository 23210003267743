import client from 'app/domains/apollo';
import { assistantFragment } from './fragment';
import { readAssistantCache } from './readAssistantCache';
export const updateAssistantCache = (documentId, data) => {
    const cache = readAssistantCache(documentId);
    if (!cache) {
        return null;
    }
    const updatedAssistant = Object.assign(Object.assign({}, cache), data);
    client.writeFragment({
        fragment: assistantFragment,
        id: client.cache.identify({ __typename: 'Assistant', id: documentId }),
        data: updatedAssistant,
    });
    return updatedAssistant;
};
