import { endOfDay, startOfDay } from 'date-fns';
export function buildDateRangeFilter(dateRange) {
    const { startDate, endDate } = dateRange;
    if (!startDate && !endDate) {
        return undefined;
    }
    const filter = {};
    if (startDate) {
        Object.assign(filter, {
            gte: startOfDay(startDate).toISOString(),
        });
    }
    if (endDate) {
        Object.assign(filter, {
            lte: endOfDay(endDate).toISOString(),
        });
    }
    return filter;
}
