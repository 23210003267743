import { Fragment } from 'react';
import { MenuBody, MenuList, TextInput } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { ErrorMessage } from 'shared/domains/forms';
import { FormSubmitButton } from './FormSubmitButton';
export function SimpleTextField() {
    const [field, meta] = useField('value');
    const error = Boolean(meta.touched && meta.error);
    return (<Fragment>
      <MenuBody>
        <MenuList>
          <TextInput stretch {...field} invalid={error}/>
          <ErrorMessage name="value"/>
        </MenuList>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
