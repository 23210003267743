var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t, select } from '@lingui/macro';
import { Card, CardContent, CardHeader } from '@pocketlaw/tetris';
import { usersOnPermissionType } from './utils/usersOnPermissionType';
import { useUsersAccessByComposers } from '../../hooks/useUsersAccessByComposers';
import { AccessMenuButton } from '../AccessMenuButton';
import { UpdateAccessMenuItem } from '../UpdateAccessMenuItem';
import { UserAccessList } from '../UserAccessList';
export function UserAccessSection(props) {
    const { accessDropdownPermission } = props;
    const _a = useUsersAccessByComposers(), { owners } = _a, accessTypeUsers = __rest(_a, ["owners"]);
    const { users, userIds } = usersOnPermissionType({
        accessType: accessDropdownPermission,
        accessTypeUsers,
        owners,
    });
    const canAddAccess = accessDropdownPermission === 'noAccess' || accessDropdownPermission === 'partialAccess';
    const canRemoveAccess = accessDropdownPermission === 'access' || accessDropdownPermission === 'partialAccess';
    return (<Card size="small">
      <CardHeader title={t({
            comment: 'Contract access Card header: Title',
            message: 'Users',
        })} subtitle={t({
            comment: 'Contract access Card header: subTitle',
            message: select(accessDropdownPermission, {
                access: 'With access to selected templates',
                partialAccess: 'With partial access to selected templates',
                noAccess: 'With no access to selected templates',
                other: 'With no access to selected templates',
            }),
        })} action={userIds.length > 0 && (<AccessMenuButton>
              {canAddAccess && <UpdateAccessMenuItem type="add" userIds={userIds} withSubtitle/>}
              {canRemoveAccess && (<UpdateAccessMenuItem type="remove" userIds={userIds} withSubtitle/>)}
            </AccessMenuButton>)}/>
      <CardContent>
        <UserAccessList users={users} accessDropdownPermission={accessDropdownPermission}/>
      </CardContent>
    </Card>);
}
