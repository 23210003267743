import { padding, marginBottom } from '@pocketlaw/tetris';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Container = styled.div `
  ${marginBottom(9)}
`;
export const StyledSpacer = styled(Spacer) `
  justify-content: space-between;
  align-items: center;
  ${marginBottom(7)};
`;
export const DrawerWrapper = styled.div `
  ${padding(6)};
`;
