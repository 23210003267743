var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql } from '@apollo/client';
const deleteCommentMutation = gql `
  mutation deleteComment($id: UUID!) {
    deleteFileSystemDocumentComment(id: $id) {
      affected
    }
  }
`;
const deleteCommentReplyMutation = gql `
  mutation deleteCommentReply($id: UUID!) {
    deleteFileSystemDocumentCommentReply(id: $id) {
      affected
    }
  }
`;
export function deleteHeadComment(apiClient, id) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield apiClient.mutate({
            mutation: deleteCommentMutation,
            variables: { id },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id, __typename: 'FileSystemDocumentComment' }),
                    fields(fieldValue, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
        return data === null || data === void 0 ? void 0 : data.deleteFileSystemDocumentComment;
    });
}
export function deleteReplyComment(apiClient, id) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield apiClient.mutate({
            mutation: deleteCommentReplyMutation,
            variables: { id },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id, __typename: 'FileSystemDocumentCommentReply' }),
                    fields(fieldValue, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
        return data === null || data === void 0 ? void 0 : data.deleteFileSystemDocumentCommentReply;
    });
}
