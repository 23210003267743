import { t } from '@lingui/macro';
import { SidebarMenuCollapsible } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useListDashboards } from './useListDashboards';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleInsights() {
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const { data, loading } = useListDashboards();
    const activeChild = useLinkMatch({
        matchPath: ['/insights'],
        matchExact: false,
    });
    if (loading) {
        return null;
    }
    return (<SidebarMenuCollapsible icon={<Falcon icon="chart-simple"/>} label={t({ comment: 'Sidebar menu - Insights label', message: 'Insights' })} activeChild={activeChild} collapsibleKey="insights" open={collapsibleStates.insights} onToggle={toggleCollapsible}>
      {!data.length && (<CollapsibleItemLink to="/insights" label={t({ comment: 'Sidebar menu - Insights no dashboards added', message: 'General' })} matchPath={['/insights']}/>)}
      {data.map(dashboard => (<CollapsibleItemLink key={dashboard.id} to={`/insights/${dashboard.id}`} label={dashboard.label} matchPath={`/insights/${dashboard.id}`}/>))}
    </SidebarMenuCollapsible>);
}
