var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createUploadedDocumentMutation } from './mutation';
export function useCreateDocument(options) {
    const { onCreated } = options || {};
    const account = useAccount();
    const { companyId } = account.data || {};
    const [mutate, mutation] = useMutation(createUploadedDocumentMutation, {
        onCompleted(data) {
            if (onCreated) {
                onCreated([data.document]);
            }
        },
    });
    const handler = useCallback((variables) => {
        const { parentId, name, file, userAcls, teamAcls } = variables;
        const { type: mimeType } = file, restFile = __rest(file, ["type"]);
        return mutate({
            variables: {
                document: {
                    companyId,
                    parentId: parentId || ROOT,
                    name,
                    files: [
                        Object.assign(Object.assign({}, restFile), { mimeType, companyId: companyId }),
                    ],
                    acls: userAcls.map(({ userId, actions }) => ({
                        userId,
                        companyId: companyId,
                        actions,
                    })),
                    teamAcls: teamAcls.map(({ teamId, actions }) => ({
                        teamId,
                        companyId: companyId,
                        actions,
                    })),
                },
            },
        });
    }, [companyId, mutate]);
    return [handler, mutation];
}
