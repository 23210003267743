import { useCallback, useState } from 'react';
import { Table, TableBody, unit } from '@pocketlaw/tetris';
import { ErrorMessage } from './ErrorMessage';
import { LoadingIndicator } from './LoadingIndicator';
import { NoTemplatesAccess } from './NoTemplatesAccess';
import { TemplateHead } from './TemplateHead';
import { TemplateRow } from './TemplateRow';
import { useComposerBrowser } from './hooks/useComposerBrowser';
export function ContractTemplateList(props) {
    const { onSelect } = props;
    const [selectedIds, setSelectedIds] = useState(null);
    const { data, error, loading } = useComposerBrowser();
    const handleSelect = useCallback((ids) => {
        setSelectedIds(ids);
        onSelect(ids);
    }, [onSelect]);
    if (loading) {
        return <LoadingIndicator />;
    }
    if (error) {
        return <ErrorMessage />;
    }
    if (data.length === 0) {
        return <NoTemplatesAccess />;
    }
    return (<Table dense border>
      <colgroup>
        <col width="auto"/>
        <col width={unit(12)}/>
        <col width={unit(12)}/>
      </colgroup>
      <TemplateHead />
      <TableBody>
        {data.map(({ id, name, locked, locale, composerId }) => (<TemplateRow key={id} id={id} name={name} locked={locked} locale={locale} selected={id === (selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.templateId)} onSelect={templateId => handleSelect({ templateId, composerId })}/>))}
      </TableBody>
    </Table>);
}
