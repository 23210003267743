import { Fragment } from 'react';
import { t, Trans } from '@lingui/macro';
import { SearchInput, MenuHeader, MenuList, MenuSubheader, Loader, Alert, MenuBody, } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { UserItem } from './UserItem';
import { useGetUsers } from './useGetUsers';
import { FormSubmitButton } from '../FormSubmitButton';
import { StyledErrorMessage } from './styled';
export function UserField() {
    const { values } = useFormikContext();
    const { users, loading, error } = useGetUsers();
    const { result, search } = useFuse(users, {
        keys: ['name'],
    });
    const placeholder = t({
        message: 'Search',
        comment: 'Metadata: User search input placeholder text.',
    });
    const resultToShow = (result.length ? result : users).filter(user => !values.value.includes(user.id || ''));
    const selectedUsers = users.filter(user => values.value.includes(user.id || ''));
    const hasSelectedUsers = Boolean(selectedUsers.length);
    const isFilteredResult = Boolean(result.length);
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return (<Alert appearance="danger" message={t({
                message: 'Error loading the users',
                comment: 'Metadata: Error message displayed when we could not load the company users',
            })}/>);
    }
    return (<Fragment>
      <MenuHeader sticky>
        <SearchInput stretch variant="line" placeholder={placeholder} onChange={e => search(e.target.value)}/>
      </MenuHeader>

      <MenuBody>
        {hasSelectedUsers && (<Fragment>
            <MenuSubheader id="metadata-list-selected-items">
              <Trans comment="Metadata: User reference, selected values header">Selected</Trans>
            </MenuSubheader>

            <MenuList aria-labelledby="metadata-list-selected-items" as="nav" role="menu">
              {selectedUsers.map(item => (<UserItem key={item.id} id={item.id} name={item.name}/>))}
            </MenuList>
          </Fragment>)}

        <MenuSubheader id="metadata-selected-items">
          {isFilteredResult ? (<Trans comment="Metadata: User reference. All users header when filtered result">
              Filtered users
            </Trans>) : (<Trans comment="Metadata: User reference. All users header">All users</Trans>)}
        </MenuSubheader>

        <MenuList aria-labelledby="metadata-list-all-items" as="nav" role="menu">
          {resultToShow.map(item => (<UserItem key={item.id} id={item.id} name={item.name}/>))}
        </MenuList>
      </MenuBody>

      <StyledErrorMessage name="value"/>

      <FormSubmitButton />
    </Fragment>);
}
