import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
`;
export const Spacer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
