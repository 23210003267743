import { useQuery } from '@apollo/client';
import { useComposersTable } from 'app/domains/templates';
import { getUsersAccessByComposersQuery } from './getUsersAccessByComposers';
import { filterUsersByAccess } from './utils';
export function useUsersAccessByComposers() {
    const { drawerData } = useComposersTable();
    const composerIds = (drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds) || [];
    const query = useQuery(getUsersAccessByComposersQuery, {
        variables: { composerIds },
        fetchPolicy: 'no-cache',
    });
    const data = query.data || query.previousData;
    const { companyUsers, composers } = data || {};
    const users = companyUsers === null || companyUsers === void 0 ? void 0 : companyUsers.map(companyUser => {
        var _a;
        return ({
            id: companyUser.userId || '',
            name: ((_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.name) || '',
            role: companyUser.role,
        });
    });
    const otherRoles = (users === null || users === void 0 ? void 0 : users.filter(user => user.role !== 'owner')) || [];
    const ownerRoles = (users === null || users === void 0 ? void 0 : users.filter(user => user.role === 'owner')) || [];
    const filteredUsersOnAccess = filterUsersByAccess({ users: otherRoles, composers });
    return Object.assign(Object.assign({ owners: ownerRoles }, filteredUsersOnAccess), { loading: query.loading, error: Boolean(query.error) });
}
