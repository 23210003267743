import { gql } from '@apollo/client';
export const GetSuggestionFragment = gql `
  fragment GetSuggestionFragment on FileSystemDocumentSuggestion {
    id
    type
    userId
    createdAt
    status
    data
    subType
    attributes
    user {
      id
      name
    }
  }
`;
