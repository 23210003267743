var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUsers } from './getUsers';
import { PleditorPlugin } from '../PleditorPlugin';
import { UsersManager } from '../UsersManager';
export class CompanyUsers extends PleditorPlugin {
    static get pluginName() {
        return 'CompanyUsers';
    }
    static get requires() {
        return ['UsersManager', 'ApiConnector'];
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.addCompanyUsers();
        });
    }
    addCompanyUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const usersManager = this.editor.plugins.get(UsersManager);
            const users = yield getUsers(this.apiConnector);
            users.forEach(companyUser => {
                const { user } = companyUser || {};
                usersManager.addUser(user);
            });
        });
    }
}
