import { useComposerData } from '../ComposerDataProvider';
import { ComposerSelect } from '../ComposerSelect';
import { DraftPreview } from '../DraftPreview';
export function Root() {
    const { selectedComposerId } = useComposerData();
    if (selectedComposerId) {
        return <DraftPreview composerId={selectedComposerId}/>;
    }
    return <ComposerSelect />;
}
