import { useQuery } from '@apollo/client';
import { getCompanyUsersQuery } from './query';
import { MultiSelectFilter, useMultiselectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function UserFilter() {
    var _a, _b;
    const { data, loading } = useQuery(getCompanyUsersQuery);
    const users = (_b = (_a = data === null || data === void 0 ? void 0 : data.companyUsers) === null || _a === void 0 ? void 0 : _a.map(companyUser => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            id: (_b = (_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
            title: (_f = (_d = (_c = companyUser.user) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_e = companyUser.user) === null || _e === void 0 ? void 0 : _e.email) !== null && _f !== void 0 ? _f : '',
        });
    })) !== null && _b !== void 0 ? _b : [];
    const filter = useMultiselectFilter({
        items: users,
        paramKey: 'user',
        name: searchFilterCopy().user,
        loading,
    });
    return <MultiSelectFilter filter={filter}/>;
}
