import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { UploadAttachmentDropdown } from '../UploadAttachmentDropdown';
export function AttachmentsEmptyState() {
    return (<EmptyState withAvatar icon={<Falcon icon="paperclip"/>} title={t({
            comment: 'No attachments added empty state - title',
            message: 'Add your first file',
        })} description={t({
            comment: 'No attachments added empty state - description',
            message: 'All your attachments will be listed here',
        })} actions={<UploadAttachmentDropdown origin="emptyState"/>}/>);
}
