import { gql } from '@apollo/client';
export const CreateCommentReplyFragment = gql `
  fragment CreateCommentReplyFragment on FileSystemDocumentCommentReply {
    id
    body
    createdAt
    user {
      id
      name
    }
  }
`;
