import * as React from 'react';
import toReact from 'rehype-react';
import remarkBreaks from 'remark-breaks';
import externalLinks from 'remark-external-links';
import remarkGfm from 'remark-gfm';
import parse from 'remark-parse';
import toHtml from 'remark-rehype';
import { unified } from 'unified';

const mapChildren = children =>
  React.Children.map(children, child => {
    if (child.type === React.Fragment) {
      return mapChildren(child.props.children);
    }

    return child;
  });

export default markdown =>
  React.useMemo(() => {
    if (!markdown) {
      return null;
    }

    const { result } = unified()
      .use(parse)
      .use(remarkGfm)
      .use(remarkBreaks)
      .use(externalLinks, {
        target: '_blank',
        rel: ['noreferrer', 'noopener'],
      })
      .use(toHtml)
      .use(toReact, { Fragment: React.Fragment, createElement: React.createElement })
      .processSync(markdown);

    return mapChildren(result);
  }, [markdown]);
