import { gql } from '@apollo/client';
import { CreateSuggestionFragment } from './fragment';
export const MUTATION = gql `
  mutation createSuggestion(
    $suggestionId: UUID!
    $documentId: ID!
    $type: FileSystemDocumentSuggestionType!
    $data: JSON
    $attributes: String
    $subType: String
  ) {
    createSuggestion: createFileSystemDocumentSuggestion(
      input: {
        documentId: $documentId
        id: $suggestionId
        type: $type
        data: $data
        attributes: $attributes
        subType: $subType
      }
    ) {
      ...CreateSuggestionFragment
    }
  }
  ${CreateSuggestionFragment}
`;
