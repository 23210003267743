import { Fragment, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DecoupledEditor, Users, Autosave, Comments, BalloonToolbar } from 'pleditor';
import { useMobile } from 'shared/domains/common-ui';
import * as Sentry from 'shared/domains/sentry';
import { usePleditor } from './PleditorProvider';
import { CompanyLogo } from './components/CompanyLogo';
import { CriticalError } from './components/CriticalError';
import { DocumentTypeChangeModal } from './components/DocumentTypeChangeModal';
import { useIsLayoutReady } from './hooks/useIsLayoutReady';
import { useToastHandler } from './hooks/useToastHandler';
import { CollaboratorSession } from './plugins/CollaboratorSession';
import { CommentMentions } from './plugins/CommentMentions';
import { CommentsAdapter } from './plugins/CommentsAdapter';
import { CommentsUi } from './plugins/CommentsUi';
import { ComposedHighlightsSupport, ComposedHighlightsStyles, } from './plugins/ComposedHighlightsSupport';
import { DisableTableDragDrop } from './plugins/DisableTableDragDrop';
import { EditorDocumentState } from './plugins/EditorDocumentState';
import { HtmlAttributeSupport } from './plugins/HtmlAttributeSupport';
import { OffsetPlugin } from './plugins/OffsetPlugin';
import { autoSaveHandler } from './plugins/SaveHandler';
import { ToastHandler } from './plugins/ToastHandler';
import { TopToolbar } from './plugins/TopToolbar';
import { UserPermissions } from './plugins/UserPermissions';
import { UsersManager } from './plugins/UsersManager';
import { basePlugins } from './plugins/constants';
import { AppPleditorStyles, ExternalPleditorStyles, BasePleditorStyles, ComposedPleditorStyles, PleditorWrapper, PleditorContainer, PleditorSidebar, } from './styled';
export function BaseComposedEditor(props) {
    const { documentId, currentUser, data, toolbar, balloonToolbar, extraPlugins = [], versionNumber, usersWithAccess = [], usersWithoutAccess = [], usersExternal = [], } = props;
    const { setPleditor } = usePleditor();
    const editorRef = useRef(null);
    const commentSidebarElementRef = useRef(null);
    const editorContainerRef = useRef(null);
    const isLayoutReady = useIsLayoutReady();
    const [editorLoaded, setEditorLoaded] = useState(false);
    const isMobile = useMobile();
    const isExternal = process.env.WEB_CLIENT === 'external';
    useToastHandler();
    const plugins = [
        Autosave,
        EditorDocumentState,
        Users,
        UsersManager,
        UserPermissions,
        HtmlAttributeSupport,
        ComposedHighlightsSupport,
        Comments,
        CommentsUi,
        CommentMentions,
        CommentsAdapter,
        ToastHandler,
        OffsetPlugin,
        CollaboratorSession,
        DisableTableDragDrop,
        TopToolbar,
        BalloonToolbar,
        ...extraPlugins,
        ...basePlugins,
    ];
    const config = {
        licenseKey: process.env.PLEDITOR_LICENSE_KEY,
        documentId,
        initialVersion: versionNumber,
        currentUser,
        toolbar: Object.assign({ shouldNotGroupWhenFull: !isMobile }, toolbar),
        balloonToolbar,
        extraPlugins: plugins,
        sidebar: {
            container: commentSidebarElementRef.current,
            preventScrollOutOfView: true,
        },
        autosave: {
            save: autoSaveHandler,
        },
        comments: { editorConfig: {} },
        language: 'en',
        table: {
            defaultHeadings: { rows: 1 },
        },
    };
    return (<Fragment>
      <BasePleditorStyles />
      <ComposedPleditorStyles />
      {isExternal ? (<ExternalPleditorStyles />) : (<AppPleditorStyles $usersExternal={usersExternal} $usersWithoutAccess={usersWithoutAccess} $usersWithAccess={usersWithAccess}/>)}

      <CriticalError />
      <ComposedHighlightsStyles />
      <DocumentTypeChangeModal />
      <PleditorWrapper ref={editorContainerRef}>
        <PleditorContainer>
          {isLayoutReady && (<Fragment>
              <CompanyLogo sidebar={commentSidebarElementRef.current} container={editorContainerRef.current} editorLoaded={editorLoaded}/>
              <CKEditor editor={DecoupledEditor} config={config} data={data} onReady={editor => {
                editorRef.current = editor;
                setEditorLoaded(true);
                setPleditor(editor);
            }} onError={(error, details) => {
                var _a, _b;
                Sentry.captureException(error, { tags: { feature: 'pleditor' } });
                if (details === null || details === void 0 ? void 0 : details.willEditorRestart) {
                    (_b = (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.ui.view.toolbar.element) === null || _b === void 0 ? void 0 : _b.remove();
                }
            }}/>
            </Fragment>)}
          <PleditorSidebar ref={commentSidebarElementRef}/>
        </PleditorContainer>
      </PleditorWrapper>
    </Fragment>);
}
