import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getTemplateImage } from 'app/domains/templates/utils/getTemplateImage';
import { PreviewTabs } from './PreviewTabs';
import { useComposerData } from '../../../ComposerDataProvider';
import { useDraftPreview } from '../../DraftPreviewProvider';
import { Container, Content, Header, TitleContainer } from './styled';
export function RightColumn() {
    const { closeDialog } = useComposerData();
    const { composer, loading } = useDraftPreview();
    const { id, name } = composer;
    return (<Container>
      <Header $background={getTemplateImage(id)} $loading={loading}>
        <IconButton $round appearance="ghost" onClick={closeDialog}>
          <Falcon icon="xmark"/>
        </IconButton>
      </Header>
      <Content>
        <TitleContainer>
          <Typography $appearance="300" $fontSize="large" $fontWeight="semibold">
            {name}
          </Typography>
        </TitleContainer>
        <PreviewTabs />
      </Content>
    </Container>);
}
