var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql } from '@apollo/client';
const editCommentMutation = gql `
  mutation editComment($id: UUID!, $body: String) {
    updateFileSystemDocumentComment(id: $id, input: { body: $body }) {
      id
    }
  }
`;
const editCommentReplyMutation = gql `
  mutation editCommentReply($id: UUID!, $body: String!) {
    updateFileSystemDocumentCommentReply(id: $id, input: { body: $body }) {
      id
    }
  }
`;
export function editHeadComment(apiClient, commentData) {
    return __awaiter(this, void 0, void 0, function* () {
        const { id, content = '' } = commentData;
        const { data } = yield apiClient.mutate({
            mutation: editCommentMutation,
            variables: { id, body: content },
        });
        return data === null || data === void 0 ? void 0 : data.updateFileSystemDocumentComment;
    });
}
export function editReplyComment(apiClient, commentData) {
    return __awaiter(this, void 0, void 0, function* () {
        const { id, content = '' } = commentData;
        const { data } = yield apiClient.mutate({
            mutation: editCommentReplyMutation,
            variables: { id, body: content },
        });
        return data === null || data === void 0 ? void 0 : data.updateFileSystemDocumentCommentReply;
    });
}
