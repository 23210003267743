import { useSearchContext } from '../../SearchProvider';
export function useSimpleTextFilter(options) {
    const { paramKey, name } = options;
    const { setParamValue, isFilterActive, isFilterInDraft, removeFilterFromDraft, getSimpleTextFilterValue, } = useSearchContext();
    const value = getSimpleTextFilterValue(paramKey) || '';
    const clear = () => {
        setParamValue(paramKey, []);
        removeFilterFromDraft(paramKey);
    };
    const isActive = isFilterActive(paramKey);
    const isInDraft = isFilterInDraft(paramKey);
    const setText = (text) => {
        setParamValue(paramKey, [text]);
        removeFilterFromDraft(paramKey);
    };
    return { clear, value, isActive, name, isInDraft, setText };
}
