import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, Dialog, DialogContent, DialogFooter, DialogHeader, IconButton, Switch, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useActiveDocument, usePleditor } from 'shared/domains/documents';
import { Container, SwitchContainer } from './styled';
export function KeepRedlinesModal(props) {
    const { downloadFallback, onClose, onSubmit } = props;
    const { name: documentName } = useActiveDocument();
    const { pleditor, pleditorState } = usePleditor();
    const [keepRedlines, setKeepRedlines] = useState(false);
    const handleToggleKeepRedlines = (event) => setKeepRedlines(event.target.checked);
    const handleSubmit = () => {
        if (keepRedlines && pleditorState === 'initialized') {
            const exportConverterUrl = process.env.PLEDITOR_EXPORT_CONVERTER_URL;
            pleditor.commands.execute('exportWord', {
                fileName: `${documentName}.docx`,
                converterUrl: exportConverterUrl,
                // The default value of version changed from 1 to 2 in an upgrade which caused issues
                // since the URL we are using is for the V1 API. I first tried to change this to use
                // the V2 api but it seems like that is not available in our self hosted docx converter.
                // with this change docx export with comments works. It would be better to switch to the V2
                // API but that would require an upgrade of the self hosted docx converter.
                version: 1,
            });
        }
        else {
            downloadFallback();
        }
        onClose();
        onSubmit();
    };
    return (<Dialog size="sm" onClose={onClose}>
      <DialogHeader title={t({
            comment: 'Include document redlines modal - modal title',
            message: 'Document redlines and comments',
        })} action={<IconButton $round appearance="ghost" onClick={onClose}>
            <Falcon icon="xmark"/>
          </IconButton>}/>
      <DialogContent>
        <Box flexDirection="column" gap="xl">
          <Typography>
            <Trans comment="Include document redlines modal - modal description">
              If there are redlines and comments added to this document you can include them in the
              download.
            </Trans>
          </Typography>
          <Container>
            <Box flexDirection="column">
              <Typography $fontWeight="medium" $appearance="300">
                <Trans comment="Include document redlines modal - include redlines switch title">
                  Include redlines and comments
                </Trans>
              </Typography>
              <Typography $fontSize="small">
                <Trans comment="Include document redlines modal - include redlines switch description">
                  Enable if you wish to keep redlines and comments in your downloaded document.
                </Trans>
              </Typography>
            </Box>
            <SwitchContainer>
              <Switch checked={keepRedlines} onChange={handleToggleKeepRedlines}/>
            </SwitchContainer>
          </Container>
        </Box>
      </DialogContent>
      <DialogFooter>
        <Button appearance="primary" disabled={pleditorState === 'initializing'} onClick={handleSubmit}>
          <Trans comment="Include document redlines modal - download button label">Download</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
