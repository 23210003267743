export function getBookDemoUrlByMarket(code) {
    switch (code) {
        case 'se':
        case 'uk':
        case 'no':
        case 'de':
        default:
            return `${process.env.WEBSITE_URL}/contact-sales`;
    }
}
