import { Plugin } from 'pleditor';
import { assistantCache } from 'app/domains/assistant';
import { AssistantSelection } from './AssistantSelection';
/**
 * This plugin handles communication between the `Assistant` and `Pleditor`
 *
 * Example: We listen for assistant suggestions and update the corresponding content in the document
 */
export class Assistant extends Plugin {
    constructor() {
        super(...arguments);
        this.init = () => {
            assistantCache.onEvent('assistant:on-accept-suggestion', this.onAssistantSuggestion);
            assistantCache.onUpdate(['selectionRange'], this.onAssistantSelectionRangeUpdate);
        };
        this.onAssistantSelectionRangeUpdate = (assistant) => {
            const { selectionRange } = assistant;
            if (!selectionRange) {
                this.editor.execute('AssistantSelectionHighlight', { onlyRemove: true });
            }
        };
        this.onAssistantSuggestion = (assistant) => {
            const { suggestionText, selectionRange } = assistant;
            if (suggestionText && selectionRange) {
                this.insertText(selectionRange, suggestionText);
                assistantCache.update({
                    selectionRange: null,
                    selectionText: '',
                    suggestionText: '',
                });
            }
        };
        this.insertText = (range, text) => {
            const { model } = this.editor;
            if (range.isCollapsed) {
                return;
            }
            model.change(writer => {
                const selection = writer.createSelection(range);
                const textNode = writer.createText(text);
                model.insertContent(textNode, selection);
            });
        };
        this.destroy = () => {
            assistantCache.off(this.onAssistantSuggestion);
            assistantCache.off(this.onAssistantSelectionRangeUpdate);
        };
    }
    static get pluginName() {
        return 'Assistant';
    }
    static get requires() {
        return [AssistantSelection];
    }
}
