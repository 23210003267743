import { DialogContent, themeGetColor, unit } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const COLLAPSED_DESCRIPTION_HEIGHT = 144;
const fadedTextStyles = css `
  &::before {
    content: '';
    width: 100%;
    height: ${unit(16)};
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      ${themeGetColor('background.50', 1.0)} 0%,
      ${themeGetColor('background.50', 0.0)} 100%
    );
  }
`;
export const ExpandableContainer = styled.div `
  position: relative;
  overflow: hidden;
  max-height: ${props => props.$showExpandButton && !props.$expanded && unit(COLLAPSED_DESCRIPTION_HEIGHT / 4)};
  ${props => props.$showExpandButton && !props.$expanded && fadedTextStyles};
`;
export const StyledDialogContent = styled(DialogContent) `
  padding-bottom: ${props => props.$noPaddingBottom && 0};
`;
