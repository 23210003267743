var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { QUERY } from './query';
export const useTemplateApprovers = (composerId) => {
    var _a, _b;
    const _c = useQuery(QUERY, {
        variables: { composerId },
    }), { data } = _c, rest = __rest(_c, ["data"]);
    const approvers = ((_b = (_a = data === null || data === void 0 ? void 0 : data.composer) === null || _a === void 0 ? void 0 : _a.approval) === null || _b === void 0 ? void 0 : _b.approvers) || [];
    return Object.assign(Object.assign({}, rest), { approvers });
};
