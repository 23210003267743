import { select, t } from '@lingui/macro';
import { AvatarWithLabel, AvatarLetter, List } from '@pocketlaw/tetris';
import { Empty } from './Empty';
import { Error } from './Error';
import { Loading } from './Loading';
import { useUsersAccessByComposers } from '../../hooks/useUsersAccessByComposers';
import { AccessMenuButton } from '../AccessMenuButton';
import { UpdateAccessMenuItem } from '../UpdateAccessMenuItem';
import { StyledListItem } from './styled';
export function UserAccessList(props) {
    const { users, accessDropdownPermission } = props;
    const { loading, error } = useUsersAccessByComposers();
    const canAddPermission = accessDropdownPermission === 'noAccess' || accessDropdownPermission === 'partialAccess';
    const canRemovePermission = accessDropdownPermission === 'access' || accessDropdownPermission === 'partialAccess';
    if (error) {
        return <Error />;
    }
    if (loading) {
        return <Loading />;
    }
    if (users.length < 1) {
        return <Empty accessDropdownPermission={accessDropdownPermission}/>;
    }
    return (<List $density="large">
      {users.map(({ id, name, role }) => {
            const FirstLetter = name.charAt(0);
            return (<StyledListItem key={id}>
            <AvatarWithLabel size="md" title={name} subtitle={t({
                    comment: 'user role',
                    message: select(role, {
                        owner: 'Owner',
                        member: 'User',
                        other: 'User',
                    }),
                })}>
              <AvatarLetter>{FirstLetter}</AvatarLetter>
            </AvatarWithLabel>
            {role !== 'owner' && (<AccessMenuButton>
                {canAddPermission && <UpdateAccessMenuItem type="add" userIds={[id]}/>}
                {canRemovePermission && <UpdateAccessMenuItem type="remove" userIds={[id]}/>}
              </AccessMenuButton>)}
          </StyledListItem>);
        })}
    </List>);
}
