import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter, } from '../SingleSelectFilter';
export function GoverningLawFilter() {
    var _a;
    const allowedValues = ((_a = metadataConfig.get('GoverningLaw')) === null || _a === void 0 ? void 0 : _a.allowedValues) || [];
    const filter = useSingleSelectFilter({
        paramKey: 'governingLaw',
        items: allowedValues.reduce((result, current) => {
            const { text, value, groupValues } = current;
            result.push({ id: value, title: text });
            if (groupValues === null || groupValues === void 0 ? void 0 : groupValues.length) {
                result.push(...groupValues.map(item => ({ id: item.value, title: item.text })));
            }
            return result;
        }, []),
        name: searchFilterCopy().governingLaw,
    });
    return <SingleSelectFilter filter={filter}/>;
}
