var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-console */
import { t } from '@lingui/macro';
import { v4 as uuid } from 'uuid';
import { addComment } from './addComment';
import { addCommentThread } from './addCommentThread';
import { deleteHeadComment, deleteReplyComment } from './deleteComment';
import { editHeadComment, editReplyComment } from './editComment';
import { getCommentThread } from './getCommentThread';
import { getData } from './getData';
import { reopenCommentThread } from './reopenCommentThread';
import { resolveCommentThread } from './resolveCommentThread';
import { PleditorPlugin } from '../PleditorPlugin';
import { UsersManager } from '../UsersManager';
export class CommentsAdapter extends PleditorPlugin {
    static get requires() {
        return ['UsersManager', 'CommentsRepository'];
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchData();
            this.useRemoveThreadPermission();
            this.useCustomUUID();
            this.useAdapter();
        });
    }
    fetchData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { apiConnector, documentId } = this;
            const { users } = yield getData(apiConnector, documentId);
            this.addUsers(users);
        });
    }
    addUsers(users) {
        const usersManager = this.editor.plugins.get(UsersManager);
        users.forEach(user => usersManager.addUser(user));
    }
    useRemoveThreadPermission() {
        const commentsRepository = this.editor.plugins.get('CommentsRepository');
        const notificationPlugin = this.editor.plugins.get('Notification');
        commentsRepository.on('removeCommentThread', (ev, data) => {
            const usersManager = this.editor.plugins.get(UsersManager);
            const currentUser = usersManager.getCurrentUser();
            const thread = commentsRepository.getCommentThread(data.threadId);
            const [firstComment] = (thread === null || thread === void 0 ? void 0 : thread.comments) || [];
            if (!firstComment) {
                return;
            }
            if (firstComment.author.id !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
                ev.stop();
                notificationPlugin.showWarning(t({
                    message: 'You can not remove another users thread',
                    comment: 'Error message when removing another users thread comment',
                }));
            }
        }, { priority: 'highest' });
    }
    useCustomUUID() {
        const commentsRepository = this.editor.plugins.get('CommentsRepository');
        /**
         * Before a comment is created, pre-generate a new ID that will be used
         * to send to the backend.
         */
        commentsRepository.on('addComment', (evt, data) => __awaiter(this, void 0, void 0, function* () {
            const { threadId } = data;
            const thread = commentsRepository.getCommentThread(threadId);
            const { comments } = (thread === null || thread === void 0 ? void 0 : thread.toJSON()) || {};
            const isNewThread = Boolean(!(comments === null || comments === void 0 ? void 0 : comments.length));
            /**
             * If we are currently creating a new thread, the comment needs
             * to have the same id as the thread.
             * Otherwise lets create a new unique UUID.
             */
            if (isNewThread) {
                // eslint-disable-next-line no-param-reassign
                data.commentId = data.threadId;
            }
            else {
                const newCommentId = uuid();
                // eslint-disable-next-line no-param-reassign
                data.commentId = newCommentId;
            }
        }), { priority: 'highest' });
    }
    useAdapter() {
        const { apiConnector } = this;
        const commentsRepository = this.editor.plugins.get('CommentsRepository');
        const notificationPlugin = this.editor.plugins.get('Notification');
        commentsRepository.adapter = {
            addComment: (data) => __awaiter(this, void 0, void 0, function* () {
                const { commentId, threadId, content } = data;
                try {
                    const comment = yield addComment(apiConnector, {
                        id: commentId,
                        threadId,
                        content,
                    });
                    return comment;
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while adding new comment',
                        comment: 'Error message while adding comment reply',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            updateComment: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId, commentId, content } = data;
                /**
                 * In general we have to avoid saving empty comments otherwise editor complains when it tries to fetch and render empty comments
                 *
                 * This if check was introduced because the updateComment adapter function gets call with empty content when you change attributes of commentModel using setAttribute.
                 * we are using these attributes in FE only for showing errors. and we don't need to save them in backend.
                 */
                if (!content)
                    return;
                try {
                    if (threadId === commentId) {
                        yield editHeadComment(apiConnector, {
                            id: data.threadId,
                            content,
                        });
                        return;
                    }
                    yield editReplyComment(apiConnector, {
                        id: commentId,
                        content,
                    });
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while updating the comment',
                        comment: 'Error message while updating the comment',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            removeComment: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId, commentId } = data;
                try {
                    if (threadId === commentId) {
                        yield deleteHeadComment(apiConnector, threadId);
                        return;
                    }
                    yield deleteReplyComment(apiConnector, commentId);
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while deleting the comment',
                        comment: 'Error message while deleting the comment',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            addCommentThread: (data) => __awaiter(this, void 0, void 0, function* () {
                const { comments, attributes, threadId } = data;
                try {
                    const documentId = this.editor.config.get('documentId');
                    const thread = yield addCommentThread(apiConnector, {
                        id: threadId,
                        anchorId: attributes === null || attributes === void 0 ? void 0 : attributes.anchorId,
                        body: comments[0].content,
                        documentId,
                    });
                    return thread;
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while adding new comment thread',
                        comment: 'Error message while adding new comment',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            getCommentThread: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId } = data;
                try {
                    const { thread, users } = yield getCommentThread(apiConnector, { threadId });
                    this.addUsers(users);
                    return thread;
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while getting the comment thread',
                        comment: 'Error message while getting the comment thread',
                    }));
                    throw error;
                }
            }),
            updateCommentThread(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    console.log('Comment thread updated', data);
                });
            },
            resolveCommentThread: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId } = data;
                try {
                    const response = yield resolveCommentThread(apiConnector, threadId);
                    return {
                        threadId: (response === null || response === void 0 ? void 0 : response.id) || '',
                        resolvedAt: (response === null || response === void 0 ? void 0 : response.resolvedAt) ? new Date(response.resolvedAt) : new Date(),
                        resolvedBy: (response === null || response === void 0 ? void 0 : response.resolvedBy) || '',
                    };
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while resolving the comment thread',
                        comment: 'Error message while resolving the comment thread',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            reopenCommentThread: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId } = data;
                try {
                    yield reopenCommentThread(apiConnector, threadId);
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while reopening comment thread',
                        comment: 'Error message while reopening the comment thread',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            removeCommentThread: (data) => __awaiter(this, void 0, void 0, function* () {
                const { threadId } = data;
                try {
                    yield deleteHeadComment(apiConnector, threadId);
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while deleting comment thread',
                        comment: 'Error message while deleting the comment thread',
                    }));
                    // Let editor crash. watchdog will handle the error but it won't revert the removed thread.
                    throw error;
                }
            }),
        };
    }
}
