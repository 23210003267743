import { gql } from '@apollo/client';
export const query = gql `
  query GetExternalUsers($documentId: String!) {
    invites: externalSharingInvites(where: { fileSystemDocumentId: { eq: $documentId } }) {
      id
      invitee {
        id
        name
      }
    }
  }
`;
