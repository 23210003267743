import { t } from '@lingui/macro';
import { localeConfig } from 'shared/domains/languages/config';
function msToMinutes(ms) {
    if (ms != null) {
        return Math.round(ms / 1000 / 60);
    }
    return undefined;
}
export function transformData(data) {
    var _a, _b, _c, _d;
    const { plans = [], company, composer } = data || {};
    const { templates, composerPlans } = composer || {};
    const [composerMetadata] = (composer === null || composer === void 0 ? void 0 : composer.metadata) || [];
    const [categoryMetadata] = ((_a = composer === null || composer === void 0 ? void 0 : composer.category) === null || _a === void 0 ? void 0 : _a.metadata) || [];
    const currentPlan = (_c = (_b = company === null || company === void 0 ? void 0 : company.plans) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.plan;
    const [firstTemplate] = templates || [];
    const previewFiles = (firstTemplate === null || firstTemplate === void 0 ? void 0 : firstTemplate.previewFiles) || {};
    const composerPlansData = (composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) || [];
    const locked = Boolean(composerPlans) && !composerPlansData.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id));
    const fallbackDescription = t({
        message: 'No description available for this template',
        comment: 'Fallback description for composer when displaying information about template before creating a draft.',
    });
    const fallbackName = t({
        message: 'Untitled',
        comment: 'Fallback name for composer  when displaying information about template before creating a draft.',
    });
    const transformedTemplates = templates === null || templates === void 0 ? void 0 : templates.map(template => {
        var _a, _b;
        return ({
            id: template.id,
            name: localeConfig.getLocale({ code: (_a = template.marketLocale) === null || _a === void 0 ? void 0 : _a.locale }).getName(),
            languageCode: ((_b = template.marketLocale) === null || _b === void 0 ? void 0 : _b.code) || '',
            latestVersion: template.latestVersion,
        });
    });
    return {
        plans,
        currentPlan,
        composer: Object.assign(Object.assign({}, composer), { name: (composerMetadata === null || composerMetadata === void 0 ? void 0 : composerMetadata.name) || fallbackName, description: (composerMetadata === null || composerMetadata === void 0 ? void 0 : composerMetadata.description) || fallbackDescription }),
        category: {
            themeId: (_d = composer === null || composer === void 0 ? void 0 : composer.category) === null || _d === void 0 ? void 0 : _d.themeId,
            title: categoryMetadata === null || categoryMetadata === void 0 ? void 0 : categoryMetadata.title,
        },
        marketCode: composer === null || composer === void 0 ? void 0 : composer.marketCode,
        templates: transformedTemplates || [],
        estimatedTime: msToMinutes(composer === null || composer === void 0 ? void 0 : composer.estimatedTime),
        composerPlans,
        previewFiles,
        locked,
    };
}
