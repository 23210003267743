import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function DiscountFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'discount',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().discount,
    });
    return <SingleSelectFilter filter={filter}/>;
}
