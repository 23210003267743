var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { DE } from 'shared/domains/languages/components/FlagGlyphs/DE';
import { NO } from 'shared/domains/languages/components/FlagGlyphs/NO';
import { SE } from 'shared/domains/languages/components/FlagGlyphs/SE';
import { UK } from 'shared/domains/languages/components/FlagGlyphs/UK';
import { loadTranslations } from './loadTranslations';
import { makeConfig } from './makeConfig';
export const localeConfig = makeConfig();
localeConfig.addLocale({
    code: 'sv-se',
    languageCode: 'sv',
    getName: () => t({
        message: 'Swedish',
        id: 'language_config_sv-se',
        comment: 'Name of SV-SE language in config',
    }),
    getNameInLocalLanguage: () => t({
        comment: 'This translation should always be in swedish',
        id: 'swedish_in_swedish',
        message: `Svenska`,
    }),
    flag: SE,
});
localeConfig.addLocale({
    code: 'en-gb',
    languageCode: 'en',
    getName: () => t({
        message: 'English',
        id: 'language_config_en-gb',
        comment: 'Name of EN-GB language in config',
    }),
    getNameInLocalLanguage: () => t({
        comment: 'This translation should always be in english',
        id: 'english_in_english',
        message: `English`,
    }),
    flag: UK,
});
localeConfig.addLocale({
    code: 'nb-no',
    languageCode: 'nb',
    getName: () => t({
        message: 'Norwegian',
        id: 'language_config_nb-no',
        comment: 'Name of NB-NO language in config',
    }),
    getNameInLocalLanguage: () => t({
        comment: 'This translation should always be in norwegian',
        id: 'norwegian_in_norwegian',
        message: `Norwegian`,
    }),
    flag: NO,
});
localeConfig.addLocale({
    code: 'de-de',
    languageCode: 'de',
    getName: () => t({ message: 'German', id: 'language_config_de', comment: 'Name of DE language in config' }),
    getNameInLocalLanguage: () => t({
        comment: 'This translation should always be in german',
        id: 'german_in_german',
        message: `Deutsche`,
    }),
    flag: DE,
});
const svSe = localeConfig.getLocale({ code: 'sv-se' });
const enGb = localeConfig.getLocale({ code: 'en-gb' });
const nbNo = localeConfig.getLocale({ code: 'nb-no' });
const de = localeConfig.getLocale({ code: 'de-de' });
// Default  the locale config to en-gb. This is the global fallback language
localeConfig.setActiveLocale(enGb === null || enGb === void 0 ? void 0 : enGb.code);
// Set and expose en-gb as global fallback language
export const defaultLocale = enGb;
const devLocales = [svSe, enGb, nbNo, de];
const productionLocales = [svSe, enGb, de];
/**
 * This should not take the market into account, because only app has markets
 * Use `getCurrentMarket().marketLocales` if you want to get locales for a specific market
 */
export const supportedLocales = process.env.APP_ENV === 'production' ? productionLocales : devLocales;
/**
 * This should run BEFORE the app is rendered
 * currentLocale is not guaranteed to re-render React components automatically
 * If app has already rendered you should reload the page when changing currentLocale
 */
export function dangerouslySetCurrentLocale(localeCode) {
    return __awaiter(this, void 0, void 0, function* () {
        const locale = localeConfig.getLocale({ code: localeCode });
        localeConfig.setActiveLocale(locale === null || locale === void 0 ? void 0 : locale.code);
        const chosenLocaleWithFallback = locale || defaultLocale;
        yield loadTranslations(chosenLocaleWithFallback);
    });
}
