import { gql } from '@apollo/client';
export const EditorCommentReplyFragment = gql `
  fragment EditorCommentReplyFragment on FileSystemDocumentCommentReply {
    id
    body
    createdAt
    lastEditAt
    user {
      id
      name
    }
  }
`;
export const EditorCommentFragment = gql `
  fragment EditorCommentFragment on FileSystemDocumentComment {
    id
    body
    resolved: isResolved
    createdAt
    anchorId
    lastEditAt
    user {
      id
      name
    }
    replies {
      ...EditorCommentReplyFragment
    }
  }
  ${EditorCommentReplyFragment}
`;
