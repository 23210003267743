import { gql } from '@apollo/client';
export const query = gql `
  query GetComposedDocument($id: ID!) {
    document: fileSystemDocument(id: $id) {
      id
      htmlBody
      versionNumber
    }
  }
`;
