export function buildNumberRangeFilter(range) {
    const { from, to } = range;
    const hasFromValue = from !== undefined;
    const hasToValue = to !== undefined;
    if (!hasFromValue && !hasToValue) {
        return undefined;
    }
    const filter = {};
    if (hasFromValue && from >= 0) {
        Object.assign(filter, {
            gte: from,
        });
    }
    if (hasToValue && to >= 0) {
        Object.assign(filter, {
            lte: to,
        });
    }
    return filter;
}
