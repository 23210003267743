var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Loader, Menu, MenuBody, MenuFooter, MenuHeader, MenuItem, MenuList, Radio, useToast, } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { getCurrentMarket } from 'app/domains/markets/config';
import { useCreateDraft } from 'app/domains/templates';
import { Flag } from 'shared/domains/languages';
import * as Sentry from 'shared/domains/sentry';
import { useGtm } from 'shared/domains/tracking';
import { useComposer } from './useComposer';
import { AlignRight } from './styled';
export function CreateButton(props) {
    var _a;
    const { composerId, disabled } = props;
    const { getComposer, loading, data } = useComposer(composerId);
    const [creatingDraft, setCreatingDraft] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const createDraft = useCreateDraft();
    const { addToast } = useToast();
    const { push: gtmPush } = useGtm();
    const history = useHistory();
    const templates = ((_a = data === null || data === void 0 ? void 0 : data.composer) === null || _a === void 0 ? void 0 : _a.templates) || [];
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const currentMarket = getCurrentMarket();
    const handleConfirmLanguage = (composer, template) => __awaiter(this, void 0, void 0, function* () {
        var _b, _c;
        setCreatingDraft(true);
        const version = template === null || template === void 0 ? void 0 : template.latestVersion;
        try {
            const { data: createDraftData } = yield createDraft.mutate({
                composerId,
                templateId: template === null || template === void 0 ? void 0 : template.id,
                version: (_b = composer === null || composer === void 0 ? void 0 : composer.tree) === null || _b === void 0 ? void 0 : _b.version,
                templateVersionTemplateId: version === null || version === void 0 ? void 0 : version.templateId,
                templateVersionReference: version === null || version === void 0 ? void 0 : version.reference,
            });
            gtmPush({
                event: 'template_start',
                form: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id,
            });
            const { id } = ((_c = createDraftData === null || createDraftData === void 0 ? void 0 : createDraftData.created) === null || _c === void 0 ? void 0 : _c.returning[0]) || {};
            const newId = id || '';
            history.push(`/draft/${newId}`);
        }
        catch (_d) {
            Sentry.captureException('Failed to create draft');
            addToast({
                appearance: 'warning',
                title: t({
                    message: 'An error occurred',
                    comment: 'Error message displayed in composer/template dialog when creating a draft',
                }),
            });
            setCreatingDraft(false);
        }
    });
    const fetchLanguages = () => __awaiter(this, void 0, void 0, function* () {
        var _e, _f, _g;
        try {
            const result = yield getComposer();
            const availableTemplates = ((_f = (_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.composer) === null || _f === void 0 ? void 0 : _f.templates) || [];
            if (availableTemplates.length === 0) {
                throw new Error('No templates found for this composer.');
            }
            if (availableTemplates.length === 1) {
                // No need to let user "choose" language if there is only one language available, create draft right away
                handleConfirmLanguage((_g = result === null || result === void 0 ? void 0 : result.data) === null || _g === void 0 ? void 0 : _g.composer, availableTemplates[0]);
            }
            else {
                setLanguageMenuOpen(true);
            }
        }
        catch (error) {
            Sentry.captureException(error);
            addToast({
                appearance: 'warning',
                title: t({
                    message: 'An error occurred',
                    comment: 'Error message displayed in composer/template dialog when creating a draft',
                }),
            });
        }
    });
    return (<Menu target={<Button disabled={disabled || loading} type="button" appearance="primary" onClick={() => fetchLanguages()} $stretch size="small">
          <Trans comment="template card hover button: create template">Use</Trans>
          {loading && <Loader />}
        </Button>} onClose={() => setLanguageMenuOpen(false)} open={languageMenuOpen && templates.length > 1}>
      <MenuHeader title={t({
            message: 'Choose language',
            comment: 'template card: create-button popover menu title',
        })}/>
      <MenuBody>
        <MenuList as="div">
          {templates.map(template => {
            const languageCode = template.marketLocale.code;
            const locale = currentMarket.templateLocales.find(templateLocale => templateLocale.languageCode === languageCode);
            return (<MenuItem key={template.id} as="label">
                <Flag languageCode={languageCode}/>
                {locale === null || locale === void 0 ? void 0 : locale.getName()}
                <AlignRight>
                  <Radio name="language" checked={template.id === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id)} onChange={event => event.target.checked && setSelectedTemplate(template)}/>
                </AlignRight>
              </MenuItem>);
        })}
        </MenuList>
      </MenuBody>
      <MenuFooter>
        <Button $stretch size="small" appearance="primary" onClick={() => handleConfirmLanguage(data === null || data === void 0 ? void 0 : data.composer, selectedTemplate)} disabled={!selectedTemplate || creatingDraft}>
          <Trans comment="template card: create-button popover confirm button">Create</Trans>
          {creatingDraft && <Loader />}
        </Button>
      </MenuFooter>
    </Menu>);
}
