import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function NoTemplatesAccess() {
    return (<Container>
      <AvatarWithLabel alignment="vertical" shape="square" size="2xl" title={t({
            comment: 'Tasks attachment templates title - when no templates are available',
            message: 'No templates found.',
        })} subtitle={t({
            comment: 'Tasks attachment templates subtitle - when no templates are available',
            message: 'Talk to a company owner to get access to templates.',
        })}>
        <Falcon icon="inbox"/>
      </AvatarWithLabel>
    </Container>);
}
