import { t } from '@lingui/macro';
import { Access, ExternalSharing, Metadata, Tasks, Approval, ESigning } from './components';
export const getTitle = (settingsKey) => {
    switch (settingsKey) {
        case 'tasks':
            return t({
                comment: 'Document settings list item - Tasks title',
                message: 'Tasks',
            });
        case 'access':
            return t({
                comment: 'Document settings list item - Access title',
                message: 'Access',
            });
        case 'metadata':
            return t({
                comment: 'Document settings list item - Metadata title',
                message: 'Metadata',
            });
        case 'external-sharing':
            return t({
                comment: 'Document settings list item - External sharing title',
                message: 'External sharing',
            });
        case 'approval':
            return t({
                comment: 'Document settings list item - Approval title',
                message: 'Approval',
            });
        case 'esigning':
            return t({
                comment: 'Document settings list item - Esigning title',
                message: 'eSigning',
            });
        default:
            return '';
    }
};
export const getSubtitle = (settingsKey) => {
    switch (settingsKey) {
        case 'tasks':
            return t({
                comment: 'Document settings list item - Tasks subtitle',
                message: 'Add tasks and reminders',
            });
        case 'access':
            return t({
                comment: 'Document settings list item - Access subtitle',
                message: 'Manage user access',
            });
        case 'metadata':
            return t({
                comment: 'Document settings list item - Metadata subtitle',
                message: 'Document details',
            });
        case 'external-sharing':
            return t({
                comment: 'Document settings list item - External sharing subtitle',
                message: 'Share with external parties',
            });
        case 'approval':
            return t({
                comment: 'Document settings list item - Approval subtitle',
                message: 'Ask a colleague for approval',
            });
        case 'esigning':
            return t({
                comment: 'Document settings list item - Esigning subtitle',
                message: 'Send this contract for signing',
            });
        default:
            return undefined;
    }
};
export const getComponent = (settingsKey) => {
    switch (settingsKey) {
        case 'tasks':
            return Tasks;
        case 'access':
            return Access;
        case 'metadata':
            return Metadata;
        case 'external-sharing':
            return ExternalSharing;
        case 'approval':
            return Approval;
        case 'esigning':
            return ESigning;
        default:
            return null;
    }
};
