import { useReactiveVar } from '@apollo/client';
import { Tooltip, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { documentState, usePreview } from 'shared/domains/documents';
import { getText } from './getText';
import { Container, IconContainer } from './styled';
export function DocumentSaveIndicator() {
    const { isFreeText } = usePreview();
    const state = useReactiveVar(documentState);
    const saved = !state || state === 'synchronized';
    const saving = state === 'saving' || state === 'synchronizing';
    const unsavedChanges = state === 'waiting';
    const error = state === 'error';
    const { label, tooltip } = getText(state);
    if (!isFreeText) {
        return null;
    }
    return (<Tooltip position="bottom" title={tooltip}>
      <Container>
        <IconContainer $state={state}>
          {saved && <Falcon $size={12} icon="check"/>}
          {saving && <Falcon $size={12} icon="arrows-rotate"/>}
          {unsavedChanges && <Falcon $size={12} icon="check"/>}
          {error && <Falcon $size={12} icon="xmark"/>}
        </IconContainer>
        {state && !saved && (<Typography $lineHeight="neutralized" $appearance="100" $fontSize="small">
            {label}
          </Typography>)}
      </Container>
    </Tooltip>);
}
