import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function ProbationaryPeriodFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'probationaryPeriod',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().probationaryPeriod,
    });
    return <SingleSelectFilter filter={filter}/>;
}
