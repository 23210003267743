import { gql } from '@apollo/client';
export const addMutation = gql `
  mutation addComposerPermissions($composerIds: [Unstable__UUID!]!, $userIds: [String!]!) {
    unstable__upsertComposerPermissions(
      input: [READ]
      where: { composerIds: $composerIds, userIds: $userIds }
    ) {
      id
    }
  }
`;
