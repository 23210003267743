import { t } from '@lingui/macro';
import { FileSystemDocumentStatus, SearchEntityTypeEnum, } from 'shared/domains/apollo/generated/types';
export function isDocument(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return true;
        default:
            return false;
    }
}
export function getStatusData(value) {
    switch (value) {
        case FileSystemDocumentStatus.AgreedStage:
            return {
                value: FileSystemDocumentStatus.AgreedStage,
                label: t({ message: 'AgreedStage', comment: 'Global search: Document status label text' }),
                appearance: 'success',
            };
        case FileSystemDocumentStatus.AgreedStageAgreedForm:
            return {
                value: FileSystemDocumentStatus.AgreedStageAgreedForm,
                label: t({
                    message: 'AgreedStageAgreedForm',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'success',
            };
        case FileSystemDocumentStatus.DraftStage:
            return {
                value: FileSystemDocumentStatus.DraftStage,
                label: t({ message: 'DraftStage', comment: 'Global search: Document status label text' }),
                appearance: 'secondary',
            };
        case FileSystemDocumentStatus.Inconsistent:
            return {
                value: FileSystemDocumentStatus.Inconsistent,
                label: t({ message: 'Inconsistent', comment: 'Global search: Document status label text' }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.ReviewStage:
            return {
                value: FileSystemDocumentStatus.ReviewStage,
                label: t({ message: 'ReviewStage', comment: 'Global search: Document status label text' }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.ReviewStageApproved:
            return {
                value: FileSystemDocumentStatus.ReviewStageApproved,
                label: t({
                    message: 'ReviewStageApproved',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'success',
            };
        case FileSystemDocumentStatus.ReviewStageCancelled:
            return {
                value: FileSystemDocumentStatus.ReviewStageCancelled,
                label: t({
                    message: 'ReviewStageCancelled',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.ReviewStageRejected:
            return {
                value: FileSystemDocumentStatus.ReviewStageRejected,
                label: t({
                    message: 'ReviewStageRejected',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.ReviewStagePending:
            return {
                value: FileSystemDocumentStatus.ReviewStagePending,
                label: t({
                    message: 'ReviewStagePending',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.SigningStage:
            return {
                value: FileSystemDocumentStatus.SigningStage,
                label: t({ message: 'SigningStage', comment: 'Global search: Document status label text' }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.SigningStageCancelled:
            return {
                value: FileSystemDocumentStatus.SigningStageCancelled,
                label: t({
                    message: 'SigningStageCancelled',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.SigningStageFailed:
            return {
                value: FileSystemDocumentStatus.SigningStageFailed,
                label: t({
                    message: 'SigningStageFailed',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.SigningStagePending:
            return {
                value: FileSystemDocumentStatus.SigningStagePending,
                label: t({
                    message: 'SigningStagePending',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.SigningStageRejected:
            return {
                value: FileSystemDocumentStatus.SigningStageRejected,
                label: t({
                    message: 'SigningStageRejected',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'danger',
            };
        case FileSystemDocumentStatus.SigningStageSigned:
            return {
                value: FileSystemDocumentStatus.SigningStageSigned,
                label: t({
                    message: 'SigningStageSigned',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'success',
            };
        case FileSystemDocumentStatus.SigningStagePendingNotYetSigned:
            return {
                value: FileSystemDocumentStatus.SigningStagePendingNotYetSigned,
                label: t({
                    message: 'SigningStagePendingNotYetSigned',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.SigningStagePendingPartiallySigned:
            return {
                value: FileSystemDocumentStatus.SigningStagePendingPartiallySigned,
                label: t({
                    message: 'SigningStagePendingPartiallySigned',
                    comment: 'Global search: Document status label text',
                }),
                appearance: 'info',
            };
        case FileSystemDocumentStatus.Unknown:
        default:
            return {
                value: FileSystemDocumentStatus.Unknown,
                label: t({ message: 'Unknown', comment: 'Global search: Document status label text' }),
                appearance: 'warning',
            };
    }
}
