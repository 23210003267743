import { useQuery } from '@apollo/client';
import { getDocumentTypeQuery } from './query';
import { useActiveDocument } from '../../../../../hooks/usePreview';
export const useGetDocumentType = () => {
    var _a;
    const { id: documentId } = useActiveDocument();
    const { data } = useQuery(getDocumentTypeQuery, { variables: { documentId }, fetchPolicy: 'no-cache' });
    const type = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.type) || undefined;
    return { type };
};
