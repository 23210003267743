import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function AutomaticRenewalFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'automaticRenewal',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().automaticRenewal,
    });
    return <SingleSelectFilter filter={filter}/>;
}
