import { t } from '@lingui/macro';
import { object, lazy, string, array, boolean } from 'yup';
export const validationSchema = object({
    value: lazy(value => {
        const requiredText = t({
            message: 'Please provide a value',
            comment: 'Generic validation message when no data is provided for document metadata field',
        });
        const arraySchema = array().of(string()).required(requiredText);
        const stringSchema = string().required(requiredText);
        const booleanSchema = boolean().required(requiredText);
        if (Array.isArray(value)) {
            return arraySchema;
        }
        if (typeof value === 'boolean') {
            return booleanSchema;
        }
        return stringSchema;
    }),
});
