import { t } from '@lingui/macro';
import { ConfirmDialog } from '@pocketlaw/tetris';
export function ConfirmDeleteDialog(props) {
    const { onCancel, onConfirm } = props;
    const heading = t({
        comment: 'Remove uploaded attachment confirm dialog - title',
        message: 'Remove attachment',
    });
    const confirm = t({
        comment: 'Remove uploaded attachment confirm dialog - confirm button label',
        message: 'Remove',
    });
    const cancel = t({
        comment: 'Remove uploaded attachment confirm dialog - cancel button label',
        message: 'Cancel',
    });
    const message = t({
        comment: 'Remove uploaded attachment confirm dialog - message',
        message: 'Are you sure you want to remove this attached document?',
    });
    return (<ConfirmDialog size="sm" appearance="danger" heading={heading} confirm={confirm} cancel={cancel} onConfirm={onConfirm} onCancel={onCancel}>
      {message}
    </ConfirmDialog>);
}
