import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs, Tab } from '@pocketlaw/tetris';
import { TabsContainer, StyledTabContent, StyledTabNav } from './styled';
import { EsignFormTabs } from './types';
export function ESignTabs(props) {
    const { parties, attachments, settings } = props;
    const [activeTab, setActiveTab] = useState(EsignFormTabs.Parties);
    const handleSelectTab = (tab) => {
        setActiveTab(tab);
    };
    return (<TabsContainer>
      <Tabs activeTab={activeTab} onSelect={handleSelectTab}>
        <StyledTabNav>
          <Tab id={EsignFormTabs.Parties}>
            <Trans comment="Label for e-signing parties tab">Parties</Trans>
          </Tab>
          <Tab id={EsignFormTabs.Attachments}>
            <Trans comment="Label for e-signing attachments tab">Attachments</Trans>
          </Tab>
          {settings && (<Tab id={EsignFormTabs.Settings}>
              <Trans comment="Label for e-signing settings tab">Settings</Trans>
            </Tab>)}
        </StyledTabNav>
        <StyledTabContent tabId={EsignFormTabs.Parties}>{parties}</StyledTabContent>
        <StyledTabContent tabId={EsignFormTabs.Attachments}>{attachments}</StyledTabContent>
        <StyledTabContent tabId={EsignFormTabs.Settings}>{settings}</StyledTabContent>
      </Tabs>
    </TabsContainer>);
}
