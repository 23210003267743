import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFieldErrors } from 'shared/domains/forms';
export function ValidationError() {
    const errors = useFieldErrors('document');
    if (!errors.length) {
        return null;
    }
    return (<Alert appearance="warning" icon={<Falcon icon="triangle-exclamation"/>} message={t({
            message: 'Please select if you want to edit the existing document or create a new copy to edit.',
            comment: 'Form validation message when creating a free-text document',
        })}/>);
}
