import { Checkbox, Avatar, AvatarLetter, MenuItem } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { RightAlign } from './styled';
export function UserItem(props) {
    const { id, name } = props;
    const { setFieldValue, values } = useFormikContext();
    const handleOnChange = (value) => {
        const added = values.value.includes(value);
        let newValues;
        if (added) {
            newValues = values.value.filter(existingValue => existingValue !== value);
        }
        else {
            newValues = [...values.value, value];
        }
        setFieldValue('value', newValues);
    };
    const disabled = !id;
    const checked = values.value.includes(id || '');
    return (<MenuItem as="label" disabled={disabled}>
      <Avatar size="sm">
        <AvatarLetter>{name.slice(0, 1)}</AvatarLetter>
      </Avatar>
      {name}
      {disabled === false && (<RightAlign>
          <Checkbox checked={checked} onChange={() => id && handleOnChange(id)}/>
        </RightAlign>)}
    </MenuItem>);
}
