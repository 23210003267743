import { gql } from '@apollo/client';
export const assistantFragment = gql `
  fragment assistant on Assistant @client {
    __typename
    id
    selectionRange
    selectionText
    suggestionText
  }
`;
