import { useSearchContext } from '../../SearchProvider';
export function useMultiselectFilter(options) {
    const { items, paramKey, name, loading } = options;
    const { setParamValue, isFilterActive, addFilterToDraft, removeFilterFromDraft, isFilterInDraft, getMultiSelectFilterValue, } = useSearchContext();
    const paramValue = getMultiSelectFilterValue(paramKey);
    const currentItems = (paramValue || [])
        .map(id => items.find(item => item.id === id))
        .filter(Boolean);
    const handleSetItems = (newItems) => {
        const data = newItems.map(item => item.id);
        setParamValue(paramKey, data);
    };
    const add = (itemToAdd) => {
        handleSetItems([...currentItems, itemToAdd]);
        removeFilterFromDraft(paramKey);
    };
    const remove = (itemToRemove) => {
        const newCurrentItems = currentItems.filter(currentItem => itemToRemove.id !== currentItem.id);
        handleSetItems(newCurrentItems);
        if (!newCurrentItems.length)
            addFilterToDraft(paramKey);
    };
    const clear = () => {
        handleSetItems([]);
        removeFilterFromDraft(paramKey);
    };
    const getDisplayValue = () => {
        if (currentItems === null || currentItems === void 0 ? void 0 : currentItems.length) {
            const [firstItem, ...rest] = currentItems;
            const additionalItems = (rest === null || rest === void 0 ? void 0 : rest.length) >= 1 ? ` +${rest.length}` : '';
            return `${firstItem.title}${additionalItems}`;
        }
        return '';
    };
    const value = getDisplayValue();
    return {
        items,
        currentItems,
        add,
        remove,
        clear,
        value,
        isActive: isFilterActive(paramKey),
        isInDraft: isFilterInDraft(paramKey),
        name,
        loading,
    };
}
