var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mutation } from './mutation';
import { updateQuery } from './updateQuery';
export function apiRequest(apiConnector, documentId, plops) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield apiConnector.mutate({
            mutation,
            variables: {
                documentId,
                input: {
                    operations: plops,
                },
            },
            context: {
                sentry: {
                    message: () => 'Redacted, sensitive data',
                },
            },
            update(cache, { errors }) {
                const error = errors !== undefined;
                if (!error) {
                    cache.writeQuery({
                        query: updateQuery,
                        data: {
                            document: {
                                __typename: 'FileSystemDocument',
                                id: documentId,
                                updatedAt: new Date().toISOString(),
                            },
                        },
                    });
                }
            },
        });
        return data;
    });
}
