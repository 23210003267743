import { Fragment, useCallback } from 'react';
import { t } from '@lingui/macro';
import { Radio, SearchInput, MenuHeader, MenuList, MenuSubheader, MenuItem, MenuBody, } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { FormSubmitButton } from './FormSubmitButton';
import { RightAlign } from './styled';
export function SingleSelectList(props) {
    const { data } = props;
    const { setFieldValue, values } = useFormikContext();
    const { result, search } = useFuse(data, {
        keys: ['text'],
    });
    const handleOnChange = useCallback((event) => {
        const { value } = event.target;
        setFieldValue('value', value);
    }, [setFieldValue]);
    const handleSearch = (event) => {
        search(event.target.value);
    };
    const items = result.length ? result : data;
    const placeholder = t({
        message: 'Search',
        comment: 'Metadata: Single select search placeholder',
    });
    return (<Fragment>
      <MenuHeader sticky>
        <SearchInput stretch variant="line" placeholder={placeholder} onChange={handleSearch}/>
      </MenuHeader>
      <MenuBody>
        <MenuList>
          {items === null || items === void 0 ? void 0 : items.map(item => (<Fragment key={`${item.text}-${item.value}`}>
              {!item.value ? (<MenuSubheader>{item.text}</MenuSubheader>) : (<MenuItem as="label" key={item.value}>
                  {item.text}
                  <RightAlign>
                    <Radio checked={values.value === item.value} value={item.value} onChange={handleOnChange}/>
                  </RightAlign>
                </MenuItem>)}
            </Fragment>))}
        </MenuList>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
