import styled from 'styled-components';
export const Container = styled.span `
  display: inline-block;
  position: absolute;
  height: 1em;
  line-height: auto;
  transform-origin: left bottom;
  white-space: pre;
  pointer-events: all;
`;
