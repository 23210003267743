import { Trans } from '@lingui/macro';
import { Box, Skeleton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChatMessageRole, ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { useMarkdown } from './hooks/useMarkdown';
import { getAssistantMessageIconByPromptType } from './utils';
import { Container, Text } from './styled';
function SkeletonLoader() {
    return (<Box flexDirection="column" gap="sm" color="">
      <Skeleton height="1rem" appearance="purple">
        Some skeleton text that does not matter to make sure we use the right amount of space
      </Skeleton>
      <Skeleton height="1rem" width="90%" appearance="purple">
        Some skeleton text that does not matter to make sure we use the right amount of space
      </Skeleton>
    </Box>);
}
export function Message(props) {
    const { role, type, content, status } = props;
    const html = useMarkdown(content !== null && content !== void 0 ? content : '');
    switch (status) {
        case ChatMessageStatus.Failed:
            return (<Container>
          <Falcon icon="circle-exclamation" $color="assistant.500"/>
          <Text $assistantColor={role === ChatMessageRole.Assistant}>
            <Trans comment="Document assistant reply when there was an error processing the request">
              There was an error processing your request. Please, try again.
            </Trans>
          </Text>
        </Container>);
        case ChatMessageStatus.Pending:
            return (<Container $marginTop={role === ChatMessageRole.User}>
          {getAssistantMessageIconByPromptType(type)}
          <Text $assistantColor={role === ChatMessageRole.Assistant}>
            {html !== null && html !== void 0 ? html : <SkeletonLoader />}
          </Text>
        </Container>);
        default:
            return (<Container $marginTop={role === ChatMessageRole.User}>
          {getAssistantMessageIconByPromptType(type)}
          <Text $assistantColor={role === ChatMessageRole.Assistant}>{html}</Text>
        </Container>);
    }
}
