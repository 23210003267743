import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, Loader, AvatarWithLabel, } from '@pocketlaw/tetris';
import { Paragraph } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { createPath } from 'history';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { useIsAccessTokenExpired } from 'app/domains/auth/hooks/useIsAccessTokenExpired';
/*
 * From user point of view it's session expiring, but technically it's the access token expiring.
 */
export function AccessTokenExpiredDialog() {
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const location = useLocation();
    const { isAccessTokenExpired } = useIsAccessTokenExpired();
    const dialogTitle = t({
        message: 'Session expired',
        comment: 'session expired dialog title',
    });
    const handleClick = () => {
        setLoading(true);
        auth.loginWithRedirect({ returnTo: createPath(location) });
    };
    if (!isAccessTokenExpired) {
        return null;
    }
    return (<Dialog>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={dialogTitle}>
            <Falcon icon="shield-keyhole"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Paragraph>
          <Trans>Your session has expired due to inactivity</Trans>
        </Paragraph>
      </DialogContent>
      <DialogFooter>
        <Button appearance="primary" onClick={handleClick} disabled={loading}>
          <Trans>Log in</Trans>
          {loading && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
