import { useRef } from 'react';
import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChipDropdown } from 'shared/domains/common-ui';
import { NumberRangeForm } from './NumberRangeForm';
export function NumberRangeFilter(props) {
    const { filter } = props;
    const dropdownRef = useRef();
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<ChipDropdown ref={dropdownRef} label={filter.name} secondary={filter.value} initiallyOpen={filter.isInDraft} action={<ChipAction onClick={filter.clear}>
          <Falcon icon="remove"/>
        </ChipAction>} dropdownContent={<NumberRangeForm initialValue={{ from: filter.from, to: filter.to }} onSubmit={newRange => {
                var _a;
                filter.setNumberRange(newRange.from, newRange.to);
                (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown();
            }}/>}/>);
}
