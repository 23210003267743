var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { query } from './query';
import { GetCommentFragment } from '../utils/commentFragment';
import { getAllUsersFromThread } from '../utils/getAllUsersFromThread';
import { transformThread } from '../utils/transformThread';
/**
 * Function to return a thread and all users active in that thread.
 * If a thread is found in cache it will be returned. Otherwise we will query the API for the thread.
 * @param threadId string
 * @returns A single comment thread with replies and the users
 */
export function getCommentThread(apiConnector, input) {
    return __awaiter(this, void 0, void 0, function* () {
        const { threadId } = input;
        let commentThread;
        const cachedThread = apiConnector.cache.readFragment({
            id: `FileSystemDocumentComment:${threadId}`,
            fragment: GetCommentFragment,
        });
        if (cachedThread) {
            commentThread = cachedThread;
        }
        else {
            const { data } = yield apiConnector.query({
                query,
                variables: {
                    id: threadId,
                },
            });
            const { comments } = data || {};
            const [newThread] = comments || [];
            commentThread = newThread;
        }
        const users = getAllUsersFromThread(commentThread);
        const thread = yield transformThread(commentThread);
        return {
            thread,
            users,
        };
    });
}
