import { createContext, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { FormStatus } from 'app/domains/esigning/utils';
export const AttachmentsContext = createContext(null);
export { useAttachments } from './useAttachments';
export function AttachmentsProvider(props) {
    const { children } = props;
    const { creating, submittingCreation } = useEsigning();
    const { status } = useFormikContext();
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { maxNumberOfAttachments } = providerProperties;
    const [fileSizeTooBigWarning, setFileSizeTooBigWarning] = useState(false);
    const [{ value: attachments }, , { setError, setValue }] = useField('attachments');
    const exceedsMaxNumberOfAttachments = maxNumberOfAttachments !== null && attachments.length > maxNumberOfAttachments;
    const uploading = status === FormStatus.Uploading;
    const loading = creating || submittingCreation || uploading;
    const disabled = loading || exceedsMaxNumberOfAttachments;
    const setAttachments = (newAttachments) => setValue(newAttachments);
    const setAttachmentsError = (error) => setError(error);
    const removeAttachment = (attachmentPath) => {
        const updatedAttachments = attachments.filter((attachment) => attachment.path !== attachmentPath);
        setAttachments(updatedAttachments);
    };
    const value = {
        loading,
        disabled,
        uploading,
        attachments,
        maxNumberOfAttachments,
        exceedsMaxNumberOfAttachments,
        fileSizeTooBigWarning,
        setFileSizeTooBigWarning,
        setAttachments,
        setAttachmentsError,
        removeAttachment,
    };
    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
}
