import { Plugin } from 'pleditor';
import { AssistantSelectionCommand } from './AssistantSelectionCommand';
const HIGHLIGHT = 'AssistantSelectionHighlight';
/**
 * This plugin allows us to add the `AssistantSelectionHighlight` attribute to `$text` nodes.
 * It also adds a custom class to the affected nodes which allows us to style it freely.
 */
export class AssistantSelection extends Plugin {
    constructor() {
        super(...arguments);
        this.init = () => {
            this.setupSchema();
            this.setupCommand();
            this.setupConversion();
        };
        this.setupSchema = () => {
            const { editor } = this;
            editor.model.schema.extend('$text', { allowAttributes: HIGHLIGHT });
            editor.model.schema.setAttributeProperties(HIGHLIGHT, { isFormatting: true });
        };
        this.setupCommand = () => {
            const { editor } = this;
            editor.commands.add(HIGHLIGHT, new AssistantSelectionCommand(editor));
        };
        this.setupConversion = () => {
            const { editor } = this;
            editor.conversion.attributeToElement({
                model: HIGHLIGHT,
                view: {
                    name: 'span',
                    classes: 'assistant-selection-highlight',
                },
            });
        };
    }
    static get pluginName() {
        return 'AssistantSelection';
    }
}
