var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { v4 as uuid } from 'uuid';
import { addSuggestion } from './addSuggestion';
import { getAllSuggestions } from './getAllSuggestions';
import { getSuggestion } from './getSuggestion';
import { updateSuggestion } from './updateSuggestion';
import { isUUID } from '../../utils';
import { PleditorPlugin } from '../PleditorPlugin';
import { UsersManager } from '../UsersManager';
export class SuggestionsAdapter extends PleditorPlugin {
    static get requires() {
        return ['UsersManager', 'CommentsRepository', 'TrackChanges'];
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.useCustomUUID();
            this.useAdapter();
            yield this.loadSuggestionData();
        });
    }
    useCustomUUID() {
        /**
         * This method will highjack the two internal methods in the Track changes plugin
         * to set a custom UUID for the suggestion and the suggestion marker.
         * There is currently no other way for us to pre-define the ID.
         */
        const trackChangesEditing = this.editor.plugins.get('TrackChangesEditing');
        let UUID = '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackChangesEditing.decorate('_setSuggestionData');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackChangesEditing.decorate('_addSuggestionMarker');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackChangesEditing.decorate('_getAuthorFromId');
        const usersManager = this.editor.plugins.get(UsersManager);
        // To intercept internal call in TrackChanges plugin to add non existent user to avoid editor from crashing
        trackChangesEditing.on('_getAuthorFromId', (_, authorIds) => __awaiter(this, void 0, void 0, function* () {
            if (authorIds && authorIds.length) {
                authorIds.forEach((userId) => {
                    const user = usersManager.getUser(userId);
                    if (!user) {
                        usersManager.addUser({
                            id: userId,
                        });
                    }
                });
            }
        }), { priority: 'highest' });
        // Hook in to the creation of the suggestion
        // Generate a new UUID that will be used by _addSuggestionMarker, these
        // ID's has to be the same.
        trackChangesEditing.on('_setSuggestionData', (_, [data]) => {
            UUID = uuid();
            if (!isUUID(data.id)) {
                // eslint-disable-next-line no-param-reassign
                data.id = UUID;
            }
        }, { priority: 'highest' });
        // Hook in to the creation of the suggestion marker
        // The UUID created in the _setSuggestionData event is used.
        trackChangesEditing.on('_addSuggestionMarker', (_, data) => {
            if (!isUUID(data[1])) {
                // eslint-disable-next-line no-param-reassign
                data[1] = UUID;
            }
        }, { priority: 'highest' });
    }
    useAdapter() {
        const trackChangesPlugin = this.editor.plugins.get('TrackChanges');
        const notificationPlugin = this.editor.plugins.get('Notification');
        trackChangesPlugin.adapter = {
            getSuggestion: (suggestionId) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const { suggestion, user } = yield getSuggestion(this.apiConnector, {
                        suggestionId,
                    });
                    this.addUser(user);
                    return suggestion;
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while getting the suggestions',
                        comment: 'Error message while getting suggestions',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            addSuggestion: (suggestionData) => __awaiter(this, void 0, void 0, function* () {
                const { id, type, data, attributes = {} } = suggestionData;
                const documentId = this.editor.config.get('documentId');
                const typeParts = type.split(':');
                const suggestionType = typeParts[0];
                const suggestionSubType = typeParts[1];
                try {
                    const suggestion = yield addSuggestion(this.apiConnector, {
                        suggestionId: id,
                        type: suggestionType,
                        subType: suggestionSubType,
                        documentId,
                        data,
                        attributes: JSON.stringify(attributes),
                    });
                    return suggestion;
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while adding suggestion',
                        comment: 'Error message while adding suggestion',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
            updateSuggestion: (id, suggestionData) => __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield updateSuggestion(this.apiConnector, {
                        suggestionId: id,
                        status: suggestionData.state,
                    });
                }
                catch (error) {
                    notificationPlugin.showWarning(t({
                        message: 'An error occurred while updating suggestion',
                        comment: 'Error message while updating suggestion',
                    }));
                    // Let editor crash. watchdog will handle the error.
                    throw error;
                }
            }),
        };
    }
    loadSuggestionData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { suggestions } = yield getAllSuggestions(this.apiConnector, this.documentId);
            suggestions.forEach(({ user }) => {
                this.addUser(user);
            });
        });
    }
    addUser(user) {
        const usersManager = this.editor.plugins.get(UsersManager);
        usersManager.addUser(user);
    }
}
