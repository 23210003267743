import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { MultiSelectFilter, useMultiselectFilter } from '../MultiSelectFilter';
export function DocumentTypeFilter() {
    var _a, _b;
    const documentTypes = (_b = (_a = metadataConfig.get('DocumentType')) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(type => ({
        id: type.value,
        title: type.text,
    }));
    const filter = useMultiselectFilter({
        paramKey: 'documentType',
        items: documentTypes || [],
        name: searchFilterCopy().documentType,
    });
    return <MultiSelectFilter filter={filter}/>;
}
