var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import rehypeParse from 'rehype-parse';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import { unified } from 'unified';
const processor = unified()
    .use(rehypeParse)
    .use(rehypeSanitize, {
    tagNames: ['p', 'span'],
    attributes: {
        '*': [],
        span: ['className', 'data*'],
    },
})
    .use(rehypeStringify);
export function sanitizeCommentBody() {
    return __awaiter(this, arguments, void 0, function* (htmlString = '') {
        const sanitizedResult = yield processor.process(htmlString);
        // Empty paragraph tag to avoid error
        return String(sanitizedResult) || '<p></p>';
    });
}
