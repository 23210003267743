import { padding, paddingTop } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { ErrorMessage } from 'shared/domains/forms';
export const RightAlign = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const StyledErrorMessage = styled(ErrorMessage) `
  ${padding(0, 2)};
  ${paddingTop(2)};
`;
