import { getCurrentMarketTemplateLocales } from 'app/domains/markets/config';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { useCurrentCompanyPlan } from '../../useCurrentCompanyPlan';
export function useFilters(filterData) {
    var _a, _b;
    const account = useAccount();
    const companyId = ((_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id) || '';
    const { currentPlan } = useCurrentCompanyPlan();
    const defaultLanguages = getCurrentMarketTemplateLocales().map(({ languageCode }) => languageCode);
    const { templatesFilter, selectedCategoryIds, selectedLanguages, searchQuery } = filterData;
    const languagesFilter = selectedLanguages.length > 0 ? selectedLanguages : defaultLanguages;
    const filters = {
        enabled: { eq: true },
        templates: { marketLocaleCode: { in: languagesFilter } },
    };
    if (templatesFilter === 'templates:included') {
        filters.availableForPlans = { id: { eq: currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id } };
    }
    if (templatesFilter === 'templates:company') {
        filters.companyComposers = { companyId: { eq: companyId } };
    }
    if (selectedCategoryIds.length > 0) {
        filters.categoryId = { in: selectedCategoryIds };
    }
    if (searchQuery.length > 0) {
        filters.metadata = { name: { ilike: `%${searchQuery}%` } };
    }
    return { filters };
}
