import { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button } from '@pocketlaw/tetris';
import { documentType } from '../../documentType';
import { useGetDocumentType } from '../../hooks/useGetDocumentType';
export function DocumentTypeChangeModal() {
    const { type: initialRenderType } = useGetDocumentType();
    const type = useReactiveVar(documentType);
    const cachedType = useRef();
    const isCachedTypeChanged = Boolean(type) && Boolean(cachedType.current) && type !== cachedType.current;
    const isInitialTypeChanged = Boolean(type) && Boolean(initialRenderType) && type !== initialRenderType;
    const isTypeChanged = isCachedTypeChanged || isInitialTypeChanged;
    useEffect(() => {
        if (!cachedType.current && type) {
            cachedType.current = type;
        }
    }, [type]);
    const handleOnClick = () => window.location.reload();
    if (!isTypeChanged) {
        return null;
    }
    return (<Dialog size="sm">
      <DialogHeader title={t({
            comment: 'Document format change dialog - Dialog title',
            message: 'Format change',
        })}/>
      <DialogContent>
        <Trans comment="Document format change dialog - Dialog message">
          The format of the document has been changed. Please reload the change
        </Trans>
      </DialogContent>
      <DialogFooter>
        <Button appearance="primary" onClick={handleOnClick}>
          <Trans comment="Document format change dialog - Reload button label">
            Reload document
          </Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
