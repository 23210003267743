import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function OnlyNameFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'onlyName',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().onlyName,
    });
    return <SingleSelectFilter filter={filter}/>;
}
