import { gql } from '@apollo/client';
import { GetCommentFragment } from '../utils/commentFragment';
export const query = gql `
  query AllCommentsForEditor($id: ID!) {
    fileSystemDocumentComments(where: { documentId: { eq: $id } }) {
      ...GetCommentFragment
    }
  }

  ${GetCommentFragment}
`;
