import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { ALL_DOCUMENTS } from './constants';
export function buildEntityTypeFilter(currentItems) {
    const validItems = currentItems.filter(item => Boolean(item));
    if (validItems.length === 0) {
        return undefined;
    }
    const types = [];
    validItems.forEach(type => {
        const typedId = type;
        if (typedId === ALL_DOCUMENTS) {
            types.push(SearchEntityTypeEnum.DocumentComposed, SearchEntityTypeEnum.DocumentFreetext, SearchEntityTypeEnum.DocumentUploaded);
            return;
        }
        types.push(typedId);
    });
    return { in: types };
}
