import { gql } from '@apollo/client';
export const QUERY = gql `
  query ComposerApprovers($composerId: Unstable__UUID!) {
    composer: unstable__composer(id: $composerId) {
      id
      approval {
        id
        approvers {
          id
          companyUser {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;
