export function buildMaterialAgreementFilter(id) {
    if (!id) {
        return undefined;
    }
    if (id === 'yes') {
        return { eq: true };
    }
    if (id === 'no') {
        return { eq: false };
    }
    if (id === 'set') {
        return { in: [true, false] };
    }
    if (id === 'not-set') {
        return { isNull: true };
    }
    return undefined;
}
