import { Plugin, Sessions } from 'pleditor';
import { documentUsers } from '../../documentUsers';
export class SessionUsers extends Plugin {
    static get pluginName() {
        return 'SessionUsers';
    }
    static get requires() {
        return [Sessions];
    }
    init() {
        const sessions = this.editor.plugins.get(Sessions);
        sessions.allConnectedUsers.on('add', (event, user) => {
            documentUsers([...documentUsers(), user]);
        });
        sessions.allConnectedUsers.on('remove', (event, user) => {
            documentUsers(documentUsers().filter(({ id }) => id !== user.id));
        });
    }
}
