import { useState } from 'react';
import { hasValidValue } from '../MetadataField/utils';
export function useToggleEntryVisibility(properties) {
    const [activeDraftEntries, setActiveDraftEntries] = useState([]);
    const entries = properties
        .filter(({ pair }) => hasValidValue(pair.value))
        .map(({ property }) => property.name);
    const toggleActiveEntry = (entryName) => {
        setActiveDraftEntries(prev => {
            const isEntry = entries.includes(entryName);
            const isDraft = prev.includes(entryName);
            if (isDraft || isEntry) {
                return prev.filter(item => item !== entryName);
            }
            return [...prev, entryName];
        });
    };
    return {
        activeEntries: [...entries, ...activeDraftEntries],
        toggleActiveEntry,
    };
}
