import debounce from 'lodash.debounce';
import { Plugin } from 'pleditor';
import { documentState } from '../../documentState';
import { CollaboratorSession } from '../CollaboratorSession';
import { SaveHandler } from '../SaveHandler';
/**
 * This plugin will take care of setting the document state in our
 * reactive variable that is being read by the React UI.
 * This way when the editor enters a error state for example, we can
 * listen to this outside of the editor.
 */
export class EditorDocumentState extends Plugin {
    init() {
        const autoSave = this.editor.plugins.get('Autosave');
        const saveHandler = this.editor.plugins.get(SaveHandler);
        const collaboratorSession = this.editor.plugins.get(CollaboratorSession);
        autoSave.on('change:state', debounce((ev, name, value) => {
            const previousState = documentState();
            const hasError = saveHandler.state === 'error' || collaboratorSession.state === 'error';
            if (hasError === false && previousState !== 'error' && value === 'waiting') {
                documentState('waiting');
            }
        }, 150));
        const handleEvent = (ev, name, newValue, oldValue) => {
            const previousState = documentState();
            if (previousState === 'error' || oldValue === 'error') {
                return;
            }
            documentState(newValue);
        };
        saveHandler.on('change:state', debounce(handleEvent, 150), { priority: 'lowest' });
        collaboratorSession.on('change:state', debounce(handleEvent, 150), { priority: 'lowest' });
    }
}
