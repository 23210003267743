import { Trans } from '@lingui/macro';
import { Form, SubmitButton, TextField } from 'shared/domains/forms';
import { validationSchema } from './validationSchema';
import { Container } from './styled';
export function SimpleTextForm(props) {
    const { onSubmit, initialValue } = props;
    return (<Form initialValues={{ text: initialValue }} onSubmit={({ text }) => onSubmit(text)} validationSchema={validationSchema}>
      <Container>
        <TextField name="text" autoFocus/>
        <SubmitButton size="small">
          <Trans comment="Apply filter button label">Apply</Trans>
        </SubmitButton>
      </Container>
    </Form>);
}
