import { useId } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobile } from 'shared/domains/common-ui';
import { ErrorState } from './ErrorState';
import { RightColumn } from './RightColumn';
import { TemplateData } from './TemplateData';
import { useComposerData } from '../../ComposerDataProvider';
import { useDraftPreview } from '../DraftPreviewProvider';
import { Content, Header, LeftColumn } from './styled';
export function Root() {
    const { error } = useDraftPreview();
    const isMobile = useMobile();
    const { goBackToSelect } = useComposerData();
    const id = useId();
    if (error) {
        return <ErrorState />;
    }
    return (<Content>
      {!isMobile && (<LeftColumn>
          <Header>
            <IconButton $round appearance="secondary" size="small" onClick={goBackToSelect} aria-describedby={id}>
              <Falcon icon="chevron-left"/>
            </IconButton>
            <Typography $appearance="300" $fontSize="large" $fontWeight="semibold" id={id}>
              <Trans comment="Preview template - Go back to view all templates">View all</Trans>
            </Typography>
          </Header>
          <TemplateData />
        </LeftColumn>)}
      <RightColumn />
    </Content>);
}
