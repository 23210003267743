import { t } from '@lingui/macro';
import { Column, Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
function ErrorAlert() {
    return (<Column width="100%">
      <Alert appearance="danger" icon={<Falcon icon="circle-xmark"/>} title={t({ message: 'Error', comment: 'Error alert title' })} message={t({
            message: 'Something went wrong, please try again later',
            comment: 'Error alert message',
        })}/>
    </Column>);
}
export default ErrorAlert;
