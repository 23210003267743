import { gql } from '@apollo/client';
export const resolveCommentThreadMutation = gql `
  mutation resolveCommentThread($id: UUID!) {
    updateFileSystemDocumentComment(id: $id, input: { isResolved: true }) {
      id
      resolved: isResolved
      resolvedAt
      resolvedBy
    }
  }
`;
