import { gql } from '@apollo/client';
export const composersQuery = gql `
  query composers($marketCodes: [String!]!, $companyId: UUID!) {
    composers: unstable__composers(
      where: { marketCode: { in: $marketCodes }, enabled: { eq: true } }
    ) {
      id

      metadata {
        name
        marketLocaleCode
      }

      composerPlans {
        id
        plan {
          id
        }
      }

      templates {
        id
        marketLocaleCode
      }
    }

    company(id: $companyId) {
      id
      plans {
        id
        plan {
          id
          name
        }
      }
    }
  }
`;
