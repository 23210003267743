import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import Markdown from './Markdown';
import { useExpandButton } from './useExpandButton';
import { ExpandableContainer } from './styled';
export function DescriptionColumn(props) {
    const { children } = props;
    const { contentRef, expanded, toggleExpanded, showExpandButton } = useExpandButton();
    return (<Fragment>
      <ExpandableContainer $expanded={expanded} $showExpandButton={showExpandButton}>
        <div ref={contentRef}>
          <Markdown as="section" contents={children}/>
        </div>
      </ExpandableContainer>
      {showExpandButton && (<Button appearance="ghost" onClick={toggleExpanded}>
          {expanded ? <Trans>- Show less</Trans> : <Trans>+ Show more</Trans>}
        </Button>)}
    </Fragment>);
}
