import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function MemberAlert(props) {
    const { expired } = props;
    const Message = expired
        ? t({
            message: "Since you haven't used e-signing in more than 30 days you need to authenticate. Only owners can authenticate and connect e-signing providers.",
            comment: "Informative message for users that haven't used e-signing the last 30 days",
        })
        : t({
            message: 'Only owners can connect your company for e-signing.',
            comment: "Informative message for users that doesn't have permission to connect the company to e-signing",
        });
    return <Alert appearance="info" icon={<Falcon icon="exclamation"/>} message={Message}/>;
}
