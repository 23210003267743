import { yesNoFilterOptions } from './constants';
import { searchFilterCopy } from './searchFilterCopy';
import { SingleSelectFilter, useSingleSelectFilter } from '../SingleSelectFilter';
export function FeeAdjustmentClauseFilter() {
    const filter = useSingleSelectFilter({
        paramKey: 'feeAdjustmentClause',
        items: yesNoFilterOptions(),
        name: searchFilterCopy().feeAdjustmentClause,
    });
    return <SingleSelectFilter filter={filter}/>;
}
