import { t } from '@lingui/macro';
import { ButtonView, Plugin } from 'pleditor';
import { assistantCache } from 'app/domains/assistant';
import { Sparkles } from './icons';
import { getTextFromSelection } from './utils';
import { Assistant } from '../Assistant';
const MAX_SELECTION_CHARACTER_COUNT = 4096;
export class InlineAssistantShortcut extends Plugin {
    static get pluginName() {
        return 'InlineAssistantShortcut';
    }
    static get requires() {
        return [Assistant];
    }
    constructor(editor) {
        super(editor);
        this.isCommandEnabled = true;
        this.isSelectionValid = true;
        this.init = () => {
            const { editor, button, setupListeners } = this;
            setupListeners();
            editor.ui.componentFactory.add('inlineAssistantShortcut', () => {
                button.set({
                    withText: false,
                    tooltip: t({
                        comment: 'Pleditor - Document assistant tooltip',
                        message: 'Document assistant',
                    }),
                    icon: Sparkles,
                    class: 'pleditor-toolbar-fa-icon-button--assistant',
                });
                button.on('execute', this.handleAssistantButtonClick.bind(this));
                return button;
            });
        };
        this.setupListeners = () => {
            var _a;
            const { editor, updateButtonEnabled } = this;
            editor.model.document.selection.on('change:range', () => {
                const selectionText = getTextFromSelection(editor.model.document.selection);
                this.isSelectionValid = selectionText.length <= MAX_SELECTION_CHARACTER_COUNT;
                updateButtonEnabled();
            });
            (_a = editor.commands
                .get('AssistantSelectionHighlight')) === null || _a === void 0 ? void 0 : _a.on('change:isEnabled', (_, __, value) => {
                this.isCommandEnabled = value;
                updateButtonEnabled();
            });
        };
        this.updateButtonEnabled = () => {
            const { isCommandEnabled, isSelectionValid } = this;
            if (!isCommandEnabled) {
                this.button.isEnabled = false;
            }
            this.button.isEnabled = isSelectionValid;
        };
        this.handleAssistantButtonClick = () => {
            const { editor } = this;
            const selectionText = getTextFromSelection(editor.model.document.selection);
            const selectionRange = editor.model.document.selection.getFirstRange();
            assistantCache.emit('pleditor:on-text-selected', { selectionText, selectionRange });
            editor.execute('AssistantSelectionHighlight');
        };
        this.button = new ButtonView();
    }
}
