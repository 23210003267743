import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { LabelHeading, RadioCard } from '@pocketlaw/tetris';
import Flag from 'shared/domains/languages/components/Flag';
import { RadioCardContainer } from './styled';
export function TemplateLanguage(props) {
    const { templates, selectedTemplateId, onSelect } = props;
    const disabled = templates.length <= 1;
    return (<Fragment>
      <LabelHeading>
        <Trans comment="Label for template language selection when creating a draft">
          Language
        </Trans>
      </LabelHeading>
      <RadioCardContainer>
        {templates.map(template => (<RadioCard key={template.id} disabled={disabled} checked={template.id === selectedTemplateId} onChange={() => onSelect(template.id)} title={template.name} icon={<Flag languageCode={template.languageCode}/>}/>))}
      </RadioCardContainer>
    </Fragment>);
}
