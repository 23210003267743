import { localizedFormatDate } from 'shared/domains/common-utils';
import { useSearchContext } from '../../SearchProvider';
export function useDateRangeFilter(options) {
    const { name, paramKey, initialValues } = options;
    const { isFilterInDraft, removeFilterFromDraft, setParamValue, getDateRangeFilterValue } = useSearchContext();
    const dateRange = Object.assign(Object.assign({}, (initialValues || {})), getDateRangeFilterValue(paramKey));
    const setDateRange = (newStartDate, newEndDate) => {
        const startDateString = (newStartDate === null || newStartDate === void 0 ? void 0 : newStartDate.toISOString()) || '';
        const endDateString = (newEndDate === null || newEndDate === void 0 ? void 0 : newEndDate.toISOString()) || '';
        setParamValue(paramKey, [`${startDateString},${endDateString}`]);
        removeFilterFromDraft(paramKey);
    };
    const clear = () => {
        setParamValue(paramKey, []);
        removeFilterFromDraft(paramKey);
    };
    const isActive = dateRange.startDate || dateRange.endDate;
    const isInDraft = isFilterInDraft(paramKey);
    const dirty = dateRange.startDate !== (initialValues === null || initialValues === void 0 ? void 0 : initialValues.startDate) ||
        dateRange.endDate !== (initialValues === null || initialValues === void 0 ? void 0 : initialValues.endDate);
    const label = [
        (dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate) && localizedFormatDate(dateRange.startDate.toISOString()),
        (dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate) && dateRange.endDate && ' - ',
        (dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate) && localizedFormatDate(dateRange.endDate.toISOString()),
    ]
        .filter(Boolean)
        .join('');
    return {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        value: label,
        setDateRange,
        clear,
        isActive,
        name,
        isInDraft,
        dirty,
    };
}
