export function getSearchHighlightContext(name, highlight) {
    // `highlight` might be `null` when the query search args modifiers property is of
    // type `autocompleteName`.
    const [firstKey] = highlight !== null ? Object.keys(highlight) : [];
    const highlightText = highlight !== null && Array.isArray(highlight[firstKey]) && highlight[firstKey].length > 0
        ? highlight[firstKey][0]
        : name;
    const showNameOnly = firstKey === 'name' || firstKey === undefined;
    return {
        highlightText,
        showNameOnly,
    };
}
