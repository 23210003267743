var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { getCurrentMarket } from 'app/domains/markets/config';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createDraftDialogQuery } from './query';
import { transformData } from './utils';
export function useCreateDraftDialog(composerId) {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const { code } = getCurrentMarket();
    const _b = useQuery(createDraftDialogQuery, {
        variables: {
            companyId,
            composerId,
            marketCode: code,
        },
        fetchPolicy: 'cache-and-network',
    }), { data } = _b, rest = __rest(_b, ["data"]);
    return Object.assign({ data: transformData(data) }, rest);
}
