var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { TableBodyCell, Menu, IconButton, useToast, Loader, MenuList, MenuItem, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTasks, useDeleteTask } from 'app/domains/tasks';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { TasksIds } from '../../../types';
import { MenuCellWrapper } from './styled';
export function MenuCell(props) {
    const { taskId, isCompleted, status } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { handleEditTask } = useTasks();
    const { mutate, loading } = useDeleteTask();
    const { addToast } = useToast();
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsOpen(false);
            yield mutate(taskId);
            addToast({
                title: t `Task deleted`,
                appearance: 'success',
            });
        }
        catch (deleteMutationError) {
            addToast({
                title: t `An error occurred`,
                appearance: 'destructive',
            });
        }
    });
    const completed = isCompleted || status === TaskStatusEnumEnum.Completed;
    return (<TableBodyCell headers={TasksIds.dropdownMenu}>
      <Menu closeOnOutsideClick onClose={() => setIsOpen(false)} open={isOpen} target={<MenuCellWrapper>
            <IconButton disabled={loading} $round appearance="ghost" size="small" onClick={() => setIsOpen(!isOpen)}>
              {loading ? <Loader /> : <Falcon icon="ellipsis-vertical"/>}
            </IconButton>
          </MenuCellWrapper>}>
        <MenuBody>
          <MenuList>
            {!completed && (<MenuItem disabled={loading} onClick={() => {
                setIsOpen(false);
                handleEditTask(taskId);
            }}>
                <Falcon icon="edit"/>
                <Trans comment="Edit list item for task context menu">Edit</Trans>
              </MenuItem>)}
            <MenuItem disabled={loading} onClick={() => handleDelete()}>
              <Falcon icon="trash"/>
              <Trans comment="Remove list item for task context menu">Remove</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
    </TableBodyCell>);
}
