import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSearchContext } from 'app/domains/global-search';
import { SaveViewDialog } from '../SaveViewDialog';
export function SaveViewButton() {
    const { getActiveFilters } = useSearchContext();
    const [open, setOpen] = useState(false);
    const activeFilters = getActiveFilters();
    if (activeFilters.length === 0) {
        return null;
    }
    return (<Fragment>
      <Button appearance="ghost" size="small" onClick={() => setOpen(true)}>
        <Falcon icon="layer-plus"/>
        <Trans comment="Save custom search view button text">Save view</Trans>
      </Button>

      {open && <SaveViewDialog onClose={() => setOpen(false)}/>}
    </Fragment>);
}
