import { Fragment } from 'react';
import { useGetSignRequestAttachments } from 'app/domains/esigning/hooks/useGetSignRequestAttachments';
import { AutomaticReminderAlert } from './AutomaticReminderAlert';
import { CancelSignRequestButton } from './CancelSignRequestButton';
import { CancelSignRequestErrorAlert } from './CancelSignRequestErrorAlert';
import { PendingAttachments } from './PendingAttachments';
import { PendingParties } from './PendingParties';
import { SendReminderButton } from './SendReminderButton';
import { ESignTabs } from '../ESignTabs';
import { SectionContent, SectionFooter } from '../components';
import { OverlayLoader } from '../components/OverlayLoader';
import { AlertsContainer, FooterContainer } from './styled';
export function ESignPendingState() {
    const { loading: loadingAttachments } = useGetSignRequestAttachments();
    return (<Fragment>
      {loadingAttachments && <OverlayLoader overlay/>}
      <SectionContent>
        <AlertsContainer>
          <CancelSignRequestErrorAlert />
        </AlertsContainer>
        <ESignTabs parties={<PendingParties />} attachments={<PendingAttachments />}/>
      </SectionContent>
      <SectionFooter>
        <AutomaticReminderAlert />
        <FooterContainer>
          <SendReminderButton />
          <CancelSignRequestButton />
        </FooterContainer>
      </SectionFooter>
    </Fragment>);
}
