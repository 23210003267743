import { defaultLocale, localeConfig } from 'shared/domains/languages/config';
export const transformSearchQuery = (inputSearchQuery) => {
    if (!inputSearchQuery) {
        return undefined;
    }
    const { search } = inputSearchQuery;
    const { results } = search;
    const transformedResults = results.map(result => {
        var _a;
        const metadataTemplateTitle = 'metadataTemplateTitle' in result ? result.metadataTemplateTitle : undefined;
        const activeLocaleCode = localeConfig.getActiveLocale().languageCode;
        const defaultLocaleCode = defaultLocale.languageCode;
        const templateLocaleTitle = (metadataTemplateTitle === null || metadataTemplateTitle === void 0 ? void 0 : metadataTemplateTitle.find(item => item.marketLocaleCode === activeLocaleCode)) ||
            (metadataTemplateTitle === null || metadataTemplateTitle === void 0 ? void 0 : metadataTemplateTitle.find(item => item.marketLocaleCode === defaultLocaleCode));
        return Object.assign(Object.assign({}, result), { name: (_a = templateLocaleTitle === null || templateLocaleTitle === void 0 ? void 0 : templateLocaleTitle.text) !== null && _a !== void 0 ? _a : result.name });
    });
    return {
        search: Object.assign(Object.assign({}, search), { results: transformedResults }),
    };
};
