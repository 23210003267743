import { t } from '@lingui/macro';
const validateUser = (user) => (Object.assign(Object.assign({}, user), { id: (user === null || user === void 0 ? void 0 : user.id) || '0', name: (user === null || user === void 0 ? void 0 : user.name) || t({ message: 'Unknown name', comment: 'Fallback name for user in comments' }) }));
function flattenAllUsersInThread(comment) {
    const { user, replies } = comment;
    const replyUsers = replies.map(reply => reply.user);
    return [user, ...replyUsers];
}
export function getAllUsersFromThread(thread) {
    if (!thread) {
        return [];
    }
    const arrayGuard = Array.isArray(thread) ? thread : [thread];
    const users = arrayGuard.map(flattenAllUsersInThread).flat();
    return users.map(validateUser);
}
