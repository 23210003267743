import { marginLeft, marginRight, padding, unit, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  ${marginLeft(-4)};
  ${marginRight(-4)};
`;
export const Image = styled.img `
  display: block;
  width: 100%;
`;
export const Viewer = styled.div `
  ${padding(8, 4, 0)};
  background-color: ${themeGet('colors.gray.50')};
`;
export const Paper = styled.div `
  max-width: ${unit(135)};
  margin: auto;
  border: ${pxToRem(1)} solid ${themeGet('colors.gray.100')};
  background-color: ${themeGet('colors.gray.0')};

  ${Viewer}:last-child & {
    border-bottom: none;
  }
`;
