import { t } from '@lingui/macro';
import { Modal, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTransitionState } from 'shared/domains/common-ui';
import { Container, CloseContainer } from './styled';
export function Drawer(props) {
    const { open, position = 'left', children, onClose } = props;
    const state = useTransitionState(open, 200);
    if (state === 'hidden') {
        return null;
    }
    return (<Modal onClose={onClose} autoFocus={false}>
      <Container $state={state} $position={position}>
        {onClose && (<CloseContainer>
            <IconButton appearance="secondary" $round onClick={onClose} aria-label={t `Close`}>
              <Falcon icon="xmark" $size={16}/>
            </IconButton>
          </CloseContainer>)}
        {children}
      </Container>
    </Modal>);
}
