import { TableBody, TableRow } from '@pocketlaw/tetris';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { SearchResultsTableCell } from './SearchResultsTableCell';
import { useTableContext } from './TableContext';
import { canChangeDocumentStatus } from './utils';
import { useSearchContext } from '../SearchProvider';
export function SearchResultTableBody() {
    const { columnConfigs } = useSearchContext();
    const { activeAction, results, selected, setSelected } = useTableContext();
    const featureFlag = useFeatureFlag('feature_document_status');
    const selectedIds = selected.map(item => item.entityId);
    const isDisabled = (row) => {
        const isSelected = selectedIds.includes(row.entityId);
        if (!activeAction) {
            return false;
        }
        if (activeAction && !isSelected) {
            return true;
        }
        switch (activeAction.name) {
            case 'add-status': {
                return !canChangeDocumentStatus(row);
            }
            default:
                return true;
        }
    };
    const isRowSelected = (row) => !(activeAction && isDisabled(row)) && selectedIds.includes(row.entityId);
    const onSelect = featureFlag
        ? (row) => () => setSelected({ type: 'select', payload: row })
        : undefined;
    return (<TableBody>
      {results.map(result => (<TableRow key={result.id} disabled={isDisabled(result)} $selected={isRowSelected(result)}>
          {columnConfigs.map(column => (<SearchResultsTableCell key={column.id} column={column} result={result} onSelect={onSelect === null || onSelect === void 0 ? void 0 : onSelect(result)} selected={isRowSelected(result)}/>))}
        </TableRow>))}
    </TableBody>);
}
