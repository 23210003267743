import { ApprovalChip } from './ApprovalChip';
import { CollaboratingChip } from './CollaboratingChip';
import { EsigningChip } from './EsigningChip';
import { LockedChip } from './LockedChip';
import { NoticeDateChip } from './NoticeDateChip';
import { SharedExternallyChip } from './SharedExternallyChip';
import { Container } from './styles';
export function DocumentChips() {
    return (<Container>
      <NoticeDateChip />
      <LockedChip />
      <ApprovalChip />
      <EsigningChip />
      <SharedExternallyChip />
      <CollaboratingChip />
    </Container>);
}
