import { gql } from '@apollo/client';
export const getSearchCategoriesQuery = gql `
  query getSearchCategories($hiddenCategoryIds: [UUID!]!) {
    categories(order_by: { order: asc }, where: { id: { _nin: $hiddenCategoryIds } }) {
      id
      metadata {
        title
        marketLocaleCode: market_locale_code
      }
    }
  }
`;
