export function filterUsersByAccess(data) {
    const composers = data.composers || [];
    const users = data.users || [];
    const defaultUserIdsWithAccessCount = {};
    const userIdsWithAccessCount = composers.reduce((prev, current) => {
        var _a;
        const userAccessCount = Object.assign({}, prev);
        (_a = current.acls) === null || _a === void 0 ? void 0 : _a.forEach(acl => {
            if (acl.allowedActions.includes('read')) {
                const count = userAccessCount[acl.userId] || 0;
                Object.assign(userAccessCount, { [acl.userId]: count + 1 });
            }
        });
        return userAccessCount;
    }, defaultUserIdsWithAccessCount);
    const defaultUserFilteredAccess = {
        access: [],
        partialAccess: [],
        noAccess: [],
    };
    return users.reduce((prev, user) => {
        const userCount = userIdsWithAccessCount[user.id] || 0;
        if (!userCount) {
            return Object.assign(Object.assign({}, prev), { noAccess: [...prev.noAccess, user] });
        }
        if (userCount < composers.length) {
            return Object.assign(Object.assign({}, prev), { partialAccess: [...prev.partialAccess, user] });
        }
        return Object.assign(Object.assign({}, prev), { access: [...prev.access, user] });
    }, defaultUserFilteredAccess);
}
