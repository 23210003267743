import { Radio, TableRow, TableBodyCell } from '@pocketlaw/tetris';
import { ComposerBadge, DocumentName } from 'app/domains/templates';
import { LanguageFlag } from 'shared/domains/languages/components/LanguageFlag';
import { MediumTableBodyCell, TitleWrapper } from './styled';
export function TemplateRow(props) {
    const { id, name, locked, selected, locale, onSelect } = props;
    const handleSelect = () => {
        onSelect(id);
    };
    return (<TableRow onSelect={handleSelect}>
      <MediumTableBodyCell headers="title">
        <TitleWrapper>
          <ComposerBadge locked={locked}/>
          <DocumentName name={name} locked={locked} onClick={handleSelect}/>
        </TitleWrapper>
      </MediumTableBodyCell>
      <TableBodyCell headers="language">
        <LanguageFlag hideLanguage languageCode={locale}/>
      </TableBodyCell>
      <TableBodyCell headers="select">
        <Radio checked={selected} onChange={handleSelect}/>
      </TableBodyCell>
    </TableRow>);
}
