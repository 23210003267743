import { gql } from '@apollo/client';
import { EditorCommentFragment } from './fragments';
export const createCommentThreadMutation = gql `
  mutation createCommentThread($id: UUID!, $documentId: ID!, $body: String! $anchorId: UUID) {
    createComment: createFileSystemDocumentComment(input: { 
      id: $id, documentId: $documentId, body: $body, anchorId: $anchorId, isResolved: false
    }) {
      ...EditorCommentFragment
    }
    ${EditorCommentFragment}
  }
`;
