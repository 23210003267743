var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useCreateTask } from './useCreateTask';
import { useDeleteTask } from './useDeleteTask';
import { useUpdateTask } from './useUpdateTask';
import { getSafeInitialValues } from '../utils/getInitialValues';
function useTaskActions(options) {
    const { task, onClose } = options;
    const { id } = task || {};
    const initialValues = getSafeInitialValues(task);
    const createTask = useCreateTask();
    const updateTask = useUpdateTask();
    const deleteTask = useDeleteTask();
    const { addToast } = useToast();
    const createError = createTask.error && t `An error occurred when creating the task.`;
    const deleteError = deleteTask.error && t `An error occurred when deleting the task.`;
    const updateError = updateTask.error && t `An error occurred when updating the task.`;
    const error = createError || deleteError || updateError;
    const handleCreate = (values) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield createTask.mutate(values);
            addToast({
                title: t `Task added.`,
                appearance: 'success',
            });
            onClose();
        }
        catch (createMutationError) {
            addToast({
                title: t `Could not add task.`,
                appearance: 'destructive',
            });
        }
    });
    const handleUpdate = (values) => __awaiter(this, void 0, void 0, function* () {
        const taskId = id;
        try {
            yield updateTask.mutate(taskId, {
                initialValues,
                data: values,
            });
            addToast({
                title: t `Task updated`,
                appearance: 'success',
            });
            onClose();
        }
        catch (updateMutationError) {
            addToast({
                title: t `An error occurred`,
                appearance: 'destructive',
            });
        }
    });
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        const taskId = id;
        try {
            yield deleteTask.mutate(taskId);
            addToast({
                title: t `Task deleted`,
                appearance: 'success',
            });
            onClose();
        }
        catch (deleteMutationError) {
            addToast({
                title: t `An error occurred`,
                appearance: 'destructive',
            });
        }
    });
    return {
        initialValues,
        handleCreate,
        handleUpdate,
        handleDelete,
        error,
    };
}
export { useTaskActions };
