var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mention } from 'pleditor';
import { CommentMentionConversion } from './CommentMentionConversion';
import { ItemRendererClassNamesManager } from './ItemRendererClassNamesManager';
import { excludeAnonymousUser, isCompanyUserWithAccess, isCompanyUserWithoutAccess, isCompanyUser, isExternalUser, isExternalUserId, sortAlphabetically, } from './utils';
import { PleditorPlugin } from '../PleditorPlugin';
import { UsersManager } from '../UsersManager';
export class CommentMentions extends PleditorPlugin {
    constructor() {
        super(...arguments);
        this.classNamesManager = new ItemRendererClassNamesManager();
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.addMentionConfig();
        });
    }
    static get requires() {
        return ['UsersManager'];
    }
    addMentionConfig() {
        this.editor.config.set('comments', {
            editorConfig: {
                extraPlugins: [Mention, CommentMentionConversion],
                mention: {
                    feeds: [
                        {
                            marker: '@',
                            minimumCharacters: 0,
                            feed: this.getUsersFeed.bind(this),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            itemRenderer: this.customItemRenderer.bind(this),
                        },
                    ],
                },
            },
        });
    }
    getUsersFeed(queryText) {
        return __awaiter(this, void 0, void 0, function* () {
            this.classNamesManager = new ItemRendererClassNamesManager();
            const usersManager = this.editor.plugins.get(UsersManager);
            const users = usersManager.getAllUsers();
            yield usersManager.getUserDocumentAcls();
            const usersFeed = users
                .filter(excludeAnonymousUser)
                .filter(item => item.name.toLowerCase().startsWith(queryText.toLowerCase()))
                .map(item => ({
                id: `@${item.name}`,
                userId: item.id,
                name: item.name,
                actions: usersManager.getUserActions(item.id),
            }));
            const companyUsers = usersFeed.filter(isCompanyUser);
            const companyUsersWithAccess = companyUsers
                .filter(isCompanyUserWithAccess)
                .sort(sortAlphabetically);
            const companyUsersWithoutAccess = companyUsers
                .filter(isCompanyUserWithoutAccess)
                .sort(sortAlphabetically);
            const externalUsers = usersFeed
                .filter(isExternalUser)
                .sort(sortAlphabetically);
            return [...companyUsersWithAccess, ...companyUsersWithoutAccess, ...externalUsers];
        });
    }
    customItemRenderer(item) {
        const { userId, name, actions } = item;
        const itemElement = document.createElement('div');
        const itemClassNames = this.classNamesManager.getItemClassNames(actions, isExternalUserId(userId));
        itemElement.classList.add(...itemClassNames);
        itemElement.id = `mention-list-item-id-${userId}`;
        itemElement.textContent = name;
        return itemElement;
    }
}
