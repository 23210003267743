import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { getCurrentMarket } from 'app/domains/markets/config';
import { QUERY } from './query';
import { getRedirectOptions } from './utils/getRedirectOptions';
import authenticate from '../../utils/authenticate';
const AuthContext = createContext({});
export function useAuth() {
    return useContext(AuthContext);
}
function AuthProvider(props) {
    const { children, locale, client } = props;
    const { data, updateQuery } = useQuery(QUERY, {
        returnPartialData: false,
    });
    const [error, setError] = useState();
    const loading = (data === null || data === void 0 ? void 0 : data.authenticated) == null && (data === null || data === void 0 ? void 0 : data.profile) == null && error == null;
    const marketCode = getCurrentMarket().code;
    const history = useHistory();
    useEffect(() => {
        authenticate({ client, history })
            .then(user => {
            if (user === null) {
                return updateQuery(() => ({ authenticated: false }));
            }
            return updateQuery(() => ({
                authenticated: true,
                profile: Object.assign(Object.assign({}, user), { __typename: 'Profile' }),
            }));
        })
            .catch(() => {
            setError(true);
        });
    }, [client, updateQuery, history]);
    const value = useMemo(() => ({
        loading,
        data,
        error,
        logout: () => client.logout({ returnTo: `${window.location.origin}/logout` }),
        getAccessTokenSilently: (options) => client.getTokenSilently(options),
        loginWithRedirect(options) {
            return client.loginWithRedirect(getRedirectOptions(Object.assign(Object.assign({}, options), { locale, marketCode })));
        },
        signupWithRedirect(options) {
            return client.loginWithRedirect(getRedirectOptions(Object.assign(Object.assign({}, options), { locale,
                marketCode, 
                // `screen_hint` are defined to display the sign-up page.
                // `prompt=login` is discarded as we don't want to force the sign-up if user is logged in.
                // Reference: https://auth0.com/docs/universal-login/new#signup
                screen_hint: 'signup' })));
        },
    }), [client, loading, data, error, marketCode, locale]);
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
