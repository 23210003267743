import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Avatar, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { getSearchEntityLink } from 'app/domains/global-search/utils/getSearchEntityLink';
import { MIN_SEARCH_TEXT_LENGTH } from '../UniversalSearchProvider';
import { useUniversalSearch } from '../useUniversalSearch';
import { InputContainer, StyledSearchInput } from './styled';
export function UniversalSearchInput() {
    const { searchText, setSearchText, closeSearchPopup, focusIndex, setFocusIndex, data } = useUniversalSearch();
    const history = useHistory();
    const inputRef = useRef(null);
    const resultsLength = (data === null || data === void 0 ? void 0 : data.search.results.length) || 0;
    const handleChange = (event) => {
        setSearchText(event.target.value);
        setFocusIndex(null);
    };
    const resetQuery = () => {
        var _a;
        setSearchText('');
        setFocusIndex(null);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const handleKeyDown = (event) => {
        const sanitizedString = (searchText === null || searchText === void 0 ? void 0 : searchText.trim()) || '';
        if (event.key === 'Enter') {
            event.preventDefault();
            if (focusIndex !== null && (data === null || data === void 0 ? void 0 : data.search.results[focusIndex])) {
                const { entityId, type } = data.search.results[focusIndex];
                const link = getSearchEntityLink(type, entityId);
                history.push(link);
                closeSearchPopup();
            }
            else if (sanitizedString.length >= MIN_SEARCH_TEXT_LENGTH) {
                history.push(`/search?query=${sanitizedString}`);
                closeSearchPopup();
            }
        }
        else if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (focusIndex === null && resultsLength > 0) {
                setFocusIndex(0);
            }
            else if (focusIndex !== null && focusIndex < resultsLength) {
                // We have 1 more item than the results length to link to all results, hence the +1
                setFocusIndex(focusIndex + 1);
            }
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (focusIndex !== null && focusIndex > 0) {
                setFocusIndex(focusIndex - 1);
            }
            else if (focusIndex === 0) {
                setFocusIndex(null);
            }
        }
    };
    return (<InputContainer>
      <Avatar size="sm" solid appearance="light">
        <Falcon icon="magnifying-glass"/>
      </Avatar>
      <StyledSearchInput ref={inputRef} name="advanced-search" value={searchText} autoComplete="off" spellCheck={false} onChange={handleChange} placeholder={t({
            message: 'Start typing...',
            comment: 'Placeholder for the universal search input.',
        })} onKeyDown={handleKeyDown}/>

      <IconButton onClick={resetQuery} $round appearance="ghost" size="small">
        <Falcon icon="close"/>
      </IconButton>
    </InputContainer>);
}
