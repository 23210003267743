import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, IconButton, Menu, MenuBody, MenuDivider, MenuItem, MenuList, Tooltip, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePagination } from './utils/usePagination';
/**
 * TODO: Move this to tetris once this feature branch is merged.
 */
export function Pagination(props) {
    const { limit = 0, count = 0, offset = 0, setOffset, disabled, maxCount = Number.POSITIVE_INFINITY, } = props;
    const [open, setOpen] = useState(false);
    const { pages, goto, current, totalPages } = usePagination({
        count,
        limit,
        offset,
        setOffset,
    });
    const notActive = pages.length < 2;
    const pageStartCount = offset + 1;
    const pageEndCount = offset + limit > count ? count : offset + limit;
    const paginationDisabled = notActive || disabled;
    const prevDisabled = disabled || notActive || current === 0;
    const nextDisabled = disabled || notActive || current === totalPages - 1;
    const numberFormatter = new Intl.NumberFormat();
    const countToDisplay = count > maxCount ? `${numberFormatter.format(maxCount)}+` : numberFormatter.format(count);
    return (<Box gap="sm">
      <Menu open={open} onClose={() => setOpen(false)} target={<Button disabled={paginationDisabled} appearance="ghost" size="small" onClick={() => setOpen(!open)}>
            <Trans comment="Pagination: Page range">
              {pageStartCount} - {pageEndCount} of {countToDisplay}
            </Trans>
          </Button>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={() => {
            goto('first');
            setOpen(false);
        }}>
              <Trans comment="Pagination: First page button">First page</Trans>
            </MenuItem>
            <MenuDivider />
            {pages.map(({ index, offset: pageOffset, number }) => (<MenuItem key={index} $selected={offset === pageOffset} onClick={() => {
                goto(index);
                setOpen(false);
            }}>
                <Trans comment="Pagination list item">Page {number}</Trans>
              </MenuItem>))}
            <MenuDivider />
            <MenuItem onClick={() => {
            goto('last');
            setOpen(false);
        }}>
              <Trans comment="Pagination: Last page button">Last page</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
      <Tooltip disabled={prevDisabled} title={t({ message: 'Previous page', comment: 'Pagination: Previous page tooltip' })}>
        <IconButton disabled={prevDisabled} appearance="ghost" onClick={() => goto(current - 1)} size="small">
          <Falcon icon="chevron-left"/>
        </IconButton>
      </Tooltip>
      <Tooltip disabled={nextDisabled} title={t({ message: 'Next page', comment: 'Pagination: Next page tooltip' })}>
        <IconButton disabled={nextDisabled} appearance="ghost" onClick={() => goto(current + 1)} size="small">
          <Falcon icon="chevron-right"/>
        </IconButton>
      </Tooltip>
    </Box>);
}
