var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { apiRequest } from './apiRequest';
import { waitFor, isDocumentOperation, replaceOperation, isNotMoveFromGraveyardOperation, isNotComplementary, isNotBaseVersion, isLocalOperation, recalculateBaseVersion, } from './utils';
import { CollaboratorSession } from '../CollaboratorSession';
import { PleditorPlugin } from '../PleditorPlugin';
/**
 * Plugin to handle saving the document
 * Any operations not yet applied by on the users local document will be applied to the document as well.
 */
export class SaveHandler extends PleditorPlugin {
    static get requires() {
        return ['ApiConnector', 'CollaboratorSession', 'Notification', 'Autosave'];
    }
    static get pluginName() {
        return 'SaveHandler';
    }
    constructor(editor) {
        super(editor);
        this.lastSavedVersion = 0;
        this.lastKnownBackendVersion = 0;
        this.hasError = false;
        this.retryCount = 0;
        this.retryLimit = 3;
        this.set('state', undefined);
    }
    init() {
        this.registerEventHandlers();
    }
    registerEventHandlers() {
        const { editor } = this;
        editor.on('ready', () => {
            const initialVersion = editor.config.get('initialVersion');
            this.lastSavedVersion = initialVersion;
            this.lastKnownBackendVersion = initialVersion;
            this.editor.model.document.version = initialVersion;
        }, { priority: 'highest' });
        const hasAutosave = editor.plugins.has('Autosave');
        const collaboratorSession = editor.plugins.get(CollaboratorSession);
        if (!hasAutosave)
            return;
        /**
         * When we apply complementary operations in the CollaboratorSession plugin
         * we need to make sure that the version we increment the document by is also
         * set as the new last saved version.
         * Complementary operations applied to the document will increment the version
         * and if we do not keep track on that version bump. Next time we save those
         * operations will be added to the operations to save.
         */
        collaboratorSession.on('setOperations', (event, [operations]) => {
            if (Array.isArray(operations) && operations.length) {
                const documentVersion = editor.model.document.history.version.valueOf();
                this.lastSavedVersion = documentVersion;
                this.lastKnownBackendVersion = documentVersion;
            }
        }, { priority: 'lowest' });
    }
    hasErrors() {
        const collaboratorSession = this.editor.plugins.get(CollaboratorSession);
        const hasError = this.state === 'error' || collaboratorSession.state === 'error';
        return hasError;
    }
    handleErrors() {
        this.set('state', 'error');
        this.editor.enableReadOnlyMode('save_error');
        const notifications = this.editor.plugins.get('Notification');
        notifications.showWarning(t({
            message: 'An error occurred saving the document',
            comment: 'Pleditor: Generic toast error message when saving a document failed',
        }));
    }
    save() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.hasErrors()) {
                return;
            }
            this.set('state', 'saving');
            while (this.retryCount < this.retryLimit) {
                this.retryCount += 1;
                try {
                    // eslint-disable-next-line no-await-in-loop
                    yield this.saveHandler();
                    break;
                }
                catch (error) {
                    if (this.retryCount >= this.retryLimit) {
                        this.handleErrors();
                        throw Error(error);
                    }
                    // eslint-disable-next-line no-await-in-loop
                    yield waitFor(2000);
                }
            }
            this.set('state', 'synchronized');
            this.retryCount = 0;
        });
    }
    /**
     * Handles the saving of a document.
     * This method gets the currently unsaved operations and
     * sends them to the API.
     * Upon successful saving, the API may respond with an array of complementaryOperations.
     * These operations represent changes made by other users that have not yet been
     * applied to the current user's document.
     * If so the complementaryOperations will be sent to the CollaboratorSession plugin to
     * be applied.
     */
    saveHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const { editor, documentId } = this;
            const { history } = editor.model.document;
            const potentialLastSavedVersion = history.version.valueOf();
            const plops = yield this.getUnsavedOperations();
            if (!plops.length) {
                return;
            }
            const data = yield apiRequest(this.apiConnector, documentId, plops);
            if (!data) {
                throw Error('No data returned when saving document in Pleditor');
            }
            this.lastSavedVersion = potentialLastSavedVersion;
            this.lastKnownBackendVersion = data.applyFileSystemPleditorOperations.versionNumber;
            this.applyCollaboratorSession(data);
        });
    }
    /**
     * Method to get all currently unsaved operations in the editor
     */
    getUnsavedOperations() {
        return __awaiter(this, void 0, void 0, function* () {
            const { editor, lastSavedVersion } = this;
            const { history } = editor.model.document;
            const operations = history.getOperations(lastSavedVersion);
            const plops = operations
                .filter(isDocumentOperation)
                .reduce(replaceOperation, [])
                .filter(isNotMoveFromGraveyardOperation)
                .filter(isNotComplementary)
                .filter(isNotBaseVersion)
                .filter(isLocalOperation)
                .map(operation => operation.toJSON())
                .map(recalculateBaseVersion(this.lastKnownBackendVersion), []);
            return plops;
        });
    }
    applyCollaboratorSession(data) {
        const { editor } = this;
        const collaboratorSession = editor.plugins.get(CollaboratorSession);
        const { applyFileSystemPleditorOperations } = data || {};
        const { complementaryOperations } = applyFileSystemPleditorOperations || {};
        collaboratorSession.setOperations(complementaryOperations);
    }
}
