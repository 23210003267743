import { gql } from '@apollo/client';
const ComposerLogicFragment = gql `
  fragment ComposerLogicFragment on Logic {
    refId: ref
    actions {
      to
      condition
    }
  }
`;
export const FieldFragment = gql `
  fragment FieldFragment on Field {
    __typename
    refId: ref
    title(format: TEXT)
    description(format: MARKDOWN)
    ... on NumberField {
      rules {
        required
        min
        max
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on DateField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on ShortTextField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on EmailField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on WebsiteField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on PhoneNumberField {
      countryCode
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on LongTextField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on YesNoField {
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on MultipleChoiceField {
      choices {
        refId: ref
        label
      }
      multiple
      rules {
        required
      }
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on StartField {
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on StatementField {
      logic {
        ...ComposerLogicFragment
      }
    }
    ... on ComposerEndField {
      treeId
    }
    ... on GuideEndField {
      treeId
    }
  }
  ${ComposerLogicFragment}
`;
