import { TableHead, TableRow, TableHeaderText, TableHeadCell, Box, Checkbox, } from '@pocketlaw/tetris';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { useTableContext } from './TableContext';
import { TITLE_CELL_WIDTH } from './constants';
import { useSearchContext } from '../SearchProvider';
export function SearchResultsTableHead() {
    const featureFlag = useFeatureFlag('feature_document_status');
    const { columnConfigs } = useSearchContext();
    const { results, selected, setSelected } = useTableContext();
    const columns = columnConfigs.filter(column => column.label && column.id);
    const allSelected = selected.length === results.length;
    const handleToggleSelectAll = () => {
        if (allSelected) {
            setSelected({ type: 'reset' });
        }
        else {
            setSelected({ type: 'select-all' });
        }
    };
    return (<TableHead>
      <TableRow>
        {columns.map(column => (<TableHeadCell key={column.id} id={column.id} maxWidth={column.id === 'title' ? TITLE_CELL_WIDTH : undefined} colSpan={column.colSpan} stickyColumn={column.stickyColumn}>
            <Box alignItems="center" gap="md" pl="lg">
              {column.id === 'title' && featureFlag && (<Checkbox onChange={handleToggleSelectAll} checked={allSelected}/>)}
              <TableHeaderText sortId={column.sortId}>{column.label || ''}</TableHeaderText>
            </Box>
          </TableHeadCell>))}
      </TableRow>
    </TableHead>);
}
