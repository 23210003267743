var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { buildDocumentEntityTypeFilter, useSearchArgs, useSearchContext, } from 'app/domains/global-search';
import { UpdateViewMutationDocument } from './mutation.gql';
export function useUpdateViewMutation() {
    const [mutate, { data, loading, error }] = useMutation(UpdateViewMutationDocument);
    const { query, sortBy, getMultiSelectFilterValue } = useSearchContext();
    const { getSearchWhereArgs } = useSearchArgs();
    const updateView = (viewId) => __awaiter(this, void 0, void 0, function* () {
        const input = {
            where: Object.assign(Object.assign({}, getSearchWhereArgs()), { entityType: buildDocumentEntityTypeFilter(getMultiSelectFilterValue('type')) }),
        };
        if (query) {
            input.query = query;
        }
        if (sortBy) {
            const { sortId, sortOrder } = sortBy;
            input.orderBy = [{ [sortId]: sortOrder }];
        }
        mutate({
            variables: {
                id: viewId,
                input,
            },
        });
    });
    return {
        updateView,
        data,
        loading,
        error,
    };
}
