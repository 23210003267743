import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledAlert } from './styled';
function AccessWarningAlert() {
    return (<StyledAlert appearance="warning" icon={<Falcon icon="exclamation"/>} message={t({
            message: 'You do not have access to this folder. You can only see the content that has been shared with you.',
            comment: 'Access warning alert message',
        })}/>);
}
export default AccessWarningAlert;
