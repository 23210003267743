import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Calendar, Typography } from '@pocketlaw/tetris';
import { localeConfig } from 'shared/domains/languages/config';
import { DateButton } from './DateButton';
import { Container } from './styled';
export function DateRangeForm(props) {
    const { initialValue, onSubmit } = props;
    const [currentCalendar, setCurrentCalendar] = useState(null);
    const localeCode = localeConfig.getActiveLocale().code;
    const [dateRange, setDateRange] = useState(initialValue);
    const getDateByCalendar = () => {
        switch (currentCalendar) {
            case 'start':
                return dateRange.startDate;
            case 'end':
                return dateRange.endDate;
            default:
                return undefined;
        }
    };
    const handleChange = (date) => {
        if (currentCalendar === 'start') {
            setDateRange(Object.assign(Object.assign({}, dateRange), { startDate: date }));
        }
        else if (currentCalendar === 'end') {
            setDateRange(Object.assign(Object.assign({}, dateRange), { endDate: date }));
        }
        // Timeout to prevent Menu from closing
        setTimeout(() => {
            setCurrentCalendar(null);
        }, 1);
    };
    const showCalendar = (calendar) => {
        setCurrentCalendar(calendar);
    };
    const handleSubmit = () => {
        onSubmit(dateRange);
    };
    const hasValue = dateRange.startDate || dateRange.endDate;
    if (currentCalendar !== null) {
        return (<Container>
        <Calendar min={currentCalendar === 'end' ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate : undefined} value={getDateByCalendar()} onChange={handleChange} locale={localeCode}/>
        <Button appearance="ghost" onClick={() => showCalendar(null)}>
          <Trans>Cancel</Trans>
        </Button>
      </Container>);
    }
    return (<Container>
      <Typography>
        <Trans comment="Search: Date input filter help text">
          Select a start- and/or end date to apply
        </Trans>
      </Typography>
      <DateButton date={dateRange.startDate} label={t({
            message: 'Date from',
            comment: 'Range filter: "Date from" input label',
        })} onClick={() => showCalendar('start')}/>
      <DateButton date={dateRange.endDate} label={t({ message: 'Date to', comment: 'Range filter: "Date to" input label' })} onClick={() => showCalendar('end')}/>
      <Button size="small" $stretch onClick={handleSubmit} disabled={!hasValue}>
        <Trans comment="Apply filter button label">Apply</Trans>
      </Button>
    </Container>);
}
